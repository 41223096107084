<template>
  <div class="caregiver-container">
    <div class="header-container" style="margin-bottom: 32px">
      <h1>Where do you want to work?</h1>
    </div>
    <div class="selects-div">
          <!-- <SelectDropdown
        label="State"
        id="state-select"
        width="153px"
        :options="stateOptions"
        @update:selectedOption="handleStateSelection"
      /> -->
      <SelectDropdown
        label="State"
        id="state-select"
        width="153px"
        :options="stateOptions"
        :disabled="true"
        :selectedOption="selectedState"
      />
      <SelectDropdown
        label="Lga"
        id="lga-select"
        :options="lgaOptions"
        :selectedOption="selectedLga"
        width="231px"
        @update:selectedOption="handleLgaSelection"
      />
    </div>
    <div class="work-preference">
      <p>Work preference.</p>
    </div>
    <div class="care-card-container">
      <div
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Live In' }"
        @click="setWork('Live In')"
      >
        <p>Live In</p>
      </div>
      <div
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Live Out' }"
        @click="setWork('Live Out')"
      >
        <p>Live Out</p>
      </div>
      <div
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Both' }"
        @click="setWork('Both')"
      >
        <p>I am open to both</p>
      </div>
    </div>
    <div class="button-div" >
      <BaseButton
        label="Next"
        :disabled="disabled"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="100%"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import { ref, inject, watch, onMounted } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import SelectDropdown from "@/components/SelectDropdown.vue";
import statesData from "@/data/states.js";
import { useRouter } from "vue-router";

export default {
  name: "CareGiverStepTwo",
  components: {
    BaseButton,
    SelectDropdown,
  },
  setup() {
    const formData = inject("formData");
    const updateFormData = inject("updateFormData");
    const router = useRouter();

    const localData = ref({
      ...formData.value.stepTwoData,
    });

    const stateOptions = statesData.states
      .map((state) => ({
        value: state.state,
        text: state.state,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));

    const lgaOptions = ref([]);
    const selectedState = ref(localData.value.state || "Lagos"); // Set default selected state to "Lagos" if not set
    const selectedLga = ref(localData.value.lga);
    const selectedWork = ref(localData.value.work_preference);
    const disabled = ref(true);

    const handleStateSelection = (value) => {
      selectedState.value = value;
      localData.value.state = value;
      selectedLga.value = "";
      localData.value.local_government = "";
      updateLgaOptions(value);
    };

    const setWork = (work) => {
      selectedWork.value = work;
      localData.value.work_preference = work;
    };

    const handleLgaSelection = (value) => {
        localData.value.state = "Lagos";
      selectedLga.value = value;
      localData.value.local_government = value;
    };

    const updateLgaOptions = (state) => {
      const selectedStateData = statesData.states.find((s) => s.state === state);
      lgaOptions.value = selectedStateData
        ? selectedStateData.lgas.map((lga) => ({ value: lga, text: lga }))
        : [];
    };

    const handleClick = () => {
      updateFormData("stepTwoData", localData.value);
      console.log("formdata here", formData);
      router.push("/caregiver-onboarding/create-account/step-3");
    };

    watch(
      [selectedState, selectedLga, selectedWork],
      ([newSelectedState, newSelectedLga, newSelectedWork]) => {
        disabled.value = !newSelectedState || !newSelectedLga || !newSelectedWork;
      },
      { immediate: true }
    );

    onMounted(() => {
      updateLgaOptions(selectedState.value); // Auto-populate LGA dropdown
    });

    return {
      stateOptions,
      lgaOptions,
      selectedState,
      selectedLga,
      selectedWork,
      handleStateSelection,
      handleLgaSelection,
      handleClick,
      disabled,
      setWork,
    };
  },
};
</script>

<style scoped>
h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  margin: 0px;
  text-align: center;
}

.text {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: center;
}

.caregiver-container {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.care-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card-selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal-active);
}

.care-card-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
  cursor: pointer;
}

.care-card p {
  color: #0d4a34;
}

.selects-div {
  display: flex;
  gap: 16px;
  width: 400px;
}
.work-preference p{
font-size: 16px;
font-weight: 700;
line-height: 27.2px;
letter-spacing: 0.5px;
text-align: left;
color:var(--s-green-darker)
}
.work-preference{
    display: flex;
    justify-content: flex-start;
     width: 400px;
       margin-top: 24px;
       
}
.button-div{
  width: 400px;
   display: flex;
  justify-content: center;
}
@media screen and (max-width: 800px) {
 
.care-card-container{
  width: 350px;
}
.selects-div{
  width: 350px;
  flex-direction: column;

}

.selects-div :deep(.custom-select-container){
  width: 350px !important;
}
.selects-div :deep(.custom-select-trigger){
  width: 350px !important;
  height: 60px;
}
.work-preference{
  padding-left: 20px;
  justify-content: flex-start;
}
.care-card{
  height: 60px;
}

.button-div{
  width: 350px;
  display: flex;
  justify-content: center;
}
h1{
  font-size: 20px;
  text-align: left;
}

.header-container{
  width: 400px;
  padding-left: 20px
}
.custom-select{
  height: 60px;
}
  /* .create-account{
    display: none;
  }
  .navbar{
    padding: 20px;
    justify-content: space-between;
  }

  .main-logo {
  width: 140px;
  height: 50px;
  cursor: pointer;
}
.create-account-small {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 40px
}
.create-account-small p {
  color: #979797;
  font-family: Inter;
font-size: 12px;
font-weight: 400;
line-height: 27.2px;
letter-spacing: 0.5px;
text-align: left;

} */


}
</style>
