<template>
  <div class="availability-container">
    <h1 style="margin-top: 10px">When do you need care?</h1>
    <div style="max-width: 400px">
      <div class="days-container">
       <button
          v-for="day in days"
          :key="day.short"
          :class="['day-button', { selected: selectedDays.includes(day.full) }]"
          @click="toggleDay(day.full)"
        >
          {{ day.short }}
        </button>
      </div>
      <p class="bottom-text">
        Don't worry, you can always adjust these times later
      </p>
      <BaseButton
        label="Next"
        :disabled="!canProceed"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="handleNext"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { useRouter } from "vue-router";

export default {
  name: "CareSeekerCreateJobStepFive",
  components: {
    BaseButton,
  },
  setup() {
  const formData = inject("onboardingCareSeekerformData");
    const updateFormData = inject("onboardingUpdateCareSeekerformData");
    const localData = ref({
      ...formData.value.stepEightData,
    });

   const days = [
      { short: "Sun", full: "Sunday" },
      { short: "Mon", full: "Monday" },
      { short: "Tue", full: "Tuesday" },
      { short: "Wed", full: "Wednesday" },
      { short: "Thu", full: "Thursday" },
      { short: "Fri", full: "Friday" },
      { short: "Sat", full: "Saturday" },
    ];
    const selectedDays = ref(localData.value.days_of_the_week || []);
    const router = useRouter();
       if (formData.value.stepFourData.type_of_care == "" || !formData.value.stepFourData.type_of_care){
      router.push("/careseeker-onboarding/job-post/step-1");
    }
    const toggleDay = (day) => {
      // if (selectedDays.value.includes(day)) {
      //   selectedDays.value = selectedDays.value.filter((d) => d !== day);
      // } else {
      //   selectedDays.value.push(day);
      // }
       if (selectedDays.value.includes(day)) {
        selectedDays.value = selectedDays.value.filter((d) => d !== day);
      } else {
        selectedDays.value.push(day);
      }
      
    };

    const canProceed = computed(() => {
      return selectedDays.value.length > 0;
    });

    const handleNext = () => {
      console.log("selected days", selectedDays.value);
      localData.value.days_of_the_week = [...selectedDays.value];
      updateFormData("stepEightData", localData.value);
      console.log("formdata here", formData);
      router.push("/careseeker-onboarding/job-post/step-6");
    };

    watch(selectedDays, (newSelectedDays) => {
      console.log("Selected days updated:", newSelectedDays);
      localData.value.days_of_the_week = [...newSelectedDays];
    });

    return {
      days,
      selectedDays,
      toggleDay,
      canProceed,
      handleNext,
    };
  },
};
</script>

<style scoped>
.availability-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.days-container,
.times-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.day-button,
.time-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.day-button.selected,
.time-button.selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal);
}

.specific-time-container {
  margin-bottom: 20px;
  margin-top: 29px;
}

.time-picker {
  display: flex;
  align-items: center;
  gap: 29px;
  margin-top: 10px;
  position: relative;
}

.time-picker select {
  padding: 16px 52px 16px 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z" fill="black"/></svg>')
    no-repeat right 26px center;
  background-size: 10px 6px;
}

.time-picker select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer */
}

.next-button {
  background-color: #ff6978;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

.days-container button {
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.specific-time-container label {
  text-align: left;
}

.add-times {
  color: #0063f7;
  text-decoration: none;
  text-align: left;
}

span {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}

.bottom-text {
  margin-top: 29px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}
</style>
