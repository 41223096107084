<template>
  <div class="overall-container">
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManagerVue ref="toastManager" />
    <div v-if="!isViewReport" class="main-table-container">
      <div style="display: flex; justify-content: space-between">
        <h2>Reports</h2>

        <a class="a-tag" :href="`${baseUrl}/profile/export/`" target="_blank"
          >Export</a
        >
      </div>

      <div class="filter-container">
        <div class="custom-select-wrapper"></div>
      </div>
      <div class="careseekers-table-container">
        <ReusableTable
          endpoint="/profile/"
          :headers="[
            'S/N',
            'Name',
            'Type',
            'Phone',
            'Email',
            'Onboarding Status',
  
          ]"
          :selectOptions="[
            { value: 'All', label: 'All' },

            { value: 'Care seeker', label: 'Careseekers' },
            { value: 'Care giver', label: 'Caregivers' },
          ]"
           :selectOptionsTwo="[
            { value: 'All', label: 'All' },

            { value: 'True', label: 'Onboarded' },
            { value: 'False', label: 'Not Onboarded' },
          ]"
             :selectOptionsThree="[
            { value: 'All', label: 'All' },

            { value: 'True', label: 'Is Insurance' },
          
          ]"
        />
      </div>
      <div>
        <!-- :queryParams="{ onboarding_status: 'True' }" -->
        <!-- :selectOptions="[
            { value: '', label: 'All' },
          
              { value: 'Care', label: 'Careseekers' },
            { value: 'Care giver', label: 'Caregivers' },
          ]" -->
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import ReusableTable from "@/components/ReusableTable.vue";

// import BaseButton from "@/components/BaseButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import Request from "@/utils/apiclient.js";

export default {
  name: "AdminList",
  components: {
    ReusableTable,
    // BaseButton,
    LoadingSpinner,
    ToastManagerVue,
  },
  setup() {
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const isLoading = ref(false);

    const careList = ref([
      // Add more data here
    ]);
    const isViewReport = ref(false);
    const selectedCategory = ref("All categories");
    const isOpen = ref(false);
    const actionDropdown = ref(null);

    const toggleReportsDropdown = (index) => {
      actionDropdown.value = actionDropdown.value === index ? null : index;
    };
    const uniqueCategories = computed(() => {
      const categories = careList.value.flatMap((care) => care.categories);
      return ["All categories", ...new Set(categories)];
    });

    const filteredCareseekers = computed(() => {
      if (selectedCategory.value === "All categories") {
        return careList.value;
      }
      return careList.value.filter((care) =>
        care.categories.includes(selectedCategory.value)
      );
    });

    const handleAction = (careseeker) => {
      console.log("Action clicked for:", careseeker);
      // Handle your action here
    };

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const selectCategory = (category) => {
      selectedCategory.value = category;
      isOpen.value = false;
    };

    const isExporting = ref(false);
    const handleExport = () => {
      if (isExporting.value) return;
      isExporting.value = true;
      isLoading.value = true;
      const request = new Request(`/profile/export/`);

      request.get((res, err) => {
        isLoading.value = false;
        isExporting.value = false;
        if (err) {
          console.error("Error save acc:", err);
          showToast("Export Failed", "error");
        } else {
          console.log("here is response save account", res);
          //   window.open(res, "_blank");
          showToast("Report Exported Successfully", "success");
        }
      });
      // router.push("/careseeker/payment-status");
    };
    // const fetchDashboard = () => {
    //   // approval status is one for approved caregivers, and two for pending caregivers
    //   const request = new Request("/profile/?onboarding_status=True");
    //   request.get((res, err) => {
    //     isLoading.value = false;
    //     if (err) {
    //       console.error("Error:", err);
    //     } else {
    //       console.log("here is admin list data", res);
    //       // caregivers.value = res;
    //       careList.value = res.sort((a, b) => a.id - b.id);
    //     }
    //   });
    // };
    const baseUrl = process.env.VUE_APP_API_BASE_URL;

    onMounted(() => {
      //   fetchDashboard();
    });

    return {
      careList,
      selectedCategory,
      uniqueCategories,
      filteredCareseekers,
      handleAction,
      toggleDropdown,
      actionDropdown,
      isOpen,
      selectCategory,
      toggleReportsDropdown,
      isViewReport,
      isLoading,
      toastManager,
      ToastManagerVue,
      //   fetchDashboard,
      handleExport,
      isExporting,
      baseUrl,
    };
  },
};
</script>

<style scoped>
.text-editor-div {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 30px;
}
h1 {
  font-size: 20px;
}
/* Optional: You can add custom styling here */
.main-table-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 1008px;
  margin-top: 52px;
}

.filter-container {
  margin-bottom: 20px;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 340px;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #979797;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}

.custom-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 340px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-top: 8px;
  z-index: 10;
  list-style-type: none;
  padding: 0;
}

.custom-options li {
  padding: 8px;
  cursor: pointer;
}

.custom-options li:hover,
.custom-options li.selected {
  background-color: #f4f4f4;
}

.careseekers-table {
  width: 100%;

  border-spacing: 0 13px;

  /* opacity: 0.5; */
}
.careseekers-table-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  padding: 24px;
  background-color: #ececec80;
  min-height: 772px;
  margin-bottom: 400px;
}

.careseekers-table th {
  /* opacity: 1; */
  padding: 8px;

  font-size: 16px;
  font-weight: 500;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table td {
  padding: 8px;

  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table th {
  background-color: #f4f4f4;
}

.careseekers-table td.resolved {
  width: 93px;
  height: 35px;
  background-color: var(--state-success);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.careseekers-table td.unresolved {
  width: 111px;
  height: 35px;
  padding: 8px;
  background-color: var(--state-error);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.dropdown-menu {
  position: absolute;
  right: 80px; /* Align to the right edge of the icon-dots container */
  top: -20px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #0000001a;
  padding: 10px;
  transform: translateY(100%); /* Position below the icon-dots */
  z-index: 10;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  white-space: nowrap;
  text-align: left;
}

.dropdown-menu .icon {
  margin-right: 8px;
}
h2 {
  margin-bottom: 15px;
}
.selected-option {
  display: flex;
  align-items: center;
}

.selected-option svg {
  margin-right: 8px;
}
.the-td {
  position: relative;
}
.icon-dots {
  display: flex;
  padding-left: 12px;

  cursor: pointer;
}
.caregiver-details-first-div {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  margin-top: 30px;
}
.caregiver-details {
  padding-left: 46px;
  margin-top: 30px;
}
.content-section:last-child {
  border-bottom: 0px;
}
.content-section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #979797;
  width: 100%;
}
.jobs-list-item-type-of-care-container {
  display: flex;
  gap: 152px;
}
.span-header {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.span-normal {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.main-container-section {
  display: flex;

  flex-direction: column;
}
.main-container {
  padding: 20px;
  background-color: var(--state-white);
  border-radius: 12px;
  margin-top: 22px;
}
.button-div {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 129px; */
  margin-top: 20px;
}
.a-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid transparent;
  border-radius: 25px;
  width: 150px;
  text-decoration: none;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, opacity 0.3s;
  background-color: var(--s-pink-normal);
  color: var(--state-white);
}

/* Pagination styles (commented out for now) */
/*
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
*/
@media (max-width: 800px) {
  .main-table-container {
    width: 260px;
  }
  .careseekers-table {
    border-spacing: 0px 5px;
  }

  .careseekers-table td.active {
    width: 30px;
    height: 25px;
    background-color: var(--state-success);
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .careseekers-table td.inactive {
    width: 33px;
    height: 25px;
    padding: 8px;
    background-color: #979797;
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .careseekers-table th {
    /* opacity: 1; */
    padding: 8px;

    font-size: 7px;
    font-weight: 500;
    line-height: 27.2px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  .careseekers-table td {
    padding: 8px;

    font-size: 7px;
    font-weight: 400;

    letter-spacing: 0.5px;
    text-align: left;
  }
  .custom-select-wrapper {
    width: 200px;
  }

  .careseekers-table td.resolved {
    width: 30px;
    height: 35px;
    background-color: var(--state-success);
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .careseekers-table td.unresolved {
    width: 30px;
    height: 35px;
    padding: 8px;
    background-color: var(--state-error);
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
}
</style>
