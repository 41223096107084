<template>
  <DashboardLayout>
    <div v-if="isNotApproved" class="awaiting-approval">
      <div class="application-review">
        <div>Your application is under review</div>

        <div style="margin-top: 20px">
          <BaseButton
            label="Logout"
            backgroundColor="var(--s-pink-normal)"
            textColor="var(--state-white)"
            height="40px"
            width="100px"
            @click="handleLogout"
          />
        </div>
      </div>
    </div>
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManagerVue ref="toastManager" />
    <div class="overall-container">
      <div class="the-container">
        <div class="dashboard-main-container">
          <div class="first-dashboard-container">
            <div>
              <h1>Good day, {{ userProfileState.first_name }}.</h1>
              <!-- <input
            type="text"
            placeholder="Search for Jobs"
            v-model="searchQuery"
          /> -->

              <div class="search-wrapper">
                <input
                  type="text"
                  placeholder="Search for Jobs"
                  v-model="searchQuery"
                />

                <svg
                  class="search-icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.1372 14.0926L24 21.9554L21.9554 24L14.0926 16.1372C12.6244 17.1938 10.8542 17.8388 8.91938 17.8388C3.99314 17.8388 0 13.8456 0 8.91938C0 3.99314 3.99314 0 8.91938 0C13.8456 0 17.8388 3.99314 17.8388 8.91938C17.8388 10.8542 17.1938 12.6244 16.1372 14.0926ZM8.91938 2.74443C5.50257 2.74443 2.74443 5.50257 2.74443 8.91938C2.74443 12.3362 5.50257 15.0943 8.91938 15.0943C12.3362 15.0943 15.0943 12.3362 15.0943 8.91938C15.0943 5.50257 12.3362 2.74443 8.91938 2.74443Z"
                    fill="#979797"
                  />
                </svg>
              </div>
            </div>
            <div class="tabs">
              <div
                class="single-tab"
                :class="{ active: currentTab === 'myFeed' }"
                @click="setTab('myFeed')"
              >
                <p>My feed</p>
              </div>
              <div
                class="single-tab"
                :class="{ active: currentTab === 'mostRecent' }"
                @click="setTab('mostRecent')"
              >
                <p>Most recent</p>
              </div>
              <div
                class="single-tab"
                :class="{ active: currentTab === 'savedJobs' }"
                @click="setTab('savedJobs')"
              >
                <p>Saved jobs</p>
              </div>
            </div>
            <div class="jobs-container">
              <section v-if="currentTab === 'myFeed'" class="jobs">
                <div v-if="filteredJobs.length == 0">
                  <div class="no-archived-container">
                    <div class="no-archived">
                      <svg
                        width="125"
                        height="107"
                        viewBox="0 0 125 107"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M84.6783 4.47897C84.6783 4.47897 67.5568 26.1989 40.1618 13.2436C12.7668 0.288249 -14.6278 35.3442 8.96193 56.6832C32.5516 78.0222 9.46734 89.7478 25.4935 102.826C43.7082 117.69 59.5663 87.5485 74.0246 94.4075C105.935 109.545 129.575 103.553 124.248 86.0243C114.898 55.2554 102.941 53.2537 112.073 37.2495C121.205 21.2453 101.051 -12.0315 84.6783 4.47897Z"
                          fill="#FCE0E5"
                        />
                        <path
                          d="M94.2 25.5789H90.2895C85.9875 25.5789 82.5 22.0915 82.5 17.7895C82.5 13.4663 79.029 10 74.7 10H51.3C46.971 10 43.5 13.4663 43.5 17.7895C43.5 22.0915 40.0125 25.5789 35.7105 25.5789H31.8C27.471 25.5789 24 29.0453 24 33.3684V76.2105C24 80.5337 27.471 84 31.8 84H94.2C98.529 84 102 80.5337 102 76.2105V33.3684C102 29.0453 98.529 25.5789 94.2 25.5789ZM51.3 21.6842C51.3 19.5332 53.0437 17.7895 55.1947 17.7895H70.8053C72.9563 17.7895 74.7 19.5332 74.7 21.6842C74.7 23.8352 72.9563 25.5789 70.8053 25.5789H55.1947C53.0437 25.5789 51.3 23.8352 51.3 21.6842ZM94.2 72.3158C94.2 74.4668 92.4563 76.2105 90.3053 76.2105H35.6947C33.5437 76.2105 31.8 74.4668 31.8 72.3158C31.8 70.1648 33.5437 68.4211 35.6947 68.4211H90.3053C92.4563 68.4211 94.2 70.1648 94.2 72.3158ZM94.2 46.7368C94.2 52.2597 89.7229 56.7368 84.2 56.7368H41.8C36.2771 56.7368 31.8 52.2597 31.8 46.7368V39.2184C31.8 35.9876 34.4191 33.3684 37.65 33.3684H39.6053C41.7563 33.3684 43.5 35.1122 43.5 37.2632C43.5 39.4142 45.249 41.1579 47.4 41.1579C49.551 41.1579 51.3 39.4142 51.3 37.2632C51.3 35.1122 53.0437 33.3684 55.1947 33.3684H70.8053C72.9563 33.3684 74.7 35.1122 74.7 37.2632C74.7 39.4142 76.449 41.1579 78.6 41.1579C80.751 41.1579 82.5 39.4142 82.5 37.2632C82.5 35.1122 84.2437 33.3684 86.3947 33.3684H88.35C91.5809 33.3684 94.2 35.9876 94.2 39.2184V46.7368Z"
                          fill="#17865F"
                        />
                      </svg>
                      <p>You have no Jobs listed</p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="job in filteredJobs"
                    :key="job.id"
                    class="job"
                    @click="openJobDetails(job)"
                  >
                    <div class="job-header-content">
                      <div>
                        <p>Posted {{ job.posted }} ago</p>
                      </div>
                      <div class="job-header-content-job-title">
                        <h2>{{ job.title }}</h2>
                        <svg
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36H10.94C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z"
                            fill="#979797"
                          />
                        </svg>
                      </div>
                      <div>
                        <p>Est. Budget: {{ job.budget }}</p>
                      </div>
                    </div>
                    <div class="job-body-content">
                      <p>{{ job.description }}</p>
                    </div>

                    <div class="job-tag">
                      <span v-for="tag in job.tags" :key="tag">{{ tag }}</span>
                    </div>
                    <!-- <button @click="saveJob(job.id)">Save</button> -->
                  </div>
                </div>
              </section>

              <section v-if="currentTab === 'mostRecent'" class="jobs">
                <div v-if="filteredJobs.length == 0">
                  <div class="no-archived-container">
                    <div class="no-archived">
                      <svg
                        width="125"
                        height="107"
                        viewBox="0 0 125 107"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M84.6783 4.47897C84.6783 4.47897 67.5568 26.1989 40.1618 13.2436C12.7668 0.288249 -14.6278 35.3442 8.96193 56.6832C32.5516 78.0222 9.46734 89.7478 25.4935 102.826C43.7082 117.69 59.5663 87.5485 74.0246 94.4075C105.935 109.545 129.575 103.553 124.248 86.0243C114.898 55.2554 102.941 53.2537 112.073 37.2495C121.205 21.2453 101.051 -12.0315 84.6783 4.47897Z"
                          fill="#FCE0E5"
                        />
                        <path
                          d="M94.2 25.5789H90.2895C85.9875 25.5789 82.5 22.0915 82.5 17.7895C82.5 13.4663 79.029 10 74.7 10H51.3C46.971 10 43.5 13.4663 43.5 17.7895C43.5 22.0915 40.0125 25.5789 35.7105 25.5789H31.8C27.471 25.5789 24 29.0453 24 33.3684V76.2105C24 80.5337 27.471 84 31.8 84H94.2C98.529 84 102 80.5337 102 76.2105V33.3684C102 29.0453 98.529 25.5789 94.2 25.5789ZM51.3 21.6842C51.3 19.5332 53.0437 17.7895 55.1947 17.7895H70.8053C72.9563 17.7895 74.7 19.5332 74.7 21.6842C74.7 23.8352 72.9563 25.5789 70.8053 25.5789H55.1947C53.0437 25.5789 51.3 23.8352 51.3 21.6842ZM94.2 72.3158C94.2 74.4668 92.4563 76.2105 90.3053 76.2105H35.6947C33.5437 76.2105 31.8 74.4668 31.8 72.3158C31.8 70.1648 33.5437 68.4211 35.6947 68.4211H90.3053C92.4563 68.4211 94.2 70.1648 94.2 72.3158ZM94.2 46.7368C94.2 52.2597 89.7229 56.7368 84.2 56.7368H41.8C36.2771 56.7368 31.8 52.2597 31.8 46.7368V39.2184C31.8 35.9876 34.4191 33.3684 37.65 33.3684H39.6053C41.7563 33.3684 43.5 35.1122 43.5 37.2632C43.5 39.4142 45.249 41.1579 47.4 41.1579C49.551 41.1579 51.3 39.4142 51.3 37.2632C51.3 35.1122 53.0437 33.3684 55.1947 33.3684H70.8053C72.9563 33.3684 74.7 35.1122 74.7 37.2632C74.7 39.4142 76.449 41.1579 78.6 41.1579C80.751 41.1579 82.5 39.4142 82.5 37.2632C82.5 35.1122 84.2437 33.3684 86.3947 33.3684H88.35C91.5809 33.3684 94.2 35.9876 94.2 39.2184V46.7368Z"
                          fill="#17865F"
                        />
                      </svg>
                      <p>You have no Jobs listed</p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="job in filteredJobs"
                    :key="job.id"
                    class="job"
                    @click="openJobDetails(job)"
                  >
                    <div class="job-header-content">
                      <div>
                        <p>Posted {{ job.posted }} ago</p>
                      </div>
                      <div class="job-header-content-job-title">
                        <h2>{{ job.title }}</h2>
                        <svg
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36H10.94C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z"
                            fill="#979797"
                          />
                        </svg>
                      </div>
                      <div>
                        <p>Est. Budget: {{ job.budget }}</p>
                      </div>
                    </div>
                    <div class="job-body-content">
                      <p>{{ job.description }}</p>
                    </div>

                    <div class="job-tag">
                      <span v-for="tag in job.tags" :key="tag">{{ tag }}</span>
                    </div>
                    <!-- <button @click="saveJob(job.id)">Save</button> -->
                  </div>
                </div>
              </section>

              <section v-if="currentTab === 'savedJobs'" class="jobs">
                <div v-if="filteredJobs.length == 0">
                  <div class="no-archived-container">
                    <div class="no-archived">
                      <svg
                        width="125"
                        height="107"
                        viewBox="0 0 125 107"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M84.6783 4.47897C84.6783 4.47897 67.5568 26.1989 40.1618 13.2436C12.7668 0.288249 -14.6278 35.3442 8.96193 56.6832C32.5516 78.0222 9.46734 89.7478 25.4935 102.826C43.7082 117.69 59.5663 87.5485 74.0246 94.4075C105.935 109.545 129.575 103.553 124.248 86.0243C114.898 55.2554 102.941 53.2537 112.073 37.2495C121.205 21.2453 101.051 -12.0315 84.6783 4.47897Z"
                          fill="#FCE0E5"
                        />
                        <path
                          d="M94.2 25.5789H90.2895C85.9875 25.5789 82.5 22.0915 82.5 17.7895C82.5 13.4663 79.029 10 74.7 10H51.3C46.971 10 43.5 13.4663 43.5 17.7895C43.5 22.0915 40.0125 25.5789 35.7105 25.5789H31.8C27.471 25.5789 24 29.0453 24 33.3684V76.2105C24 80.5337 27.471 84 31.8 84H94.2C98.529 84 102 80.5337 102 76.2105V33.3684C102 29.0453 98.529 25.5789 94.2 25.5789ZM51.3 21.6842C51.3 19.5332 53.0437 17.7895 55.1947 17.7895H70.8053C72.9563 17.7895 74.7 19.5332 74.7 21.6842C74.7 23.8352 72.9563 25.5789 70.8053 25.5789H55.1947C53.0437 25.5789 51.3 23.8352 51.3 21.6842ZM94.2 72.3158C94.2 74.4668 92.4563 76.2105 90.3053 76.2105H35.6947C33.5437 76.2105 31.8 74.4668 31.8 72.3158C31.8 70.1648 33.5437 68.4211 35.6947 68.4211H90.3053C92.4563 68.4211 94.2 70.1648 94.2 72.3158ZM94.2 46.7368C94.2 52.2597 89.7229 56.7368 84.2 56.7368H41.8C36.2771 56.7368 31.8 52.2597 31.8 46.7368V39.2184C31.8 35.9876 34.4191 33.3684 37.65 33.3684H39.6053C41.7563 33.3684 43.5 35.1122 43.5 37.2632C43.5 39.4142 45.249 41.1579 47.4 41.1579C49.551 41.1579 51.3 39.4142 51.3 37.2632C51.3 35.1122 53.0437 33.3684 55.1947 33.3684H70.8053C72.9563 33.3684 74.7 35.1122 74.7 37.2632C74.7 39.4142 76.449 41.1579 78.6 41.1579C80.751 41.1579 82.5 39.4142 82.5 37.2632C82.5 35.1122 84.2437 33.3684 86.3947 33.3684H88.35C91.5809 33.3684 94.2 35.9876 94.2 39.2184V46.7368Z"
                          fill="#17865F"
                        />
                      </svg>
                      <p>You have no Jobs listed</p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="job in filteredJobs"
                    :key="job.id"
                    class="job"
                    @click="openJobDetails(job)"
                  >
                    <div class="job-header-content">
                      <div>
                        <p>Posted {{ job.posted }} ago</p>
                      </div>
                      <div class="job-header-content-job-title">
                        <h2>{{ job.title }}</h2>
                        <svg
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 0C12.76 0 11.09 0.81 10 2.09C8.91 0.81 7.24 0 5.5 0C2.42 0 0 2.42 0 5.5C0 9.28 3.4 12.36 8.55 17.04L10 18.35L11.45 17.03C16.6 12.36 20 9.28 20 5.5C20 2.42 17.58 0 14.5 0ZM10.1 15.55L10 15.65L9.9 15.55C5.14 11.24 2 8.39 2 5.5C2 3.5 3.5 2 5.5 2C7.04 2 8.54 2.99 9.07 4.36H10.94C11.46 2.99 12.96 2 14.5 2C16.5 2 18 3.5 18 5.5C18 8.39 14.86 11.24 10.1 15.55Z"
                            fill="#979797"
                          />
                        </svg>
                      </div>
                      <div>
                        <p>Est. Budget: {{ job.budget }}</p>
                      </div>
                    </div>
                    <div class="job-body-content">
                      <p>{{ job.description }}</p>
                    </div>

                    <div class="job-tag">
                      <span v-for="tag in job.tags" :key="tag">{{ tag }}</span>
                    </div>
                    <!-- <button @click="saveJob(job.id)">Save</button> -->
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div class="second-dashboard-container">
            <div class="second-dashboard-container-main">
              <div class="second-dashboard-container-profile-div">
                <div class="profile">
                  <img
                    :src="
                      userProfileState.profile_picture ||
                      require(`@/assets/${defaultImage}`)
                    "
                    alt="Profile Picture"
                  />
                </div>
                <div class="second-dashboard-container-profile-div-user">
                  <h3>{{ userProfileState.user.name }}</h3>
                  <p>Professional Caregiver</p>
                </div>
              </div>
              <div class="second-dashboard-container-profile-settings">
                <div class="availability">
                  <div class="availability-header">
                    <h4>Availability</h4>
                    <div
                      style="cursor: pointer"
                      @click="showModal('availability')"
                    >
                      <svg
                        width="166"
                        height="18"
                        viewBox="0 0 166 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M164.057 0.58793L165.407 1.93793C166.197 2.71793 166.197 3.98793 165.407 4.76793L152.177 17.9979H147.997V13.8179L158.397 3.40793L161.227 0.58793C162.007 -0.19207 163.277 -0.19207 164.057 0.58793ZM149.997 15.9979L151.407 16.0579L161.227 6.22793L159.817 4.81793L149.997 14.6379V15.9979Z"
                          fill="#979797"
                        />
                      </svg>
                    </div>
                  </div>
                  <p>{{ userProfileState.user.availability }}</p>
                </div>
                <div class="categories">
                  <div class="categories-header">
                    <h4>Categories</h4>
                    <div style="cursor: pointer" @click="showModal('services')">
                      <svg
                        width="166"
                        height="18"
                        viewBox="0 0 166 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M164.057 0.58793L165.407 1.93793C166.197 2.71793 166.197 3.98793 165.407 4.76793L152.177 17.9979H147.997V13.8179L158.397 3.40793L161.227 0.58793C162.007 -0.19207 163.277 -0.19207 164.057 0.58793ZM149.997 15.9979L151.407 16.0579L161.227 6.22793L159.817 4.81793L149.997 14.6379V15.9979Z"
                          fill="#979797"
                        />
                      </svg>
                    </div>
                  </div>
                  <div
                    v-for="category in userProfileState.user.category"
                    :key="category"
                  >
                    <p>{{ category.name }}</p>
                  </div>
                </div>
                <div class="preferences">
                  <div class="job-preferences-header">
                    <h4>Job Preferences</h4>
                    <div
                      style="cursor: pointer"
                      @click="showModal('preferences')"
                    >
                      <svg
                        width="166"
                        height="18"
                        viewBox="0 0 166 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M164.057 0.58793L165.407 1.93793C166.197 2.71793 166.197 3.98793 165.407 4.76793L152.177 17.9979H147.997V13.8179L158.397 3.40793L161.227 0.58793C162.007 -0.19207 163.277 -0.19207 164.057 0.58793ZM149.997 15.9979L151.407 16.0579L161.227 6.22793L159.817 4.81793L149.997 14.6379V15.9979Z"
                          fill="#979797"
                        />
                      </svg>
                    </div>
                  </div>

                  <p>{{ userProfileState.user.preferences }}</p>
                </div>
                <div v-if="!bankAccountStatus" class="preferences">
                  <div class="job-preferences-header">
                    <h4>Salary & Virtual Account</h4>
                    <div
                      style="cursor: pointer"
                      @click="showModal('virtual_account')"
                    >
                      <svg
                        width="166"
                        height="18"
                        viewBox="0 0 166 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M164.057 0.58793L165.407 1.93793C166.197 2.71793 166.197 3.98793 165.407 4.76793L152.177 17.9979H147.997V13.8179L158.397 3.40793L161.227 0.58793C162.007 -0.19207 163.277 -0.19207 164.057 0.58793ZM149.997 15.9979L151.407 16.0579L161.227 6.22793L159.817 4.81793L149.997 14.6379V15.9979Z"
                          fill="#979797"
                        />
                      </svg>
                    </div>
                  </div>

                  <!-- <p>{{ userProfileState.user.preferences }}</p> -->
                </div>
                <div>
                  <div
                    v-if="
                      !bankAccountDetails || bankAccountDetails?.error_message
                    "
                  >
                    <h4>Virtual Account Creation Update</h4>
                    <p>{{ bankAccountDetails?.error_message }}</p>
                  </div>
                  <div
                    v-if="
                      bankAccountDetails?.vba_bank &&
                      bankAccountDetails?.vba_name &&
                      bankAccountDetails?.vba_number
                    "
                  >
                    <h4>Virtual Account</h4>
                    <p>{{ bankAccountDetails?.vba_bank }}</p>
                    <p>{{ bankAccountDetails?.vba_name }}</p>
                    <p>{{ bankAccountDetails?.vba_number }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Job Details Modal -->
        </div>
        <!-- start main modals -->

        <GenericModal
          :isVisible="isMainModalVisible"
          :title="modalTitle"
          @close="hideModal"
          @confirm="handleConfirm"
        >
          <template v-if="currentModal === 'virtual_account'">
            <!-- Availability form content -->

            <div class="availability-container">
              <div style="max-width: 400px; width: 100%">
                <div
                  v-if="salaryAccountSubmitted"
                  class="update-salary-account"
                >
                  <div v-if="accountValidCheck">
                    <label for="bank">Bank</label>
                    <div
                      style="margin-bottom: 30px; margin-top: 10px"
                      :class="['custom-multiselect']"
                    >
                      <Multiselect
                        id="Bank"
                        label="text"
                        v-model="selectedBank"
                        :options="banksOptions"
                        track-by="text"
                        :searchable="true"
                        placeholder="Banks"
                        class="custom-multiselect"
                      >
                        <template #caret>
                          <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                              fill="#979797"
                            />
                          </svg> </template
                      ></Multiselect>
                    </div>
                    <div style="margin-bottom: 30px">
                      <label for="account_number">Account Number</label>
                      <input
                        class="account_number"
                        style="width: 100%; margin-top: 10px"
                        type="text"
                        v-model="account_number"
                        placeholder="Enter Account Number"
                      />
                    </div>
                    <div
                      v-if="accountError"
                      style="color: red; margin-bottom: 20px"
                    >
                      <p>{{ accountError }}</p>
                    </div>
                  </div>
                  <div
                    v-if="accountName"
                    style="
                      color: red;
                      display: flex;
                      gap: 10px;
                      display: flex;
                      flex-direction: column;
                      padding-bottom: 30px;
                    "
                  >
                    <h1 style="font-size: 16px; text-align: center">
                      Your Bank Details
                    </h1>
                    <p>{{ selectedBankName }}</p>
                    <p>{{ accountName }}</p>
                    <p>{{ accontNumber }}</p>
                  </div>
                  <BaseButton
                    :label="isBankAccount ? 'Updating...' : updateStage"
                    backgroundColor="var(--s-pink-normal)"
                    textColor="var(--state-white)"
                    height="60px"
                    width="100%"
                    @click="
                      () =>
                        updateStage === 'Next'
                          ? handleBankAccount()
                          : handleSaveAccount()
                    "
                  />
                </div>
                <div v-if="createVirtualAccount">
                  <div style="margin-bottom: 30px">
                    <label for="account_number">BVN</label>
                    <input
                      class="account_number"
                      style="width: 100%; margin-top: 10px"
                      type="text"
                      v-model="bvn"
                      placeholder="Enter BVN"
                    />
                  </div>
                  <BaseButton
                    :label="isBankAccount ? 'Updating...' : updateStage"
                    backgroundColor="var(--s-pink-normal)"
                    textColor="var(--state-white)"
                    height="60px"
                    width="100%"
                    @click="virtualAccount"
                  />
                </div>
              </div>
            </div>
          </template>
          <template v-if="currentModal === 'availability'">
            <!-- Availability form content -->

            <div class="availability-container">
              <div style="max-width: 400px">
                <div class="days-container">
                  <button
                    v-for="day in days"
                    :key="day.short"
                    :class="[
                      'day-button',
                      { selected: selectedDays.includes(day.full) },
                    ]"
                    @click="toggleDay(day.full)"
                  >
                    {{ day.short }}
                  </button>
                </div>

                <BaseButton
                  :label="isAvailability ? 'Updating...' : 'Update'"
                  backgroundColor="var(--s-pink-normal)"
                  textColor="var(--state-white)"
                  height="60px"
                  width="100%"
                  @click="handleAvailability"
                />
              </div>
            </div>
          </template>

          <template v-if="currentModal === 'services'">
            <!-- Services form content -->
            <div class="categories">
              <!-- <div class="selected-options">
            <span
              v-for="(option, index) in selectedOptions"
              :key="index"
              class="selected-option"
            >
              {{ option }}
            </span>
          </div> -->

              <div
                v-for="(category, index) in categories"
                :key="index"
                class="care-button"
                :class="{ focus: category.showDropdown }"
              >
                <div class="care-button-first" @click="toggleDropdown(index)">
                  {{ category.name }}
                  <span v-if="category.showDropdown">
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.68141e-08 6.50016L1.41 7.91016L6 3.33016L10.59 7.91016L12 6.50016L6 0.500156L1.68141e-08 6.50016Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  <span v-else>
                    <svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 1.91L10.59 0.5L6 5.08L1.41 0.5L-6.16331e-08 1.91L6 7.91L12 1.91Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                </div>
                <div v-if="category.showDropdown" class="dropdown">
                  <div
                    v-for="option in category.options"
                    :key="option.id"
                    class="option-container"
                  >
                    <input
                      type="checkbox"
                      :id="`option-${option.id}`"
                      v-model="option.selected"
                      @change="updateSelectedOptions"
                    />
                    <label :for="`option-${option.id}`">{{
                      option.name
                    }}</label>
                  </div>
                </div>
              </div>

              <BaseButton
                :label="isCategory ? 'Updating...' : 'Update'"
                backgroundColor="var(--s-pink-normal)"
                textColor="var(--state-white)"
                height="60px"
                width="100%"
                @click="handleCategory"
              />
            </div>
          </template>
          <template v-if="currentModal === 'preferences'">
            <!-- Preferences form content -->
            <div :class="['custom-multiselect']">
              <Multiselect
                id="level_of_education"
                label="text"
                v-model="preferences"
                track-by="value"
                placeholder="Level of Education"
                class="custom-multiselect"
                :options="preferencesOptions"
              >
                <template #caret>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                      fill="#979797"
                    />
                  </svg> </template
              ></Multiselect>
            </div>
            <BaseButton
              :label="isWorkPreference ? 'Updating...' : 'Update'"
              backgroundColor="var(--s-pink-normal)"
              textColor="var(--state-white)"
              height="60px"
              width="100%"
              @click="handleWorkPreference"
            />
          </template>
        </GenericModal>

        <!-- end main modals -->
        <SideModal
          :isVisible="isModalVisible"
          :job="selectedJob"
          @close="closeJobDetails"
        />
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import GenericModal from "@/components/GenericModal.vue";
import BaseButton from "@/components/BaseButton.vue";
import SideModal from "@/components/SideModal.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Request from "@/utils/apiclient.js";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
import { formatErrorMessages } from "@/utils/errorHandler";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useRouter } from "vue-router";
export default {
  name: "CareGiverHome",
  components: {
    GenericModal,
    SideModal,
    BaseButton,
    Multiselect,
    DashboardLayout,
    ToastManagerVue,
    LoadingSpinner,
  },
  setup() {
    const bankAccountStatus = ref(true);
const router = useRouter();
    const isLoading = ref(true);
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const userData = ref();
    const userProfileState = ref({
      first_name: "",
      last_name: "",
      user: {
        profile_picture: "",
        name: "",
        title: "Professional Caregiver",
        availability: "",
        category: [],
        preferences: "",
      },
    });
    const defaultImage = ref("default_profile_image.png");
    const jobs = ref([
      // {
      //   id: 1,
      //   posted: "5 minutes",
      //   title: "Senior Care Needed",
      //   budget: "₦20,000",
      //   description: "I need a sit in care giver for my mom of 75 years...",
      //   tags: ["Bathing / Dressing", "Special Care"],
      // },
      // {
      //   id: 2,
      //   posted: "5 minutes",
      //   title: "Senior Care Needed",
      //   budget: "₦20,000",
      //   description: "I need a sit in care giver for my mom of 75 years...",
      //   tags: ["Bathing / Dressing", "Special Care"],
      // },
    ]);

    const user = ref({
      image: "profileimage.png",
      name: "Jane Adeyemi",
      title: "Professional Caregiver",
      availability: "Mondays - Friday",
      categories: ["Child Care", "Senior Care", "Academic Care", "Home Care"],
      preferences: "Live Out",
    });

    const searchQuery = ref("");
    const currentTab = ref("myFeed");
    const isModalVisible = ref(false);
    const selectedJob = ref({});

    const filteredJobs = computed(() => {
      return jobs.value.filter((job) =>
        job.title.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    const setTab = (tab) => {
      currentTab.value = tab;
    };

    const saveJob = (jobId) => {
      console.log(`Job ${jobId} saved!`);
    };

    const openJobDetails = (job) => {
      selectedJob.value = job;
      isModalVisible.value = true;
    };

    const closeJobDetails = () => {
      isModalVisible.value = false;
      selectedJob.value = null;
    };

    const isMainModalVisible = ref(false);
    const currentModal = ref("");
    const modalTitle = ref("");

    const showModal = (modalType) => {
      currentModal.value = modalType;
      modalTitle.value = getModalTitle(modalType);
      isMainModalVisible.value = true;
    };

    const hideModal = () => {
      isMainModalVisible.value = false;
    };

    const getModalTitle = (modalType) => {
      switch (modalType) {
        case "availability":
          return "Update your availability";
        case "services":
          return "Update your services";
        case "preferences":
          return "Update your preferences";
        case "virtual_account":
          return "Update your payment information";
        default:
          return "";
      }
    };

    const handleConfirm = () => {
      hideModal();
    };

    const days = [
      { short: "Sun", full: "Sunday" },
      { short: "Mon", full: "Monday" },
      { short: "Tue", full: "Tuesday" },
      { short: "Wed", full: "Wednesday" },
      { short: "Thu", full: "Thursday" },
      { short: "Fri", full: "Friday" },
      { short: "Sat", full: "Saturday" },
    ];

    const timeOptions = [
      "12:00 AM",
      "1:00 AM",
      "2:00 AM",
      "3:00 AM",
      "4:00 AM",
      "5:00 AM",
      "6:00 AM",
      "7:00 AM",
      "8:00 AM",
      "9:00 AM",
      "10:00 AM",
      "11:00 AM",
      "12:00 PM",
      "1:00 PM",
      "2:00 PM",
      "3:00 PM",
      "4:00 PM",
      "5:00 PM",
      "6:00 PM",
      "7:00 PM",
      "8:00 PM",
      "9:00 PM",
      "10:00 PM",
      "11:00 PM",
    ];

    const selectedDays = ref([]);
    const showSpecificTime = ref(false);

    const toggleDay = (day) => {
      if (selectedDays.value.includes(day)) {
        selectedDays.value = selectedDays.value.filter((d) => d !== day);
      } else {
        selectedDays.value.push(day);
      }
    };

    const transformCategories = (data) => {
      const selectedSubcategoryIds = new Set(
        data.sub_category.map((sub) => sub.id)
      );

      return data.category.map((category) => ({
        id: category.id,
        name: category.name,
        showDropdown: false,
        options: category.sub_categories.map((sub) => ({
          id: sub.id,
          name: sub.name,
          selected: selectedSubcategoryIds.has(sub.id),
        })),
      }));
    };

    const categories = ref([]);
    const selectedOptions = ref([]);

    const toggleDropdown = (index) => {
      categories.value[index].showDropdown =
        !categories.value[index].showDropdown;
    };

    const updateSelectedOptions = () => {
      selectedOptions.value = categories.value.flatMap((category) =>
        category.options.filter((opt) => opt.selected).map((opt) => opt.id)
      );
    };

    const preferences = ref("");
    const preferencesOptions = ref([
      { value: "Live In", text: "Live In" },
      { value: "Live Out", text: "Live Out" },
      { value: "Both", text: "Both" },
    ]);

    const isNotApproved = ref(false);
    const userProfile = JSON.parse(localStorage.getItem("_userProfile"));
    const bankAccountDetails = ref({});
    const fetchProfile = () => {
      const request = new Request(`/profile/${userProfile.id}/`);
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here is profile data", res);
          userData.value = res;
          localStorage.setItem("_userProfile", JSON.stringify(res));
          selectedDays.value = res.care_giver.availabilities[0].days;
          userProfileState.value.first_name = res.first_name;
          userProfileState.value.last_name = res.last_name;
          userProfileState.value.profile_picture = res.profile_picture;
          userProfileState.value.user.name =
            res.first_name + " " + res.last_name;
          userProfileState.value.user.availability =
            res.care_giver.availabilities[0].days.join(", ");
          userProfileState.value.user.preferences =
            res.care_giver.work_preference;
          userProfileState.value.user.category = res.care_giver.category;
          preferences.value = res.care_giver.work_preference;
          categories.value = transformCategories(res.care_giver);
          isNotApproved.value =
            res.care_giver.approval_status == 0 ? true : false;
          //  isNotApproved.value = false;
          // Check if the care giver has a bank account and assign true if it exists, false otherwise
          bankAccountStatus.value =
            res.care_giver.bank_account &&
            res.care_giver.bank_account.account_number &&
            res.care_giver.bank_account.vba_number;
          bankAccountDetails.value = { ...res.care_giver.bank_account };
          console.log("bank", bankAccountDetails.value);
          if (
            res.care_giver.bank_account.error_message ||
            (res.care_giver.bank_account &&
              res.care_giver.bank_account.account_number)
          ) {
            salaryAccountSubmitted.value = false;
            createVirtualAccount.value = true;
          }

          updateSelectedOptions();
        }
      });
    };

    const account_number = ref("");
    const selectedBank = ref(null);
    const banksOptions = ref([]);

    // Function to fetch banks data from the API
    const fetchBanks = () => {
      const request = new Request(`/banks/`);
      // const request = new Request('/user/')
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here is banks data", res);
          banksOptions.value = res.map((bank) => ({
            text: bank.name, // Customize according to your API response structure
            value: bank.id, // Customize according to your API response structure
            // id: bank.id
          }));
          console.log("banksOptions", banksOptions.value);
        }
      });
    };
    // Computed property to get the selected bank name
    const selectedBankName = computed(() => {
      const bank = banksOptions.value.find(
        (option) => option.value === selectedBank.value
      );
      return bank ? bank.text : "";
    });

    onMounted(() => {
      fetchProfile();
      fetchBanks();
    });
    const accountName = ref("");
    const bvn = ref("");
    const accountError = ref("");
    let updateStage = ref("Next");
    const isBankAccount = ref(false);
    const accountValidCheck = ref(true);
    const accontNumber = ref("");
    const handleBankAccount = () => {
      isLoading.value = true;
      if (isBankAccount.value) return;
      isBankAccount.value = true;
      if (!selectedBank.value || !account_number.value) {
        accountName.value = "Please select a bank and enter an account number";
        isLoading.value = false;
        return;
      }
      console.log("here", selectedBank.value);
      const request = new Request(
        `/banks/resolve-bank/?account_number=${account_number.value}&bank_id=${selectedBank.value}`
      );
      request.get((res, err) => {
        isLoading.value = false;
        isBankAccount.value = false;
        if (err) {
          console.error("Error:", err);
          if (res.status == false) {
            accountName.value =
              "Your account information is incorrect, please check and try again";
          }
        } else {
          console.log("here is response resolve", res);
          if (res.status == true) {
            accontNumber.value = res.data.account_number;
            accountName.value = res.data.account_name;
            updateStage.value = "Save Account";
            accountValidCheck.value = false;
          } else {
            accountError.value =
              "Your account information is incorrect, please check and try again";
          }
        }
      });
    };
    const salaryAccountSubmitted = ref(true);
    const createVirtualAccount = ref(false);
    const handleSaveAccount = () => {
      isLoading.value = true;
      console.log("here", selectedBank.value);
      const request = new Request(`/bank-account/`);
      const bankAccountPayload = {
        account_number: accontNumber.value,
        // account_name: accountName.value,
        bank: selectedBank.value,
      };
      const rawData = JSON.stringify(bankAccountPayload);
      request.post(rawData, (res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error save acc:", err);

          if (err.status == false) {
            showToast(err.error, "error");
            if (
              err.error == "A bank account already exists for this CareGiver."
            ) {
              salaryAccountSubmitted.value = false;
              createVirtualAccount.value = true;
            }
          }
        } else {
          console.log("here is response save account", res);
          if (res.status == true) {
            salaryAccountSubmitted.value = false;
            createVirtualAccount.value = true;
            updateStage.value = "Create Virtual Account";
            showToast("Salary Account Update Successful", "success");
          } else {
            showToast("Salary Account Update Failed", "error");
          }
        }
      });
    };
    const virtualAccount = () => {
      isLoading.value = true;
      console.log("here", selectedBank.value);
      const request = new Request(`/banks/virtual-bank/`);
      const bvnPayload = {
        bvn: bvn.value,
        // name: accountName.value,
      };
      const rawData = JSON.stringify(bvnPayload);
      request.post(rawData, (res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error save acc:", err);
          showToast("Salary Account Update Failed", "error");
        } else {
          console.log("here is response save account", res);
          if (res.message == "Customer Identification in progress") {
            showToast("Virtual Account Creation in progress", "success");
            hideModal();
          } else {
            showToast("Virtual Account Creation Failed", "error");
          }
        }
      });
    };
    const isAvailability = ref(false);
    const handleAvailability = () => {
      if (isAvailability.value) return;
      isAvailability.value = true;
      //  let avail = {...selectedDays.value}
      const availabilityPayload = { days: selectedDays.value };
      const rawData = JSON.stringify(availabilityPayload);
      console.log("raw data", rawData);
      console.log("user data", userData);
      const request = new Request(
        `/availability/${userData.value.care_giver.availabilities[0].id}/`
        // `/care-giver/${userData.value.care_giver.id}/`
      );
      request.put(rawData, (data, error) => {
        isAvailability.value = false;
        if (error) {
          console.log("data error", error);
          if (error.status === "failure") {
            return showToast(error.message, "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("Success:", data);
          fetchProfile();
          hideModal();
          showToast("Availability Update Successful", "success");
        }
      });
    };
    const isWorkPreference = ref(false);
    const handleWorkPreference = () => {
      if (isWorkPreference.value) return;
      isWorkPreference.value = true;
      const workPreferencePayload = { work_preference: preferences.value };
      const rawData = JSON.stringify(workPreferencePayload);
      console.log("raw data", rawData);
      const request = new Request(
        `/care-giver/${userData.value.care_giver.id}/`
      );
      request.patch(rawData, (data, error) => {
        isWorkPreference.value = false;
        if (error) {
          console.log("data error", error);
          if (error.status === "failure") {
            return showToast(error.message, "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("Success:", data);
          fetchProfile();
          hideModal();
          showToast("Work Preference Update Successful", "success");
        }
      });
    };
    const isCategory = ref(false);
    const handleCategory = () => {
      if (isCategory.value) return;
      isCategory.value = true;
      const categoryPayload = {
        sub_category: selectedOptions.value,
      };
      const rawData = JSON.stringify(categoryPayload);
      console.log("raw data", rawData);
      const request = new Request(
        `/care-giver/${userData.value.care_giver.id}/`
      );
      request.patch(rawData, (data, error) => {
        isCategory.value = false;
        if (error) {
          console.log("data error", error);
          if (error.status === "failure") {
            return showToast(error.message, "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("Success:", data);
          fetchProfile();
          hideModal();
          showToast("Category Update Successful", "success");
        }
      });
    };
    const handleLogout = () => {
      router.push("/login")
    }

    return {
      jobs,
      user,
      searchQuery,
      currentTab,
      filteredJobs,
      setTab,
      saveJob,
      openJobDetails,
      closeJobDetails,
      selectedJob,
      isModalVisible,
      isMainModalVisible,
      currentModal,
      modalTitle,
      showModal,
      hideModal,
      handleConfirm,
      getModalTitle,
      days,
      timeOptions,
      selectedDays,
      showSpecificTime,
      toggleDay,
      categories,
      selectedOptions,
      toggleDropdown,
      updateSelectedOptions,
      preferencesOptions,
      preferences,
      userProfileState,
      toastManager,
      handleAvailability,
      handleWorkPreference,
      handleCategory,
      isNotApproved,
      isLoading,
      isCategory,
      isAvailability,
      defaultImage,
      isWorkPreference,
      account_number,
      selectedBank,
      banksOptions,
      handleBankAccount,
      accountName,
      updateStage,
      handleSaveAccount,
      accountValidCheck,
      accontNumber,
      selectedBankName,
      accountError,
      salaryAccountSubmitted,
      createVirtualAccount,
      virtualAccount,
      bvn,
      bankAccountStatus,
      bankAccountDetails,
      handleLogout
    };
  },
};
</script>


<style scoped>
.the-container {
  display: flex;
  width: 1184px;
}
.overall-container {
  display: flex;
  width: 100%;
  justify-content: center;
  /* padding-left: 128px */
}
.login-container {
  max-width: 400px;
  margin: auto;
}

h2 {
  margin-bottom: 20px;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.5px;
  text-align: center;
}

.login-button {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}

.login-button:hover {
  background-color: var(--s-pink-normal-hover);
}

.forgot-password {
  display: block;
  margin-bottom: 34px;
  color: var(--s-green-normal);
  text-decoration: none;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password:hover {
  color: var(--s-green-normal-hover);
}

.google-login,
.apple-login {
  width: 100%;
  padding: 10px 67px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 10px;
  font-size: var(--font-body-large-regular-size);
  font-weight: var(--font-weight-regular);
  line-height: 34px;
  font-family: var(--font-family);
  white-space: nowrap;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.google-login img,
.apple-login img {
  width: 40.67px;
  height: 40.67px;
}

/* .google-login:hover,
.apple-login:hover {
  background-color: #f5f5f5;
} */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
}
.separator:not(:empty)::before {
  margin-right: 8px;
}
.separator:not(:empty)::after {
  margin-left: 8px;
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-form {
  padding-bottom: 200px;
}

.dashboard-main-container {
  width: 1168px;
  display: flex;
  justify-content: center;
  gap: 24px;
  height: 100%;
  margin-top: 33px;
}

.first-dashboard-container {
  width: 100%;
  width: 772px;
  box-sizing: border-box;
}
.second-dashboard-container {
  width: 100%;
  width: 372px;
  box-sizing: border-box;
  padding-top: 76px;
}
h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 24px;
}
.search-wrapper input {
  border: 1px solid #979797 !important;
  width: 575px;
  height: 64px;
  padding: 10px 10px 10px 70px;
  gap: 16px;
  border-radius: 12px;
  border: 1px;
  box-sizing: border-box;
  background-color: transparent;
  z-index: 1;
}
.search-wrapper input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: #979797;
}
.account_number {
  border: 1px solid #979797 !important;

  height: 64px;
  padding: 10px 10px 10px 24px;
  gap: 16px;
  border-radius: 12px;
  border: 1px;
  box-sizing: border-box;
  background-color: transparent;
  z-index: 1;
}
.account_number::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: #979797;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  z-index: 1;
}
.search-icon {
  position: absolute;
  left: 20px;
  z-index: 1;
}

.active {
  border-bottom: 3px solid var(--s-pink-normal);
}
.active p {
  color: var(--s-pink-normal) !important;
  font-weight: var(--font-weight-bold) !important;
}
.single-tab {
  padding: 0px 20.5px 4px 20.5px;
  cursor: pointer;
}
.tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 42px;
}
.tabs p {
  color: #0a3828;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  font-weight: var(--font-weight-regular);
}
.jobs-container {
  padding-top: 32px;
}
.job {
  padding: 34px 16px 34px 16px;
  border-bottom: 0.5px solid black;
  cursor: pointer;
}
.job-header-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  margin-bottom: 10px;
}
.job-header-content p {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
}
.job-header-content-job-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job-header-content-job-title h2 {
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
}
.job-body-content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: justified;
}
.job-body-content {
  margin-bottom: 10px;
}
.job-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.job-tag span {
  padding: 10px;
  gap: 8px;
  border-radius: 12px;
  background-color: #ececec;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.second-dashboard-container-main {
  display: flex;
  gap: 18px;
  flex-direction: column;
}
.second-dashboard-container-profile-div {
  background-color: #e8f3ef80;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px 16px;
}
.second-dashboard-container-profile-div img {
  width: 71px;
  height: 71px;
  border-radius: 50%;
}
.second-dashboard-container-profile-settings {
  background-color: #e8f3ef80;
}
.second-dashboard-container-profile-div-user {
  display: flex;
  flex-direction: column;
}
.second-dashboard-container-profile-div-user h3 {
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.second-dashboard-container-profile-div-user p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.second-dashboard-container-profile-settings {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.second-dashboard-container-profile-settings h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  margin: 0px;
}
.availability-header,
.categories-header,
.job-preferences-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.availability,
.categories,
.preferences p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
}
.availability,
.categories,
.preferences {
  padding: 10px 0px 10px 0px;
}

/* start availability */
.availability-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.days-container,
.times-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.day-button,
.time-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.day-button.selected,
.time-button.selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal);
}

.specific-time-container {
  margin-bottom: 20px;
  margin-top: 29px;
}

.time-picker {
  display: flex;
  align-items: center;
  gap: 29px;
  margin-top: 10px;
  position: relative;
}

.time-picker select {
  padding: 16px 52px 16px 10px;

  border-radius: 12px;
  border: 1px solid #979797;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z" fill="black"/></svg>')
    no-repeat right 26px center;
  background-size: 10px 6px;
}

.time-picker select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer */
}

.next-button {
  background-color: #ff6978;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

.days-container button {
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.specific-time-container label {
  text-align: left;
}

.add-times {
  color: #0063f7;
  text-decoration: none;
  text-align: left;
}
span {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}
.bottom-text {
  margin-top: 29px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}
/* end availability */
/* start .categories */
input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.selected-options {
  margin-bottom: 16px;
  margin-top: 16px;
}
.selected-option {
  display: inline-block;
  background-color: var(--s-pink-light-hover);
  border-radius: 12px;
  padding: 10px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;

  color: #000000;
  border: 1px solid var(--s-green-normal-active);
}
.focus {
  border: 1px solid var(--s-green-normal);
}

.dropdown {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
}
.dropdown label {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  margin-left: 8px;
  color: #000000;
}

.option-container {
  width: 50%;
  box-sizing: border-box;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.care-button .category {
  margin-top: 10px;
}

.care-button {
  padding: 16px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  margin-top: 20px;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.care-button-first {
  display: flex;
  justify-content: space-between;
}
.care-button {
  max-height: 210px;
}
input[type="checkbox"] {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #979797;
  background-color: transparent;
  display: grid;
  place-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

input[type="checkbox"]::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: transform 0.3s;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
/* end categories */
.custom-multiselect {
  position: relative;
  width: 100%;
}
.multiselect-option.is-selected {
  background: var(--s-green-normal-active);
  color: #000;
}
.custom-multiselect .multiselect {
  z-index: 11;
  width: 100%;
  padding: 10px;
  height: 60px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}

.custom-multiselect .multiselect__content {
  background-color: transparent; /* White background for options */
}
.multiselect {
  background: transparent;
}
.custom-multiselect-selected .multiselect {
  border: 1px solid var(--s-green-normal-active); /* Change to desired color when a value is selected */
}
.state-select {
  position: relative;
  z-index: 2;
}

.city-select {
  position: relative;
  z-index: 1;
}

.state-select .multiselect--active .multiselect__content-wrapper {
  z-index: 3;
}

.city-select .multiselect--active .multiselect__content-wrapper {
  z-index: 2;
}

/* awaiting approval */
.awaiting-approval {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.96); /* Changed to dark shade */
  z-index: 1000; /* Ensure it is above other elements */
}

.application-review {
  width: 400px;
  height: 200px;
  font-size: 18px;
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.no-archived-container {
  display: flex;
  justify-content: center;
  padding-top: 49px;
}
.no-archived {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 575px;
  background-color: #fff;
  padding-top: 59px;
  padding-bottom: 59px;
  flex-direction: column;
}
.no-archived svg {
  margin-bottom: 10px;
}
.no-archived p {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: center;
}
/* end */

@media (max-width: 800px) {
  .application-review {
    width: 100%;
    max-width: 300px;
    height: 100px;

    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .tabs p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 27.2px;
    letter-spacing: 0.5px;
    text-align: left;
    white-space: nowrap;
  }
  .the-container {
    display: flex;
    width: 100%;
    max-width: 350px;
  }
  .dashboard-main-container {
    flex-direction: column-reverse;
    width: 100%;
  }
  .tabs {
    flex-direction: column;
  }
  .job {
    padding: 15px 8px;
  }
  .job-header-content-job-title h2 {
    font-size: 16px;
  }
  .job-body-content p {
    font-size: 10px;
  }
  .job-tag span {
    font-size: 8px;
    padding: 4px;
  }
  .second-dashboard-container-profile-div img {
    width: 40px;
    height: 40px;
  }
  .second-dashboard-container-profile-div-user h3 {
    font-size: 14px;
  }
  .second-dashboard-container-profile-div-user p {
    font-size: 12px;
  }
  .availability,
  .categories,
  .preferences p {
    font-size: 12px;
  }
  .search-wrapper input {
    width: 100%;
    height: 50px;
  }
  .footer {
    padding: 16px;
    background-color: var(--s-green-normal);
    width: 100%;
  }
  .footer-content-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .first-dashboard-container {
    width: 100%;
  }
  .second-dashboard-container {
    width: 100%;
  }
  .second-dashboard-container {
    padding-top: 0px !important;
  }
  .tabs {
    flex-direction: row;
  }
  .dropdown {
    flex-direction: column;
  }
  .second-dashboard-container-profile-div {
    padding: 10px;
  }
  .second-dashboard-container-profile-settings {
    padding: 10px;
  }
  .no-archived p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20.4px;
    letter-spacing: 0.5px;
    text-align: center;
  }
  .no-archived {
    width: 300px;
  }
  .no-archived-container {
    padding-top: 20px;
  }
}
</style>

