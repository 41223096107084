<template>
  <div class="main-table-container">
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManagerVue ref="toastManager" />
    <h2 style="margin-bottom: 10px">Chat List</h2>
    <div class="filter-container">
      <!-- Your filter UI components -->
    </div>
    <div class="careseekers-table-container">
      <GenericTable
        :data="chatList"
        :headers="['S/N', 'Name']"
        :pageSize="pageSize"
        @delist="delistCaregiver"
        @view-care="viewCare"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Request from "@/utils/apiclient.js";
import GenericTable from "@/components/GenericTable.vue";
import ToastManagerVue from "../../../components/ToastAlert/ToastManager.vue";
import { formatErrorMessages } from "@/utils/errorHandler";

export default {
  name: "AdminCareGivers",
  components: {
    LoadingSpinner,
    ToastManagerVue,
    GenericTable,

  },
  setup() {
     
    // Assume `responseData` is the data you received from the endpoint
    const processDataForTable = (responseData) => {
      console.log("RES", responseData);
      const conversations = [];

      for (const [groupName, messages] of Object.entries(responseData)) {
        if (!messages || messages.length === 0) continue;

        // Get the last message in the conversation
        const theGroupName = groupName.split("_")[2]+"_"+groupName.split("_")[3];
        console.log("group name", theGroupName);
        const lastMessage = messages[messages.length - 1];
        console.log("last message", lastMessage);
       
        // Determine careseeker and caregiver usernames based on user types
        let careseekerUsername = "";
        let caregiverUsername = "";

        if (lastMessage.sender.type === "Care seeker") {
         careseekerUsername = lastMessage.sender.first_name + " " + lastMessage.sender.last_name ;
          caregiverUsername = lastMessage.recipient.first_name + " "+ lastMessage.recipient.last_name;
        } else if (lastMessage.sender.type === "Care giver") {
          careseekerUsername = lastMessage.recipient.first_name + " "+ lastMessage.recipient.last_name;
          caregiverUsername = lastMessage.sender.first_name + " " + lastMessage.sender.last_name;
        }

        // Get sender and recipient names
        const senderName = `${lastMessage.sender.first_name} ${lastMessage.sender.last_name}`;
        const recipientName = `${lastMessage.recipient.first_name} ${lastMessage.recipient.last_name}`;

        // Create a conversation summary
        conversations.push({
          theGroupName,
          senderName,
          recipientName,
         caregiverUsername,careseekerUsername,
          lastMessage: lastMessage.message,
          timestamp: lastMessage.timestamp,
        });
      }

      return conversations;
    };

    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const isLoading = ref(true);
    const chatList = ref({});
    const isOpen = ref({
      category: false,
    });

    const pageSize = ref(10);
    const page = ref(1);

    const toggleDropdown = (dropdown) => {
      isOpen.value[dropdown] = !isOpen.value[dropdown];
    };

    const fetchDashboard = () => {
      isLoading.value = true;
      const request = new Request("/admin/chat/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("chat", res);

          chatList.value = processDataForTable(res);
        }
      });
    };

    onMounted(() => {
      fetchDashboard();
    });

    const handleDelist = async (id) => {
      isLoading.value = true;
      const formData = {
        approval_status: 2,
      };
      const request = new Request(`/admin/care-givers/${id}/`);
      const rawData = JSON.stringify(formData);
      request.patch(rawData, (data, error) => {
        isLoading.value = false;
        if (error) {
          if (error.status == "failure") {
            return showToast(error.message, "error");
          }
          if (error.detail) {
            return showToast("Error delisting careseeker", "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          showToast("Careseeker delisted successfully", "success");
          fetchDashboard();
        }
      });
    };

    const delistCaregiver = (caregiver) => {
      handleDelist(caregiver.id);
    };

    const careDetails = ref(null);
    const isCareType = ref(null);
    const notCareView = ref(true);

    const viewCare = (care) => {
      careDetails.value = care;
      notCareView.value = false;
      isCareType.value = care.user.type;
    };

    return {
      isOpen,
      toggleDropdown,

      isLoading,
      toastManager,
      pageSize,
      page,
      delistCaregiver,
      viewCare,
      careDetails,
      isCareType,
      notCareView,
      chatList,
      
    };
  },
};
</script>


<style scoped>
.main-table-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 1008px;
  margin-top: 52px;
}

.filter-container {
  margin-bottom: 20px;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 340px;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #979797;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
}

.custom-options {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 340px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  margin-top: 8px;
  z-index: 10;
  list-style-type: none;
  padding: 0;
}

.custom-options li {
  padding: 8px;
  cursor: pointer;
}

.custom-options li:hover,
.custom-options li.selected {
  background-color: #f4f4f4;
}

.careseekers-table {
  width: 100%;

  border-spacing: 0 13px;
  /* opacity: 0.5; */
}
.careseekers-table-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 12px;
  padding: 24px;
  background-color: #ececec80;
  min-height: 772px;
  margin-bottom: 400px;
}

.careseekers-table th {
  /* opacity: 1; */
  padding: 8px;

  font-size: 16px;
  font-weight: 500;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table td {
  padding: 8px;

  font-size: 16px;
  font-weight: 400;

  letter-spacing: 0.5px;
  text-align: left;
}

.careseekers-table th {
  background-color: #f4f4f4;
}

.careseekers-table td.active {
  width: 70px;
  height: 35px;
  background-color: var(--state-success);
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.careseekers-table td.inactive {
  width: 83px;
  height: 35px;
  padding: 8px;
  background-color: #979797;
  color: var(--state-white);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.dropdown-menu {
  position: absolute;
  right: 60px; /* Align to the right edge of the icon-dots container */
  top: -30px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #0000001a;
  padding: 10px;
  transform: translateY(100%); /* Position below the icon-dots */
  z-index: 10;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  text-align: left;
}

.dropdown-menu .icon {
  margin-right: 8px;
}

.selected-option {
  display: flex;
  align-items: center;
}

.selected-option svg {
  margin-right: 8px;
}
.the-td {
  position: relative;
}
.icon-dots {
  display: flex;
  padding-left: 12px;

  cursor: pointer;
}
/* Pagination styles (commented out for now) */
/*
.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
*/
@media (max-width: 800px) {
  .main-table-container {
    width: 280px;
  }
  .careseekers-table {
    border-spacing: 0px 5px;
  }

  .careseekers-table td.active {
    width: 30px;
    height: 25px;
    background-color: var(--state-success);
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .careseekers-table td.inactive {
    width: 33px;
    height: 25px;
    padding: 8px;
    background-color: #979797;
    color: var(--state-white);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
  .careseekers-table th {
    /* opacity: 1; */
    padding: 8px;

    font-size: 7px;
    font-weight: 500;
    line-height: 27.2px;
    letter-spacing: 0.5px;
    text-align: left;
  }

  .careseekers-table td {
    padding: 8px;

    font-size: 7px;
    font-weight: 400;

    letter-spacing: 0.5px;
    text-align: left;
  }
  .custom-select-wrapper {
    width: 200px;
  }
}
</style>