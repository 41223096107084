<template>
  <LoginLayout>
    <div class="main-container">
      <div class="job-post-confirmation">
        <div class="notification-container">
          <div class="notification">
            <span><img src="@/assets/success.svg" /></span> Your Job post has
            been shared
          </div>
        </div>

        <h2>Be on the lookout for applicants in your inbox</h2>
        <!-- <p class="sub-message">
          Don't wait for caregivers to contact you, upgrade now to reach out to
          them directly.
        </p> -->
        <!-- <h3>Top care givers in your area</h3>
        <div class="caregivers-grid">
          <div
            class="caregiver-card"
            v-for="caregiver in caregivers"
            :key="caregiver.name"
          >
            <img
              src="@/assets/woman.png"
              alt="Caregiver Photo"
              class="caregiver-photo"
            />
            <div class="caregiver-info">
              <div class="caregiver-name">{{ caregiver.name }}</div>
              <div class="caregiver-rating">
                <span
                  v-for="star in 5"
                  :key="star"
                  class="star"
                  :class="{ filled: star <= caregiver.rating }"
                  >★</span
                >
              </div>
              <div class="caregiver-experience">
                {{ caregiver.experience }} Years Exp.
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </LoginLayout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "CareSeekerJobPost",
components:{
    LoginLayout
},
  setup() {
    const router = useRouter();
    const caregivers = ref([
      {
        name: "Taiwo. A",
        rating: 5,
        experience: 5,
        image: "path/to/image1.jpg",
      },
      {
        name: "Ella B.",
        rating: 5,
        experience: 5,
        image: "path/to/image2.jpg",
      },
      {
        name: "Bridget A.",
        rating: 4,
        experience: 5,
        image: "path/to/image3.jpg",
      },
      {
        name: "Jadesola B.",
        rating: 5,
        experience: 5,
        image: "path/to/image4.jpg",
      },
      {
        name: "Blessing Tom",
        rating: 5,
        experience: 5,
        image: "path/to/image5.jpg",
      },
      {
        name: "Taiwo S.",
        rating: 4,
        experience: 5,
        image: "path/to/image6.jpg",
      },
    ]);
  setTimeout(() => {
            router.push("/careseeker/home");
          }, 5000); // 5000ms = 5 seconds delay
    return {
      caregivers,
    };
  },
};
</script>

<style scoped>
.job-post-confirmation {
  text-align: center;
  padding: 20px;
  width: 551px;
}

.confirmation-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0f7ea;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.icon {
  font-size: 24px;
  margin-right: 10px;
}

.message {
  font-size: 18px;
  color: #0a3828;
}

h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: center;
}

.sub-message {
  color: #979797;
  margin-bottom: 32px;

  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
}

.caregivers-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.caregiver-card {
  width: 245px;
  background-color: transparent;
  border-radius: 8px;
  overflow: hidden;
  gap: 8px;
  text-align: left;
  display: flex;
  margin-top: 26px;
}

.caregiver-photo {
  display: block;
  width: 80px;
  height: 80px;
}

.caregiver-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.caregiver-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.caregiver-rating {
  color: #ffd700;
}

.star {
  font-size: 20px;
  margin-right: 6px;
}

.star.filled {
  color: #ffcc00;
}

.star:not(.filled) {
  color: #ddd;
}

.caregiver-experience {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}
.notification {
  background-color: #e6f7e6;
  color: #2c662d;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 400px;
  margin-top: 40px;
}
.main-container {
  display: flex;
  justify-content: center;
  margin-bottom:200px;
}
.notification-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
