<template>
  <div class="careseeker-container">
    <LoadingSpinner :isLoading="isLoading" />

    <div
      v-if="formData.stepFourData.type_of_care === 'Elderly care'"
      class="careseeker-inner-container"
    >
      <div>
        <h1>Share a few details about who needs care.</h1>
      </div>
      <!-- First service selection -->
      <div class="care-card-container">
        <div
          v-for="service in services"
          :key="service.id"
          class="care-card"
          :class="{
            'care-card-selected': seniorCareData.who_needs_care === service.id,
          }"
          @click="selectService(service.id, 'Elderly care')"
        >
          <div class="label-div">
            <h3>{{ service.main }}</h3>
          </div>
          <input
            type="radio"
            :checked="seniorCareData.who_needs_care === service.id"
          />
        </div>
      </div>

      <div class="form-group has-value">
        <label>Gender</label>
        <div class="gender-group">
          <button
            type="button"
            @click="setGender('M', 'Elderly care')"
            :class="{ selected: seniorCareData.selectedGender === 'M' }"
          >
            Male
          </button>
          <button
            type="button"
            @click="setGender('F', 'Elderly care')"
            :class="{ selected: seniorCareData.selectedGender === 'F' }"
          >
            Female
          </button>
        </div>
      </div>
      <div class="specific-time-container">
        <label>Age</label>
        <div class="time-picker">
          <select v-model="seniorCareData.selectedAge">
            <option value="" selected disabled>Select age</option>
            <option v-for="age in ageOptions" :key="age" :value="age">
              {{ age }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group has-value">
        <label>Preferred gender of caregiver</label>
        <div class="gender-group">
          <button
            type="button"
            @click="setPreferredGender('M', 'Elderly care')"
            :class="{ selected: universalCaregiverGender === 'M' }"
          >
            Male
          </button>
          <button
            type="button"
            @click="setPreferredGender('F', 'Elderly care')"
            :class="{ selected: universalCaregiverGender === 'F' }"
          >
            Female
          </button>
        </div>
      </div>
      <BaseButton
        label="Next"
        :disabled="!allInputsFilled"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="handleClick"
      />
    </div>

    <!-- academic care start -->
    <div
      style="width: 400px"
      v-if="formData.stepFourData.type_of_care === 'Tutor'"
    >
      <h2>Which subject do you need tutoring on?</h2>
      <div style="margin-top: 20px">
        <span>Number of children</span>
      </div>

      <div class="button-div">
        <div @click="removeChildAcademic" :disabled="children.length <= 1">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5 0C5.876 0 0.5 5.376 0.5 12C0.5 18.624 5.876 24 12.5 24C19.124 24 24.5 18.624 24.5 12C24.5 5.376 19.124 0 12.5 0ZM18.5 13.2H6.5V10.8H18.5V13.2Z"
              fill="#979797"
            />
          </svg>
        </div>
        <span>{{ childrenAcademic.length }}</span>
        <div @click="addChildAcademic">
          <svg
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 0C6.876 0 1.5 5.376 1.5 12C1.5 18.624 6.876 24 13.5 24C20.124 24 25.5 18.624 25.5 12C25.5 5.376 20.124 0 13.5 0ZM19.5 13.2H14.7V18H12.3V13.2H7.5V10.8H12.3V6H14.7V10.8H19.5V13.2Z"
              fill="#F59CAB"
            />
          </svg>
        </div>
      </div>
      <div
        v-for="(child, index) in childrenAcademic"
        :key="index"
        class="child-details"
      >
        <div
          @click="toggleChildDetailsAcademic(index)"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid #979797;
            padding-bottom: 8px;
            margin-bottom: 24px;
          "
        >
          <p class="child-text-main">Child {{ index + 1 }}</p>
          <p>
            <span v-if="child.showDetails">
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 6.29508L1.41 7.70508L6 3.12508L10.59 7.70508L12 6.29508L6 0.295075L0 6.29508Z"
                  fill="#979797"
                />
              </svg>
            </span>
            <span v-else
              ><svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z"
                  fill="#979797"
                />
              </svg>
            </span>
          </p>
        </div>
        <div v-if="child.showDetails">
          <div>
            <label :class="[{ 'label-selected': child.educationLevel }]"
              >Select level of education</label
            >
          </div>
          <div
            :class="[
              'custom-multiselect',
              { 'custom-multiselect-selected': child.educationLevel },
            ]"
          >
            <Multiselect
              id="level_of_education"
              label="text"
              v-model="child.educationLevel"
              track-by="value"
              placeholder="Level of Education"
              class="custom-multiselect"
              :options="levelOfEducationOptions"
            >
              <template #caret>
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                    fill="#979797"
                  />
                </svg> </template
            ></Multiselect>
          </div>
          <div style="margin-bottom: 24px">
            <p class="child-text-main" style="margin-bottom: 16px">
              Select Subjects
            </p>
            <div class="subjects-div">
              <button
                v-for="subject in subjects"
                :key="subject"
                @click="toggleSubject(index, subject)"
                :class="{ active: child.selectedSubjects.includes(subject) }"
              >
                {{ subject }}
              </button>
            </div>
          </div>
          <!-- <div style="margin-bottom: 24px">
            <p class="child-text-main" style="margin-bottom: 16px">
              Preferred gender of caregiver
            </p>
            <div class="gender-group">
              <button
                @click="() => setCaregiverGenderAcademic(index, 'M')"
                :class="{
                  selected: childrenAcademic[index].caregiverGender === 'M',
                }"
              >
                Male
              </button>
              <button
                @click="() => setCaregiverGenderAcademic(index, 'F')"
                :class="{
                  selected:
                    childrenAcademic[index].caregiverGender === 'F',
                }"
              >
                Female
              </button>
            </div>
          </div> -->
        </div>
      </div>
        <div class="form-group has-value">
        <label>Preferred gender of caregiver</label>
        <div class="gender-group">
          <button
            type="button"
            @click="setPreferredGender('M', 'Tutor')"
            :class="{ selected: universalCaregiverGender === 'M' }"
          >
            Male
          </button>
          <button
            type="button"
            @click="setPreferredGender('F', 'Tutor')"
            :class="{ selected: universalCaregiverGender === 'F' }"
          >
            Female
          </button>
        </div>
      </div>
      <BaseButton
        label="Next"
        :disabled="isAcademicCareDisabled"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="handleClick"
      />
    </div>
    <!-- academic care end -->
    <!-- child care start -->
    <div
      style="width: 400px"
      v-if="formData.stepFourData.type_of_care === 'Child care'"
    >
      <h2>Who needs care?</h2>
      <div style="margin-top: 20px">
        <span class="child-text" >Number of children</span>
        <div class="button-div">
          <div @click="removeChild" :disabled="children.length <= 1">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 0C5.876 0 0.5 5.376 0.5 12C0.5 18.624 5.876 24 12.5 24C19.124 24 24.5 18.624 24.5 12C24.5 5.376 19.124 0 12.5 0ZM18.5 13.2H6.5V10.8H18.5V13.2Z"
                fill="#979797"
              />
            </svg>
          </div>
          <span>{{ children.length }}</span>
          <div @click="addChild">
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.5 0C6.876 0 1.5 5.376 1.5 12C1.5 18.624 6.876 24 13.5 24C20.124 24 25.5 18.624 25.5 12C25.5 5.376 20.124 0 13.5 0ZM19.5 13.2H14.7V18H12.3V13.2H7.5V10.8H12.3V6H14.7V10.8H19.5V13.2Z"
                fill="#F59CAB"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        v-for="(child, index) in children"
        :key="index"
        class="child-details"
      >
        <div
          @click="toggleChildDetails(index)"
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid #979797;
            padding-bottom: 8px;
            margin-bottom: 24px;
          "
        >
          <p class="child-text-main">Child {{ index + 1 }}</p>
          <p>
            <span v-if="child.showDetails">
              <svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 6.29508L1.41 7.70508L6 3.12508L10.59 7.70508L12 6.29508L6 0.295075L0 6.29508Z"
                  fill="#979797"
                />
              </svg>
            </span>
            <span v-else
              ><svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z"
                  fill="#979797"
                />
              </svg>
            </span>
          </p>
        </div>
        <div v-if="child.showDetails">
          <div class="child-details-div">
            <p class="child-text-main" style="margin-bottom: 8px">
              Select age range
            </p>
            <div class="button-class-div">
              <button
                @click="() => setAgeRange(index, '0-11 months')"
                :class="{
                  selectedAge: children[index].ageRange === '0-11 months',
                }"
              >
                0-11 months
              </button>
              <button
                @click="() => setAgeRange(index, '1-3 yrs')"
                :class="{ selectedAge: children[index].ageRange === '1-3 yrs' }"
              >
                1-3 yrs
              </button>
              <button
                @click="() => setAgeRange(index, '4-5 yrs')"
                :class="{ selectedAge: children[index].ageRange === '4-5 yrs' }"
              >
                4-5 yrs
              </button>
              <button
                @click="() => setAgeRange(index, '6-11 yrs')"
                :class="{
                  selectedAge: children[index].ageRange === '6-11 yrs',
                }"
              >
                6-11 yrs
              </button>
              <button
                @click="() => setAgeRange(index, '12+ yrs')"
                :class="{ selectedAge: children[index].ageRange === '12+ yrs' }"
              >
                12+ yrs
              </button>
            </div>
          </div>
          <!-- <div class="child-details-div-two">
            <p class="child-text-main">Preferred gender of caregiver</p>
            <div class="gender-group">
              <button
                @click="() => setCaregiverGender(index, 'M')"
                :class="{
                  selected: children[index].caregiverGender === 'M',
                }"
              >
                Male
              </button>
              <button
                @click="() => setCaregiverGender(index, 'F')"
                :class="{
                  selected: children[index].caregiverGender === 'F',
                }"
              >
                Female
              </button>
            </div>
          </div> -->
        </div>
      </div>
       <div class="form-group has-value">
        <label>Preferred gender of caregiver</label>
        <div class="gender-group">
          <button
            type="button"
            @click="setPreferredGender('M', 'Child care')"
            :class="{ selected: universalCaregiverGender === 'M' }"
          >
            Male
          </button>
          <button
            type="button"
            @click="setPreferredGender('F', 'Child care')"
            :class="{ selected: universalCaregiverGender === 'F' }"
          >
            Female
          </button>
        </div>
      </div>
      <div>
        <BaseButton
          label="Next"
          :disabled="isChildCareDisabled"
          backgroundColor="var(--s-pink-normal)"
          textColor="var(--state-white)"
          disabledBackgroundColor="#ECECEC"
          disabledColor="#ECECEC"
          disabledTextColor="#979797"
          height="60px"
          width="400px"
          @click="handleClick"
        />
      </div>
    </div>
    <!-- child care end -->
    <!-- home care start -->

    <div v-if="formData.stepFourData.type_of_care === 'Housekeeper'">
      <h2 style="margin-bottom:15px">Tell us about your house</h2>
      <div class="home-care-input-group-container">
        <div class="home-care-input-group">
          <label>Number of bedrooms</label>
          <div class="counter">
            <div @click="decrement('bedrooms')">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 0C5.876 0 0.5 5.376 0.5 12C0.5 18.624 5.876 24 12.5 24C19.124 24 24.5 18.624 24.5 12C24.5 5.376 19.124 0 12.5 0ZM18.5 13.2H6.5V10.8H18.5V13.2Z"
                  fill="#979797"
                />
              </svg>
            </div>
            <span>{{ homeCareData.bedrooms }}</span>
            <div @click="increment('bedrooms')">
              <svg
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 0C6.876 0 1.5 5.376 1.5 12C1.5 18.624 6.876 24 13.5 24C20.124 24 25.5 18.624 25.5 12C25.5 5.376 20.124 0 13.5 0ZM19.5 13.2H14.7V18H12.3V13.2H7.5V10.8H12.3V6H14.7V10.8H19.5V13.2Z"
                  fill="#F59CAB"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="home-care-input-group">
          <label>Number of Bathrooms</label>
          <div class="counter">
            <div @click="decrement('bathrooms')">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 0C5.876 0 0.5 5.376 0.5 12C0.5 18.624 5.876 24 12.5 24C19.124 24 24.5 18.624 24.5 12C24.5 5.376 19.124 0 12.5 0ZM18.5 13.2H6.5V10.8H18.5V13.2Z"
                  fill="#979797"
                />
              </svg>
            </div>
            <span>{{ homeCareData.bathrooms }}</span>
            <div @click="increment('bathrooms')">
              <svg
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 0C6.876 0 1.5 5.376 1.5 12C1.5 18.624 6.876 24 13.5 24C20.124 24 25.5 18.624 25.5 12C25.5 5.376 20.124 0 13.5 0ZM19.5 13.2H14.7V18H12.3V13.2H7.5V10.8H12.3V6H14.7V10.8H19.5V13.2Z"
                  fill="#F59CAB"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="home-care-input-group">
          <label>Number of toilets</label>
          <div class="counter">
            <div @click="decrement('toilets')">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5 0C5.876 0 0.5 5.376 0.5 12C0.5 18.624 5.876 24 12.5 24C19.124 24 24.5 18.624 24.5 12C24.5 5.376 19.124 0 12.5 0ZM18.5 13.2H6.5V10.8H18.5V13.2Z"
                  fill="#979797"
                />
              </svg>
            </div>
            <span>{{ homeCareData.toilets }}</span>
            <div @click="increment('toilets')">
              <svg
                width="26"
                height="24"
                viewBox="0 0 26 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 0C6.876 0 1.5 5.376 1.5 12C1.5 18.624 6.876 24 13.5 24C20.124 24 25.5 18.624 25.5 12C25.5 5.376 20.124 0 13.5 0ZM19.5 13.2H14.7V18H12.3V13.2H7.5V10.8H12.3V6H14.7V10.8H19.5V13.2Z"
                  fill="#F59CAB"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="child-details-div-two">
          <p class="child-text-main">Preferred gender of care giver</p>
          <div class="gender-group">
            <button
              :class="{ selected: universalCaregiverGender === 'M' }"
              @click="setPreferredGender('M')"
            >
              Male
            </button>
            <button
              :class="{ selected:universalCaregiverGender === 'F' }"
              @click="setPreferredGender('F')"
            >
              Female
            </button>
          </div>
        </div>
      </div>
      <BaseButton
        label="Next"
        :disabled="isHomeCareDisabled"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="handleClick"
      />
    </div>
    <!-- 
    <div v-else>
  
      <router-link to="/careseeker-onboarding-create-job/step-one"
        >Go back to Step One</router-link
      >
    </div> -->

    <!-- Next button -->
    <!-- <div>
      <BaseButton
        label="Next"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="handleClick"
      />
    </div> -->
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import { ref, inject, computed, onMounted } from "vue";
import Request from "@/utils/apiclient.js";
import { useRouter } from "vue-router";
import Multiselect from "@vueform/multiselect";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import "@vueform/multiselect/themes/default.css";
export default {
  name: "CareSeekerCreateJobStepFour",
  components: {
    BaseButton,
    LoadingSpinner,
    Multiselect,
  },
  setup() {
        const updateFormData = inject("onboardingUpdateCareSeekerformData");
    const universalCaregiverGender = ref("")
    const router = useRouter();
    const formData = inject("onboardingCareSeekerformData");
    // const updateFormData = inject("onboardingUpdateCareSeekerformData");
  const localData = ref({
      ...formData.value.stepSixData,
    });
    if (
      formData.value.stepFourData.type_of_care == "" ||
      !formData.value.stepFourData.type_of_care
    ) {
      router.push("/careseeker-onboarding/job-post/step-1");
    }
    const isLoading = ref(false);

    const fetchCareCategory = () => {
      isLoading.value = true;
      const request = new Request("/category/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("Data:", res);
        }
      });
    };
    onMounted(() => {
      fetchCareCategory();
    });

    const ageOptions = ["50 - 59", "60 - 69", "70 - 79", "80 - 89", ">90"];

    const seniorCareData = ref({
      selectedAge: "",
      selectedGender: "",
     
      who_needs_care: "",
    });

    const services = [
      { id: "my_parent", main: "My Parent" },
      { id: "my_spouse", main: "My Spouse" },
      { id: "my_grandparent", main: "My Grandparent" },
      { id: "my_friend", main: "My Friend / Extended Relative" },
      { id: "myself", main: "Myself" },
    ];

    const setGender = (gender, type) => {
      if (type === "Elderly care") {
        seniorCareData.value.selectedGender = gender;
      }
   
    };

    const setPreferredGender = (gender) => {
      
         universalCaregiverGender.value = gender
    };

    const selectService = (serviceId, type) => {
      if (type === "Elderly care") {
        seniorCareData.value.who_needs_care = serviceId;
      }
    };

    const toggleService = (serviceId, type) => {
      if (type === "Housekeeper") {
        const index = homeCareData.value.services.indexOf(serviceId);
        if (index === -1) {
          homeCareData.value.services.push(serviceId);
        } else {
          homeCareData.value.services.splice(index, 1);
        }
      }
    };

  

    const allInputsFilled = computed(() => {
      // if (formData.value.stepFourData.type_of_care === "Senior care") {
      return (
        seniorCareData.value.selectedAge !== "" &&
        seniorCareData.value.selectedGender !== "" &&
        universalCaregiverGender.value !== "" &&
        seniorCareData.value.who_needs_care !== ""
      );
      // } else if (formData.value.stepFourData.type_of_care === "Home care") {
      //   return homeCareData.value.services.length > 0;
      // }
      // return false;
    });
    // home care start

    const homeCareData = ref({
      bedrooms: 1,
      bathrooms: 1,
      toilets: 1,
  
    });

    const increment = (field) => {
      homeCareData.value[field]++;
    };

    const decrement = (field) => {
      if (homeCareData.value[field] > 1) {
        homeCareData.value[field]--;
      }
    };

   

    const handleNext = () => {
      // Handle the next button click

      console.log("Next clicked", homeCareData.value);
      // localData.value.specific_care_requirements = homeCareData.value;
    };

    const isHomeCareDisabled = computed(() => {
      return !universalCaregiverGender.value
    });

    // home care end

    // child care start
    const children = ref([
      {
        showDetails: true,
        ageRange: "",
       
      },
    ]);

    const addChild = () => {
      children.value.push({
        showDetails: false,
        ageRange: "",
     
      });
    };

    const removeChild = () => {
      if (children.value.length > 1) {
        children.value.pop();
      }
    };

    const toggleChildDetails = (index) => {
      children.value[index].showDetails = !children.value[index].showDetails;
    };

    const setAgeRange = (index, range) => {
      children.value[index].ageRange = range;
    };

    const setCaregiverGender = (index, gender) => {
      children.value[index].caregiverGender = gender;
    };

    const nextStep = () => {
      // Implement the logic for the next step
      console.log("Proceeding to the next step", children.value);
    };

    const isChildCareDisabled = computed(() => {
      return children.value.some(
        (child) => !child.ageRange || !universalCaregiverGender.value
      );
    });

    // child care end
    // academic care start
    const subjects = [
      "Mathematics",
      "English",
      "Science",
      "Arts",
      "Music and Drama",
      "Special Education",
      "Others",
    ];
    const levelOfEducationOptions = ref([
      {
        value: "Pre-school",
        text: "Pre-school",
      },
      {
        value: "Primary school",
        text: "Primary school",
      },
      {
        value: "Secondary School",
        text: "Secondary School",
      },
      {
        value: "Higher Instituition",
        text: "Higher Instituition",
      },
    ]);

    const childrenAcademic = ref([
      {
        showDetails: true,
        educationLevel: "",
        selectedSubjects: [],
       
      },
    ]);

    const addChildAcademic = () => {
      childrenAcademic.value.push({
        showDetails: false,
        educationLevel: "",
        selectedSubjects: [],
    
      });
    };

    const removeChildAcademic = () => {
      if (childrenAcademic.value.length > 1) {
        childrenAcademic.value.pop();
      }
    };

    const toggleChildDetailsAcademic = (index) => {
      childrenAcademic.value[index].showDetails =
        !childrenAcademic.value[index].showDetails;
    };

    const toggleSubject = (index, subject) => {
      const subjects = childrenAcademic.value[index].selectedSubjects;
      const subjectIndex = subjects.indexOf(subject);
      if (subjectIndex > -1) {
        subjects.splice(subjectIndex, 1);
      } else {
        subjects.push(subject);
      }
    };

    const setCaregiverGenderAcademic = (index, gender) => {
      childrenAcademic.value[index].caregiverGender = gender;
    };

    const isAcademicCareDisabled = computed(() => {
      return childrenAcademic.value.some(
        (child) =>
          !child.selectedSubjects.length ||
          !universalCaregiverGender.value ||
          !child.educationLevel
      );
    });
    // Check if type_of_care is neither 'senior care' nor 'home care' and redirect
    // if (
    //   formData.value.stepFourData.type_of_care !== "Senior care" &&
    //   formData.value.stepFourData.type_of_care !== "Home care"
    // ) {
    //   router.push("/careseeker-onboarding/job-post/step-1");
    // }
  const handleClick = () => {
    console.log("universal", universalCaregiverGender)
    localData.value.care_giver_gender = universalCaregiverGender.value;
     console.log("universal local", localData.value)
      if (formData.value.stepFourData.type_of_care === "Elderly care") {
       // updateFormData("stepSixData", seniorCareData.value);
       localData.value.specific_care_data["Elderly care"] = seniorCareData.value;
      } else if (formData.value.stepFourData.type_of_care === "Housekeeper") {
       // updateFormData("stepSixData", homeCareData.value);
         localData.value.specific_care_data["Housekeeper"] = homeCareData.value;
      } else if (formData.value.stepFourData.type_of_care === "Tutor") {
       // updateFormData("stepSixData", childrenAcademic.value);
         localData.value.specific_care_data["Tutor"] = childrenAcademic.value;
      }else if (formData.value.stepFourData.type_of_care === "Child care"){
        //  updateFormData("stepSixData", children.value);
          localData.value.specific_care_data["Child care"] =  children.value;
      }
        updateFormData("stepSixData", localData.value);
      console.log("form three data here", formData);
      router.push("/careseeker-onboarding/job-post/step-4");
    };
    return {
      services,
      seniorCareData,
      setGender,
      setPreferredGender,
      selectService,
      toggleService,
      handleClick,
      allInputsFilled,
      ageOptions,
      formData,
      isLoading,
      // child care start
      children,
      addChild,
      removeChild,
      toggleChildDetails,
      setAgeRange,
      setCaregiverGender,
      nextStep,
      isChildCareDisabled,
      //   child care end
      //   academic care start
      childrenAcademic,
      addChildAcademic,
      removeChildAcademic,
      toggleChildDetailsAcademic,
      toggleSubject,
      subjects,
      setCaregiverGenderAcademic,
      isAcademicCareDisabled,
      levelOfEducationOptions,
      // academic care end
      // home care data
      homeCareData,
      increment,
      decrement,
      handleNext,
      isHomeCareDisabled,
      // home care data end
      universalCaregiverGender
    };
  },
};
</script>

<style scoped>
h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  margin: 0px;
  text-align: center;
}

.careseeker-container {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.careseeker-inner-container {
  width: 400px;
  height: 100%;
}
.care-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
}

.care-card-selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal-active);
}

.care-card-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 42px;
  margin-bottom: 24px;
}

.care-card p {
  color: #0d4a34;
}

.care-card div:first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}

.care-card input[type="radio"] {
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #979797;
  background-color: transparent;
  display: grid;
  place-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card input[type="radio"]::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: scale(0);
  transition: transform 0.3s;
  background-color: var(--s-green-normal-active);
  border-radius: 50%;
}

.care-card input[type="radio"]:checked {
  border-color: var(--s-green-normal-active);
}

.care-card input[type="radio"]:checked::before {
  transform: scale(1);
}

h3 {
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.label-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.label-div p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
}

.gender-group {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}

.gender-group button {
  flex: 1;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  height: 48px;
}

.gender-group button.selected {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
.form-group {
  width: 100%;
  margin-bottom: 24px;
}
.specific-time-container {
  margin-bottom: 24px;
  margin-top: 24px;
}
.complete-bio-section-div {
  display: flex;
  flex-direction: column;
}
.specific-time-container {
  margin-bottom: 20px;
  margin-top: 29px;
}

.specific-time-container {
  margin-bottom: 20px;
  margin-top: 29px;
}

.time-picker {
  display: flex;
  align-items: center;
  gap: 29px;
  margin-top: 10px;
  position: relative;
}

.time-picker select {
  padding: 10px 24px 10px 24px;
  min-width: 160px;
  border-radius: 12px;
  border: 1px solid #979797;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z" fill="black"/></svg>')
    no-repeat right 26px center;
  background-size: 10px 6px;
}

.time-picker select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer */
}
label {
  margin-bottom: 8px;
}

.care-card input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #979797;
  background-color: transparent;
  display: grid;
  place-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card input[type="checkbox"]::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: scale(0);
  transition: transform 0.3s;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: var(--s-green-normal-active);
}

.care-card input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

.care-card input[type="checkbox"]:checked::before {
  transform: scale(1);
}
/* start home care */
.home-care-input-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.counter {
  display: flex;
  align-items: center;
  gap: 24px;
}
.home-care-input-group-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
/* end home care */
.child-details-div {
  margin-bottom: 16px;
}
.child-details-div-two {
  margin-bottom: 24px;
}
/* child care */
.child-details {
  margin-top: 10px;
}
.button-div {
  display: flex;
  gap: 47px;
  align-items: center;
  margin-top: 16px;
  justify-content: center;
}
.child-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #0a3828;
}
.child-text-main {
  font-size: 16px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
.button-class-div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.button-class-div button {
  width: 126.67px;
  height: 44px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}

.button-class-div button.selectedAge {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
.gender-group {
  display: flex;
  gap: 10px;
  margin-top: 8px;
}

.gender-group button {
  flex: 1;

  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  height: 48px;
}

.gender-group button.selected {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
.child-details-div {
  margin-bottom: 16px;
}
.child-details-div-two {
  margin-bottom: 24px;
}
/* end child care */
/* start academic care */
.subjects-div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.subjects-div button {
  min-width: 126.67px;
  height: 44px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  background-color: transparent;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}
.subjects-div button.active {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
/* end academic care */
.custom-multiselect {
  position: relative;
  width: 100%;
}
.multiselect-option.is-selected {
  background: var(--s-green-normal-active);
  color: #000;
}
.custom-multiselect .multiselect {
  width: 100%;
  padding: 10px;
  height: 60px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}

.custom-multiselect .multiselect__content {
  background-color: transparent; /* White background for options */
}
.multiselect {
  background: transparent;
}

.label-selected {
  color: var(--s-green-normal-active); /* Change to desired color */
  /* font-weight: bold; */
}
.custom-multiselect-selected .multiselect {
  border: 1px solid var(--s-green-normal-active); /* Change to desired color when a value is selected */
}
label {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #979797;
  margin-bottom: 8px;
}
.label-selected {
  color: var(--s-green-normal);
}
</style>
