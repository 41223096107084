<template>
  <div class="login-layout-container">
    <ToastManagerVue ref="toastManager" />
    <LoadingSpinner :isLoading="isLoading" />
    <div class="main">
      <div class="form-container">
        <h2>Fill in your Guarantor Details</h2>
        <form @submit.prevent="submitForm">
          <p class="header-text">Guarantor's details</p>
          <div class="name-class">
            <div class="form-group">
              <label :class="{ active: form.firstName }">First Name</label>
              <input
                type="text"
                v-model="form.firstName"
                :class="{ filled: form.firstName }"
                placeholder="First Name"
              />
            </div>
            <div class="form-group">
              <label :class="{ active: form.lastName }">Last Name</label>
              <input
                type="text"
                v-model="form.lastName"
                :class="{ filled: form.lastName }"
                placeholder="Last Name"
              />
            </div>
          </div>

          <div class="form-group">
            <label :class="{ active: form.emailAddress }">Email Address</label>
            <input
              type="email"
              v-model="form.emailAddress"
              :class="{ filled: form.emailAddress }"
              placeholder="Email address"
            />
            <small v-if="!isValidEmail" class="error">
              Email must be a valid email address
            </small>
          </div>
          <div class="form-group" style="margin-top: 16px">
            <label :class="{ active: form.guarantorPhoneNumber }">
              Phone Number</label
            >
            <input
              type="text"
              v-model="form.guarantorPhoneNumber"
              :class="{ filled: form.guarantorPhoneNumber }"
              placeholder="08030123456"
            />
            <small v-if="!isValidPhoneNumber" class="error">
              Phone number must be exactly 11 digits
            </small>
          </div>
          <div class="form-group">
            <label :class="{ active: form.designation }">Designation</label>
            <input
              type="text"
              v-model="form.designation"
              :class="{ filled: form.designation }"
              placeholder="Designation"
            />
          </div>
          <div class="form-group">
            <label :class="{ active: form.workAddress }">Work Address</label>
            <input
              type="text"
              v-model="form.workAddress"
              :class="{ filled: form.workAddress }"
              placeholder="Address"
            />
          </div>
          <div class="button-div">
            <BaseButton
              label="Next"
              backgroundColor="var(--s-pink-normal)"
              textColor="var(--state-white)"
              height="60px"
              width="100%"
              :disabled="!formValid"
              disabledBackgroundColor="#ECECEC"
              disabledColor="#ECECEC"
              disabledTextColor="#979797"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import BaseButton from "@/components/BaseButton.vue";
import Request from "@/utils/apiclient.js";
import { formatErrorMessages } from "@/utils/errorHandler";
import ToastManagerVue from "../../../../components/ToastAlert/ToastManager.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "CompleteVerification",
  components: {
    BaseButton,
    ToastManagerVue,
    LoadingSpinner,
  },
  setup() {
    const toastManager = ref(null);
    const isLoading = ref(false);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const router = useRouter();

    const form = ref({
      firstName: "",
      lastName: "",
      emailAddress: "",
      designation: "",
      workAddress: "",
      guarantorPhoneNumber: "",
    });

    const isValidPhoneNumber = computed(() => {
      return /^\d{11}$/.test(form.value.guarantorPhoneNumber);
    });

    const isValidEmail = computed(() => {
      return form.value.emailAddress.includes("@");
    });

    const formValid = computed(() => {
      return (
        form.value.firstName &&
        form.value.lastName &&
        isValidEmail.value &&
        form.value.designation &&
        form.value.workAddress &&
        isValidPhoneNumber.value
      );
    });

    const submitForm = () => {
      isLoading.value = true;
      const payload = {
        first_name: form.value.firstName,
        last_name: form.value.lastName,
        email: form.value.emailAddress,
        designation: form.value.designation,
        work_address: form.value.workAddress,
        phone: form.value.guarantorPhoneNumber,
      };
      console.log("payload here", payload);
      const request = new Request(`/guarantor/`);
      request.post(payload, (data, error) => {
        isLoading.value = false;
        if (error) {
          if (error.status === "failure") {
            return showToast(error.message, "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("data", data);
          // showToast("Guarantor Create Successful", "success");
          router.push("/caregiver");
        }
      });
      console.log(form.value);
      // router.push("/caregiver");
    };

    const route = useRoute();
    const steps = ["CompleteVerification"];

    const progressWidth = computed(() => {
      const currentStep = route.name;
      const currentIndex = steps.indexOf(currentStep);
      return ((currentIndex + 1) / steps.length) * 100;
    });

    return {
      progressWidth,
      form,
      formValid,
      submitForm,
      toastManager,
      isLoading,
      isValidPhoneNumber,
      isValidEmail,
    };
  },
};
</script>


<style scoped>
.onboarding-container {
  padding-top: 56px;
}
.care-card {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.care-card-single {
  padding: 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);

  width: 360px;
}
.login-layout-container {
 background-color: #fff8f9;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    width: 100%;
}
.main {
  background-color: #fff8f9;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 200px;
  width: 400px;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.onboarding-header-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 60px;
}

.stepper {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.progress-bar {
  width: 100%;
  background-color: #979797;
  height: 5px;
}

.progress {
  background-color: #17865f;
  height: 5px;
  transition: width 0.3s;
}
.create-account {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.create-account p {
  color: #0a3828;
}
.create-account p:first-child {
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-right: 8px;
}

.form-container {
   width: 400px; 
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

label {
  color: #979797;
  transition: color 0.3s ease;
  margin-bottom: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

label.active {
  color: var(--s-green-normal-active);
}

input,
select {
  padding: 16px 10px;
  width: 100%;
  border: 1px solid #979797;
  background-color: transparent;
  border-radius: 12px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
  height: 60px;
}
input::placeholder {
  color: #979797;

  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
input.filled,
select.filled {
  border-color: var(--s-green-normal-active);
}

button {
  padding: 10px;
  background-color: pink;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}
.name-class {
  display: flex;
  gap: 16px;
  width: 100%;
}
.header-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
  color: #0a3828;
}
h2 {
  margin-bottom: 32px;
  text-align: center;
  margin-top: 60px;

  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  color: #0a3828;
}
.error {
  color: #979797;
  font-size: 12px;
  margin-top: 5px;
}
@media screen and (max-width: 800px) {
  form{
    max-width: 400px;
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
  
  }
  .care-card-container {
    width: 100%;
  }
  .selects-div {
      width: 100%;
    flex-direction: column;
  }

  .form-group :deep(.custom-select-container) {
      width: 100% !important;
  }
  .form-group :deep(.custom-select-trigger) {
      width: 100% !important;
  }
  .work-preference {
    justify-content: center;
  }

  .button-div {
    /* width: 300px; */
      width: 100%;
    display: flex;
    justify-content: center;
  }
  .days-container-main {
    /* max-width: 300px; */
      width: 100%;
  }
  .complete-bio-container {
    /* width: 300px; */
      width: 100%;
  }
  h2 {
    margin-top: 30px;
    margin-bottom: 10px;
    font-family: Inter;
font-size: 20px;
font-weight: 700;
line-height: 31.2px;
letter-spacing: 0.5px;
text-align: left;

  }
  .form-group {
    width: 100%;
  }
  .form-container {
    justify-content: content;
    align-items: center;
  }
  .name-class {
    flex-direction: column;
    gap: 0px;
  }
}
</style>
