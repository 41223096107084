<template>
  <div class="final-details-container">
    <ToastManagerVue ref="toastManager" />
    <div class="final-details">
      <h2>Final details</h2>
      <p>Are there other particular requirements?</p>
      <p class="examples-title">Examples</p>
      
      <ul v-if="formData.stepFourData.type_of_care == 'Elderly care'">
        <li>Do they have underlying conditions?</li>
        <li>What are their hobbies or interest?</li>
        <li>How would they structure their ideal day?</li>
      </ul>
      <textarea v-model="details" placeholder="Share details here"></textarea>

      <BaseButton
      
        :label="isSubmitting ? 'Creating Job...' : 'Post Job'"
        :disabled="isSubmitting || !details"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="postJob"
      />
    </div>
  </div>
</template>

<script>
import { ref, inject, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { useRouter } from "vue-router";
import { formatErrorMessages } from "@/utils/errorHandler";
import Request from "@/utils/apiclient.js";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
export default {
  name: "CareSeekerCreateJobStepSeven",
  components: {
    BaseButton,
     ToastManagerVue,
  },
  setup() {
    const formatLgaValue = (value) => {
  // Replace dash with underscore and convert to lowercase
  let formattedValue = value.replace(/-/g, '_').toLowerCase();
  
  // Replace space with underscore
  formattedValue = formattedValue.replace(/\s+/g, '_');
  
  return formattedValue;
};
     const isSubmitting = ref(false);
     const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const formData = inject("onboardingCareSeekerformData");
    console.log("formmmm", formData)
    const updateFormData = inject("onboardingUpdateCareSeekerformData");
    const router = useRouter();
    if (
      formData.value.stepFourData.type_of_care == "" ||
      !formData.value.stepFourData.type_of_care
    ) {
      router.push("/careseeker-onboarding/job-post/step-1");
    }
    const localData = ref({
      ...formData.value.stepTenData,
    });
    const details = ref("");

    const postJob = () => {
          if (isSubmitting.value) return;
      isSubmitting.value = true;

      // Logic to post the job
      updateFormData("stepTenData", localData.value);
      console.log("Form Data:", formData);
      // router.push("/job-post");
      const payload = {
        days: formData.value.stepEightData.days_of_the_week,
        budget: formData.value.stepNineData.pay_rate,
        state: formData.value.stepSevenData.state.toLowerCase(),
        lga: formatLgaValue(formData.value.stepSevenData.local_government),
        work_preference: formData.value.stepFourData.preference,
        care_giver_gender: formData.value.stepSixData.care_giver_gender,
        category: formData.value.stepFourData.type_of_care_id,
        sub_category: formData.value.stepFiveData.sub_category,
        data: formData.value.stepSixData.specific_care_data,
        other_requirement: details.value,
      };
      
      console.log("full form data 7", payload);
      const rawData = JSON.stringify(payload);
      console.log("full form data", rawData);
      const request = new Request("/job/");

      request.post(rawData, (data, error) => {
           isSubmitting.value = false;
        if (error) {
          console.log("data error", error);
          if (error.status == "failure") {
            return showToast(error.message, "error");
          }
          if (error.detail) {
            return showToast("An error occurred", "error");
          }
          const errorMessages = formatErrorMessages(error);
          console.log("error", errorMessages);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("data success here", data);
           showToast("Job Created Successfully", "error");
          router.push("/job-post");
        }
      });
      // Handle the next button action here
    };

    watch(details, (newdetails) => {
      localData.value.share_details = newdetails;
    });
    return {
      details,
      postJob,
      toastManager,
      isSubmitting,
formData
    };
  },
};
</script>

<style scoped>
.final-details-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
h2 {
  margin-top: 36px;
  margin-bottom: 32px;
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
}
p {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.final-details {
  padding: 20px 0px;
  width: 400px;
  display: flex;
  flex-direction: column;
}

.examples-title {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

ul {
  list-style-position: inside;

  text-align: left;
  padding-left: 5px;
  display: inline-block;
}

textarea {
  width: 100%;
  margin-top: 10px;
  padding: 16px;
  border: 1px solid #979797;
  border-radius: 5px;
  font-size: 16px;
  height: 197px;
  margin-bottom: 59px;
  background-color: transparent;
  box-sizing: border-box;
  font-family: var(--font-family);
}

button {
  display: block;
  width: 100%;
  background-color: #f28b8b;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 15px;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
}

button:disabled {
  background-color: #f28b8b;
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
