<template>
  <div class="landing-container">
    <div class="main">
      <header>
        <nav class="navbar">
          <img class="main-logo" src="@/assets/full_logo.svg" alt="Logo" />
        </nav>
      </header>
      <!-- start main one -->

      <div class="main-one">
        <!-- this is the outer container -->
        <div v-if="isLargeScreen" class="main-one-container">
          <div class="find-care-container">
            <div class="inner-white-container">
              <!-- first div -->
              <div class="inner-white-container-first-div">
                <div>
                  <h1 class="header-text">
                    Support your
                    <span class="typewriter">{{ currentWord }}</span> <br />
                    with Health Insurance Today
                  </h1>
                </div>
                <div style="height: 100px">
                  <div></div>
                </div>
              </div>
              <!-- end first div -->
            </div>
          </div>
        </div>
        <div v-else>
          <div class="main-one-container-small">
            <div class="main-one-container-small-background">
              <div>
                <div>
                  <h1 class="header-text">
                    Support your
                    <span class="typewriter">{{ currentWord }}</span> <br />
                    with Health Insurance Today
                  </h1>
                </div>
              </div>
              <div style="width: 100%; display: flex; justify-content: center">
                <img
                  class="smaller-screen-image"
                  src="@/assets/cshi_landing_image.png"
                  alt="Mother and Child"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- start care section -->
        <div class="start-care-div">
          <div
            style="display: flex; justify-content: center; margin-bottom: 50px"
          >
            <BaseButton
              label="Get Started"
              backgroundColor="var(--s-green-normal)"
              textColor="white"
              width="281px"
              height="60px"
              @click="handleClick"
            />
          </div>
          <div class="type-of-care-div">
            <div class="type-of-care" @click="handleClick">
              <p class="type-of-care-header">Prevent unexpected bills</p>
              <p class="type-of-care-text">
                Health insurance helps families <br />
                avoid surprise medical bills during emergencies, thereby
                eliminating unplanned expenses
              </p>
            </div>
            <div class="type-of-care" @click="handleClick">
              <p class="type-of-care-header">Enjoy Better Service</p>
              <p class="type-of-care-text">
                Healthy caregivers provide better service, enhancing the quality
                of life of those they care for.
              </p>
            </div>
            <div class="type-of-care" @click="handleClick">
              <p class="type-of-care-header">Loyalty Through Support</p>
              <p class="type-of-care-text">
                Supporting caregivers with Health Insurance fosters consistency,
                retention and a win-win for you and them
              </p>
            </div>
            <div class="type-of-care" @click="handleClick">
              <p class="type-of-care-header">Access to over 1,000 hospitals</p>
              <p class="type-of-care-text">
                Your caregiver can access over 1,000 hospitals and clinics
                across Nigeria, ensuring timely, quality healthcare
              </p>
            </div>
          </div>
          <!-- Access a network of caregivers -->
          <!-- <div>
            <div class="access-network-div">
              <p class="access-network">
                Access a network of verified caregivers
              </p>
            </div>
            <div v-if="isLargeScreen" class="caregivers-div">
              <ProfileCard
                v-for="profile in profiles"
                :key="profile.id"
                :profile="profile"
              />
            </div>
            <div v-else>
              <div class="carousel">
                <div
                  class="carousel-item"
                  v-for="(profile, index) in profiles"
                  :key="profile.id"
                  :class="{ activec: currentIndex === index }"
                >
                  <ProfileCard :profile="profile" />
                </div>
              </div>
              <div class="carousel-indicators">
                <span
                  v-for="(profile, index) in profiles"
                  :key="index"
                  :class="{ 'active-dot': currentIndex === index }"
                  class="indicator-dot"
                  @click="goToSlide(index)"
                ></span>
              </div>
            </div>
          </div> -->
        </div>
        <!-- end care section -->
      </div>
      <!-- end main one -->
    </div>
    <main>
      <div class="main-two">
        <div class="find-job-care-provider">
          <div class="are-you">Get Started in 3 easy steps</div>
          <div style="font-size: 22px">
            1. Create your Profile <br />
            2. Provide your domestic staff’s details<br />
            3. Use the dedicated account number to pay your staff’s salary (plus 5%
            facilitation fee)
            <br />
            4. Your staff’s salary will be sent to his/her regular bank account
            while 5% will be retained by Succour as management fee
          </div>
          <BaseButton
            label="Get Started"
            backgroundColor="var(--s-green-normal)"
            textColor="white"
            width="281px"
            height="60px"
            @click="handleClick"
          />
        </div>
      </div>
    </main>
    <footer>
      <div class="footer">
        <div class="footer-content-container">
          <div v-if="isLargeScreen" class="logoandsocialmedia">
            <img
              src="@/assets/white_logo.svg"
              style="width: 167.32px; height: 60.05px; margin-bottom: 16px"
              alt="Logo"
            />
            <div style="margin-bottom: 61px">
              <p class="white-text">
                Succour helps families and individuals connect with verified
                caregivers faster
              </p>
            </div>
            <div class="social-media-links">
              <a
                href="https://www.linkedin.com/company/succourcare/?viewAsMember=true"
                target="_blank"
                class="clickable-div"
              >
                <img src="../../assets/linkedin.svg" />
              </a>
              <a
                href="https://www.facebook.com/succour.care/"
                target="_blank"
                class="clickable-div"
              >
                <img src="../../assets/facebook.svg" />
              </a>
              <a
                href="https://www.instagram.com/succour.care"
                target="_blank"
                class="clickable-div"
              >
                <img src="../../assets/instagram.svg" />
              </a>
              <a
                href="https://x.com/succourcare"
                target="_blank"
                class="clickable-div"
              >
                <img src="../../assets/x.svg" />
              </a>
            </div>
          </div>
          <div v-if="!isLargeScreen" class="social-media-links">
            <a
              href="https://www.linkedin.com/company/succourcare/?viewAsMember=true"
              target="_blank"
              class="clickable-div"
            >
              <img src="../../assets/linkedin.svg" />
            </a>
            <a
              href="https://www.facebook.com/succour.care/"
              target="_blank"
              class="clickable-div"
            >
              <img src="../../assets/facebook.svg" />
            </a>
            <a
              href="https://www.instagram.com/succour.care"
              target="_blank"
              class="clickable-div"
            >
              <img src="../../assets/instagram.svg" />
            </a>
            <a
              href="https://x.com/succourcare"
              target="_blank"
              class="clickable-div"
            >
              <img src="../../assets/x.svg" />
            </a>
          </div>
          <div v-if="!isLargeScreen">
            <p class="white-text">
              Succour helps families and individuals connect with verified
              caregivers faster
            </p>
          </div>
          <div
            class="footer-section"
            v-for="(section, index) in footerSections"
            :key="index"
          >
            <div class="footer-header" @click="toggleSection(index)">
              <p class="footer-header-text">{{ section.title }}</p>
              <img
                v-if="!isLargeScreen"
                src="@/assets/dropdown_icon_white.svg"
                alt=""
                :class="{ open: isOpen(index) }"
              />
            </div>
            <div v-if="isLargeScreen || isOpen(index)">
              <router-link
                style="margin-bottom: 8px"
                v-for="(link, i) in section.links"
                :key="i"
                :to="link.url"
                >{{ link.text }}</router-link
              >
            </div>
          </div>
        </div>

        <div v-if="!isLargeScreen" class="logoandsocialmedia">
          <img
            src="@/assets/white_logo.svg"
            style="width: 167.32px; height: 60.05px; margin-bottom: 16px"
            alt="Logo"
          />
          <!-- <div style="margin-bottom: 61px">
              <p class="white-text">
                Succour helps families and individuals connect with trusted,
                verified caregivers
              </p>
            </div> -->
        </div>
        <div class="ft">
          Copyright © {{ currentYear }} Succour Company S.L. All rights
          reserved.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import BaseButton from "../../components/BaseButton.vue"; // Adjust the path as needed
// import ProfileCard from "../../components/ProfileCard.vue";
import statesData from "@/data/states.js";
import { useRouter } from "vue-router";

export default {
  name: "LandingPageCSHI",
  components: {
    BaseButton,
    // ProfileCard,
  },
  setup() {
    const router = useRouter();
    const profiles = [
      {
        id: 1,
        name: "Taiwo A.",
        location: "Lagos, Nigeria",
        rating: 4,
        image: "../assets/woman.png", // Replace with actual image URL
        description:
          "Lorem ipsum dolor sit amet consectetur. Ultrices euismod aliquet posuere enim eu mauris tincidunt id.",
        reviewer: "Onyinyechi G.",
      },
      {
        id: 2,
        name: "Chinwe B.",
        location: "Abuja, Nigeria",
        rating: 5,
        image: "../assets/woman.png", // Replace with actual image URL
        description:
          "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.",
        reviewer: "Kelechi M.",
      },
      {
        id: 3,
        name: "Emeka C.",
        location: "Enugu, Nigeria",
        rating: 3,
        image: "../assets/woman.png", // Replace with actual image URL
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        reviewer: "Amaka O.",
      },
    ];

    const currentTab = ref("find-care");
    const setTab = (tab) => {
      currentTab.value = tab;
      stateQuery.value = "";
      cityQuery.value = "";
    };

    // SearchBox setup
    const stateQuery = ref("");
    const cityQuery = ref("");
    const showStateDropdown = ref(false);
    const showCityDropdown = ref(false);

    const stateOptions = statesData.states.map((state) => ({
      value: state.state,
      text: state.state,
    }));

    const filteredList = computed(() => {
      const query = stateQuery.value;
      return stateOptions.filter((item) =>
        item.value.toLowerCase().includes(query.toLowerCase())
      );
    });
    const lgaOptions = ref([]);
    const filteredListTwo = computed(() => {
      const query = cityQuery.value;
      return lgaOptions.value.filter((item) =>
        item.value.toLowerCase().includes(query.toLowerCase())
      );
    });

    function onInput(type) {
      if (type === "state") {
        if (!stateQuery.value) {
          showStateDropdown.value = false;
          lgaOptions.value = [];
        } else {
          showStateDropdown.value = true;
          showCityDropdown.value = false;
        }
      } else if (type === "city") {
        if (!cityQuery.value) {
          showCityDropdown.value = false;
        } else {
          showCityDropdown.value = true;
          showStateDropdown.value = false;
        }
      }
    }

    const selectItem = (item) => {
      stateQuery.value = item;
      showStateDropdown.value = false;
      updateLgaOptions(item);
    };

    const selectItemTwo = (item) => {
      cityQuery.value = item;
      showCityDropdown.value = false;
    };

    const updateLgaOptions = (state) => {
      const selectedStateData = statesData.states.find(
        (s) => s.state === state
      );
      lgaOptions.value = selectedStateData
        ? selectedStateData.lgas.map((lga) => ({ value: lga, text: lga }))
        : [];
    };

    // const handleClick = () => {
    //   console.log("BaseButton clicked");
    //   localStorage.setItem("isCSInsurance", "true");
    //   router.push("/onboarding");
    // };
    const handleClick = () => {
      console.log("BaseButton clicked");
      sessionStorage.setItem("isCSInsurance", "true");

      // Use a slight delay to ensure localStorage is set before the route change
      setTimeout(() => {
        router.push("/onboarding");
      }, 1000); // 100 milliseconds delay
    };

    const isLargeScreen = ref(window.innerWidth > 1200);

    const handleResize = () => {
      isLargeScreen.value = window.innerWidth > 1200;
      console.log("inner", window.innerWidth);
    };

    const currentIndex = ref(0);
    let interval = null;

    const startCarousel = () => {
      interval = setInterval(() => {
        nextSlide();
      }, 3000); // Change slide every 3 seconds
    };

    const nextSlide = () => {
      currentIndex.value = (currentIndex.value + 1) % profiles.length;
    };

    const goToSlide = (index) => {
      currentIndex.value = index;
      clearInterval(interval);
      startCarousel();
    };

    onMounted(() => {
      window.addEventListener("resize", handleResize);
      startCarousel();
      //  window.location.href = "https://waitlist.succour.care";
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleResize);
      clearInterval(interval);
    });

    const openSection = ref(null);

    const footerSections = [
      {
        title: "About",
        links: [
          { text: "Company", url: "/about-us" },
          { text: "FAQ", url: "/faq" },
          { text: "Terms of use", url: "/terms-of-use" },
          { text: "Privacy policy", url: "/privacy-policy" },
        ],
      },
      // {
      //   title: "Get help",
      //   links: [
      //     { text: "Safety", url: "/" },
      //     { text: "Articles and guidelines", url: "/" },
      //     { text: "Help center", url: "/" },
      //   ],
      // },
      {
        title: "Services",
        links: [
          { text: "Child care", url: "/onboarding" },
          { text: "Elderly care", url: "/onboarding" },
          { text: "Housekeeper", url: "/onboarding" },
          { text: "Tutor", url: "/onboarding" },
        ],
      },
    ];

    const toggleSection = (index) => {
      openSection.value = openSection.value === index ? null : index;
    };

    const isOpen = (index) => openSection.value === index;
    const currentYear = computed(() => new Date().getFullYear());
    // typewritereffect start
    const words = ["Housekeeper", "Domestic staff", "Nanny", "Caregiver"];
    const currentWord = ref(""); // This will hold the word being typed
    let wordIndex = 0;
    let charIndex = 0;
    let typing = true;
    let intervalNew = null;

    const typeWriterEffect = () => {
      if (typing) {
        // Typing the word
        if (charIndex < words[wordIndex].length) {
          currentWord.value += words[wordIndex][charIndex];
          charIndex++;
        } else {
          typing = false;
          setTimeout(() => {
            typing = true;
            charIndex = 0;
            wordIndex = (wordIndex + 1) % words.length;
            currentWord.value = "";
          }, 2000); // Wait 2 seconds before switching to the next word
        }
      }
    };

    onMounted(() => {
      intervalNew = setInterval(typeWriterEffect, 150); // Typing speed
    });

    onBeforeUnmount(() => {
      clearInterval(intervalNew);
    });
    // typewritereffect end
    return {
      currentWord,
      currentTab,
      setTab,
      handleClick,
      profiles,
      stateQuery,
      cityQuery,
      showStateDropdown,
      showCityDropdown,
      filteredList,
      filteredListTwo,
      onInput,
      selectItem,
      selectItemTwo,
      handleResize,
      isLargeScreen,
      currentIndex,
      goToSlide,

      footerSections,
      toggleSection,
      isOpen,
      currentYear,
    };
  },
};
</script>

<style scoped>
.typewriter {
  display: inline-block;
  font-weight: bold;
  color: var(--s-pink-normal);
  border-right: 2px solid;
  animation: blink 0.75s step-end infinite;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel-item.activec {
  opacity: 1;
}

.carousel-indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.indicator-dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: transparent;
  border: 1px solid #979797;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.active-dot {
  background-color: #d9d9d9;
}

.smaller-screen-image {
  max-width: 90%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.main-one-small {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main-one-container-small {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-three-cont {
  display: flex;
  gap: 24px;
  flex-direction: column;
}
.landing-container {
  background-color: #fffbfc;
}
.main {
  background-image: url("@/assets/Layer2.png");
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 80px;
  background-color: #fffbfc;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
}
.links {
  display: flex;
  gap: 30px;
}

.normal-links {
  color: var(--s-green-normal-active);
  text-decoration: none;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.links router-link:hover {
  background-color: #007bff;
  color: #fff;
}

.links router-link-active {
  font-weight: bold;
}
.join-now {
  background-color: var(--s-pink-normal);
  width: 150px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--state-white);
  text-decoration: none;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
header {
  background-color: #fff8f9;
}
.main-one {
  padding: 0px 48px 0px 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-one-container {
  position: relative; /* Ensure the container is positioned relatively */
  margin-top: 24px;
  max-width: 1300px;
  width: 100%;
}

.main-one-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-image: url("@/assets/cshi_landing_image.png"); /* Replace with the actual path to your image */
  background-position: calc(100% - 70px) 0; /* Adjust the horizontal position by subtracting pixels from 100% */
  background-repeat: no-repeat;
  z-index: 1000; /* Ensure the background image is on top */
  pointer-events: none; /* Allow clicks to pass through the pseudo-element */
}

.main-one-container > * {
  position: relative; /* Ensure the content is above the pseudo-element */
  z-index: 2; /* Ensure the content is above the pseudo-element */
}

.find-care-container {
  background-color: var(--state-white);
  display: flex;
  justify-content: center;
  margin-top: 31px;
  padding-bottom: 51px;
  border-radius: 12px;
  width: 100%;
}

.header-text {
  color: var(--s-green-normal);
  font-size: 40px;
  font-weight: 700;
  line-height: 70.32px;
  letter-spacing: 0.9634920597076416px;
}
.inner-white-container {
  background-color: var(--state-white);
  padding: 0px 0px 0px 115px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  max-width: 1322px;
  width: 100%;
}
.tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 42px;
}
.tabs p {
  color: var(--s-green-dark);
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  font-weight: var(--font-weight-regular);
}
.active {
  border-bottom: 2px solid var(--s-pink-normal);
}
.active p {
  color: var(--s-pink-normal);
  font-weight: var(--font-weight-bold);
}
.single-tab {
  padding: 0px 20.5px 4px 20.5px;
  cursor: pointer;
}
.search-box {
  border: 1px solid #979797;
  border-radius: 12px;
  padding: 16px 24px 16px 2px;
  max-width: 340px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 8px;
  position: relative;
}
.search-box input {
  border: none;
  color: #979797;
  height: 28px;
  max-width: 123px;
  padding-left: 22px;
  box-sizing: border-box;

  outline: none;
}
.separator {
  margin-right: 8px;
  height: 28px;
  border-right: 1px solid #000000;
}
.search-box-button-div {
  display: flex;
  max-width: 460px;
}
.inner-white-container-first-div {
  padding-top: 57px;
}
.job-search {
  color: var(--s-green-darker);
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.job-search span {
  color: var(--s-pink-normal);
}
.job-search a {
  text-decoration: none;
  color: var(--s-pink-normal);
}
.start-care-div {
  padding: 80px 150px 0px 150px;
}
/* .type-of-care-div {
  display: flex;
  gap: 48px;
  justify-content: center;
  width: 100%;
  margin-bottom: 80px;
} */
.type-of-care-div {
  display: flex;
  gap: 48px;
  justify-content: center;
  width: 100%;
  margin-bottom: 80px;
  flex-wrap: wrap; /* Add this to allow wrapping */
}

.type-of-care {
  display: flex;
  box-shadow: 0px 4px 4px 0px #0000001a;
  background-color: var(--s-pink-light-hover);
  border-radius: 12px;
  width: 244.4px;
  flex-direction: column;

  align-items: center;
  gap: 10px;
  padding: 26px 20px;
  cursor: pointer;
}
.type-of-care img {
  height: 30px;
  width: 30px;
}
.type-of-care-header {
  font-size: 15px;
  font-weight: var(--font-weight-bold);
  line-height: var(--font-body-normal-regular-line-height);
  letter-spacing: 0.5px;
  height: 55px;
  text-align: center;
}
.type-of-care-text {
  font-size: var(--font-label-regular-size);
  font-weight: 400;
  line-height: var(--font-body-small-regular-line-height);
  letter-spacing: 0.4px;
  text-align: center;
}
.access-network-div {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  gap: 32.6px;
}
.access-network {
  font-size: 36px;
  font-weight: var(--font-weight-bold);
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: center;
}
.caregivers-div {
  display: flex;
  justify-content: center;
  gap: 32.6px;
}
.caregivers {
  display: flex;
  flex-direction: column;
}
.find-job-care-provider {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--s-pink-normal);
  padding-top: 108.5px;
  padding-bottom: 108.5px;
  gap: 48px;
  margin-bottom: 100px;
}
.find-job-care-provider {
  background-image: url("@/assets/Layer1.png");
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  color: var(--state-white);
  font-size: 36px;
  font-weight: var(--font-weight-bold);
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: center;
}
.main-three {
  background-image: url("@/assets/waitlist-image.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: flex-end;
  padding-top: 49px;
  padding-bottom: 49px;
  padding-right: 91px;
}
.get-started-card {
  display: flex;
  flex-direction: column;
  background-color: var(--state-white);
  border-radius: 12px;
  padding: 16px 0px 16px 16px;
  gap: 8px;
}
.get-started-card-image-div {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  width: 546px;
}
.get-started-card-image-div p {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.get-started-card-image-div img {
  height: 40px;
  width: 40px;
}
.get-started-card-extra-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.button-get-started {
  background-color: var(--s-pink-normal);
  color: var(--state-white);
  width: 546px;
  padding: 16px 24px 16px 24px;
  gap: 10px;
  border-radius: 40px;

  height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.button-get-started div {
  outline: none;
  color: var(--state-white);
  text-decoration: none;
}
.main-three-header p {
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0.5px;
  color: var(--s-green-darker);
}
.main-three-header {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.footer {
  padding: 52px 134px 59px 157px;
  background-color: var(--s-green-normal);
}
.footer-content-container {
  display: flex;
  justify-content: space-between;
}
.social-media-links {
  display: flex;
  justify-content: flex-start;
  gap: 20.58px;
}
.social-media-links a {
  outline: none;
}
.logoandsocialmedia {
  display: flex;
  flex-direction: column;
}
.footer-content-container > div {
  max-width: 220px;
}
.white-text {
  color: #ffffff;
  font-size: 15.22px;
  font-weight: 400;
  line-height: 25.87px;
  letter-spacing: 0.5434070825576782px;
  text-align: justified;
}
.footer-header-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  color: var(--state-white);
}
.footer-section > div:first-child {
  margin-bottom: 16px;
}
.footer-section > div:not(:first-child) {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
}
.footer-section > div:not(:first-child) a {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: var(--state-white);
  text-decoration: none;
}

.statedropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 5px;
  background-color: white;
  z-index: 10000;
  max-height: 200px;
  overflow-y: auto;
}
.statedropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.statedropdown li {
  padding: 10px;
  cursor: pointer;
}
.statedropdown li:hover {
  background-color: #f0f0f0;
}
.citydropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 5px;
  background-color: white;
  z-index: 10000;
  max-height: 200px;
  overflow-y: auto;
}
.citydropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.citydropdown li {
  padding: 10px;
  cursor: pointer;
}
.citydropdown li:hover {
  background-color: #f0f0f0;
}
.icon img {
  width: 16px;
  height: 24px;
}
.care-by-location {
  margin-top: 26px;
  margin-bottom: 10px;
  padding-left: 24px;
}
.ft {
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.9px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #ffffff;
  margin-top: 36px;
}
/* Media query for screen widths less than 500px */
@media screen and (max-width: 800px) {
  /* .footer-content-container {
    flex-direction: column-reverse;
  } */
  .by-location {
    font-size: 12px;
    font-weight: 400;
    line-height: 20.4px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  .ft {
    margin-top: 16px;
  }
  .logoandsocialmedia {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-content-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .social-media-links {
    justify-content: center;
  }
  .footer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer-content-container > div {
    max-width: 320px;
  }
  .footer-section {
    width: 100%;
  }
  .small {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .main-three-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-three-header p {
    font-size: 24px;
    font-weight: 700;
    white-space: wrap;
    line-height: 32px;
    letter-spacing: 0.5px;
    max-width: 204px;
    text-align: center;
    color: var(--s-green-darker);
  }
  .type-of-care {
    width: 173px;
  }
  .main {
    margin-bottom: 38px;
  }
  .main-one-container-small-background {
    background-color: #fef0f2;
    padding: 0px 20px;
  }
  .main-one-container-small {
    padding: 8px 20px;
  }
  .smaller-screen-image {
    margin: 0px;
  }
  .header-text {
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    line-height: 38.73px;
    letter-spacing: 0.5634920597076416px;
    width: 100%;
    white-space: wrap;
    margin-top: 24px;
  }
  .are-you {
    font-size: 24px;
  }
  .care-by-location {
    padding-left: 0px;
  }

  .landing-container {
    margin: 0;
    padding: 0;
  }
  .inner-white-container-first-div {
    width: 100%;
  }
  .main-logo {
    width: 100px !important;
    height: 50px !important;
  }
  header {
    box-shadow: 0px 4px 1px 0px #0000001a;
  }
  .navbar {
    padding: 5px 10px 5px 10px !important;
    box-sizing: border-box;
    flex-direction: row;
  }
  .main-one {
    width: 100%;
    padding: 0px !important;
  }
  .main-one-container {
    position: relative;
    margin-top: 24px;
    width: 100%; /* Use percentage for better responsiveness */
  }
  .footer {
    padding: 16px;
    background-color: var(--s-green-normal);
    width: 100%;
  }

  .start-care-div {
    padding: 20px !important;
    width: 100%;
  }

  .carousel {
    display: flex;
    transition: transform 0.5s ease-in-out;
    overflow: hidden; /* Hide non-active items */
    width: 100%; /* Ensure the carousel doesn't exceed the container width */
    position: relative; /* Needed for positioning the items */
  }

  .carousel-item {
    min-width: 100%;
    box-sizing: border-box;
    transition: opacity 0.5s ease-in-out;
    opacity: 0; /* Hide non-active items */
    position: absolute; /* Stack items on top of each other */
    top: 0;
    left: 0;
  }

  .carousel-item.activec {
    opacity: 1; /* Show the active item */
    position: relative; /* Ensure the active item takes the place of the previous item */
  }

  .type-of-care {
    flex-basis: 160px; /* Set the width to 172px */
    width: 160px; /* Ensure the width is exactly 172px */
  }

  .type-of-care:nth-child(2n) {
    margin-right: 0; /* Remove margin from the right side of the second item in each row */
  }

  .type-of-care-div {
    justify-content: center; /* Ensure items are spaced out evenly */
    gap: 16px;
  }
  .access-network {
    font-size: 28px;
    line-height: 30px;
  }

  .type-of-care-header {
    font-size: 12px;
    white-space: wrap;
  }
  .type-of-care-text {
    font-size: 10px;
  }
  .caregivers-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .main-three {
    padding: 16px;
  }
  .find-job-care-provider {
    padding: 66px 56px;
    justify-content: center;
    gap: 16px;
  }
  .main-three-header,
  .access-network-div,
  .search-box {
    flex-direction: column;
    gap: 8px;
  }
  .search-box input {
    max-width: 100%;
    background: transparent !important;
  }

  .get-started-card-image-div {
    width: 100%;
  }
  .button-get-started {
    width: 100%;
  }
  .main {
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 38px;
    background-color: #fffbfc;
    width: 100%;
  }

  .normal-links {
    font-size: 10px !important;
  }

  .main-one-container::before {
    background-position: top center !important;
    background-size: 100% !important;
    /* background-size: cover !important; */
  }
  .main-one-container {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }
  .inner-white-container {
    padding: 0px !important;
  }

  .find-care-container-div {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    padding-left: 5px;
    flex-direction: column;
  }
  .footer {
    padding: 40px !important;
  }
  .search-box {
    border: 1px solid #979797;
    border-radius: 12px;
    padding: 18px !important;
    max-width: 340px;
  }
  .search-box input {
    border: none;
    color: #979797;
    height: 28px;
    width: 100%;
    max-width: 134px;
    padding-left: 22px;
    box-sizing: border-box;

    outline: none;
  }
  .base-button {
    height: 50px;
  }
  .search-box {
    height: 50px;
    flex-direction: row;
    justify-content: flex-start;
    /* width: 50px; */
  }
  .search-box-button-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    margin-bottom: 16px;
  }
  .search-box {
    width: 100%;
  }

  .main-three {
    justify-content: center !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .get-started-card {
    max-width: 350px;
    width: 100%;
  }
  .main-three-cont {
    align-items: center;
  }
  .button-get-started {
    width: 350px !important;
  }
  .join-now {
    width: 80px !important;
    font-size: 12px !important;
    height: 40px;
  }
  .links {
    align-items: center;
    gap: 15px !important;
  }
}
</style>

<style>
@media screen and (max-width: 1200px) {
  .navbar {
    padding: 16px 64px;
  }
  .main-one-container {
    padding: 0 32px;
  }
  .start-care-div {
    padding: 60px 100px 0px 100px;
  }
  .type-of-care-div {
    gap: 24px;
  }
  .type-of-care {
    width: 200px;
  }
  .get-started-card-image-div {
    width: 400px;
  }
  .button-get-started {
    width: 400px;
  }
  .footer {
    padding: 32px 64px;
  }
}

@media screen and (max-width: 1000px) {
  .navbar {
    padding: 16px 32px;
  }
  .main-one-container {
    padding: 0 16px;
  }
  .start-care-div {
    padding: 40px 60px 0px 60px;
  }
  .type-of-care {
    width: 180px;
  }
  .get-started-card-image-div {
    width: 350px;
  }
  .button-get-started {
    width: 350px;
  }
  .footer {
    padding: 24px 48px;
  }
}
</style>
