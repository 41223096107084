<template>
  <div class="table-container" v-if="showTable">
    <LoadingSpinner :isLoading="isLoading" />

    <!-- Filter Input for Search -->
    <div class="all-filters-container">
      <div class="filter-div">
        <div class="filter-container">
          <label for="">Search</label>
          <input
            class="input-text"
            v-model="searchTerm"
            placeholder="Search..."
            @input="onSearch"
          />
        </div>

        <!-- Checkbox for onboarding status -->
        <!-- <div class="filter-container">
          <label>
            <input
              type="checkbox"
              v-model="isOnboardedChecked"
              @change="onCheck"
            />
            Onboarding Status
          </label>
        </div> -->
      </div>

      <!-- Select for Additional Filtering -->
      <div class="select-filter">
        <div class="select-container">
          <label for="">Onboarding Status</label>
          <select v-model="selectedOptionTwo" @change="onSelectChangeTwo">
            <option
              v-for="option in selectOptionsTwo"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
        <div class="select-container">
          <label for="">Care Type</label>
          <select v-model="selectedOption" @change="onSelectChange">
            <option
              v-for="option in selectOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
        <div class="select-container">
          <label for="">Is Insurance</label>
          <select v-model="selectedOptionThree" @change="onSelectChangeThree">
            <option
              v-for="option in selectOptionsThree"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="table-wrapper">
      <table class="data-table">
        <thead>
          <tr>
            <th v-for="header in headers" :key="header">{{ header }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in paginatedData" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.first_name }} {{ item.last_name }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.has_onboarded ? "Onboarded" : "Not Onboarded" }}</td>
            <!-- <td>{{ item.id }}</td> -->
            <!-- <td>
              <div>
              
                <button @click="performAction(item.id)" class="base-button">
                  View Details
                </button>
              </div>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination -->
    <div class="pagination">
      <button @click="prevPage" class="prev-button" :disabled="page === 1">
        Previous
      </button>
      <span>Page {{ page }}</span>
      <button
        @click="nextPage"
        class="next-button"
        :disabled="page === totalPages"
      >
        Next
      </button>
    </div>
  </div>
  <!-- Alternative content to show after table is hidden -->
  <div v-if="!showTable">
    <div @click="showTable = true" class="caregiver-details-first-div">
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.70492 1.41L6.29492 0L0.294922 6L6.29492 12L7.70492 10.59L3.12492 6L7.70492 1.41Z"
          fill="black"
        />
      </svg>
      <p>Back to Dashboard</p>
    </div>
    <!-- <CareDetails
      :data="careDetails"
      :isCareGiverChosen="careDetails.type == 'Care giver'"
      :showTable="showTable"
    /> -->
    <!-- <button>Back to Table</button> -->
    <!-- <p>Alternative content here...</p> -->
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Request from "@/utils/apiclient.js";

// import CareDetails from "./CareDetails.vue";

export default {
  name: "ReusableTable",
  components: {
    // CareDetails,
   LoadingSpinner },
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true, // Pass table headers as a prop
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    queryParams: {
      type: Object,
      default: () => ({}), // Allow dynamic query parameters
    },
    selectOptions: {
      type: Array,
      default: () => [], // Pass options for select dropdown
    },
    selectOptionsTwo: {
      type: Array,
      default: () => [], // Pass options for select dropdown
    },
    selectOptionsThree: {
      type: Array,
      default: () => [], // Pass options for select dropdown
    },
  },
  setup(props) {
    const data = ref([]);
    const page = ref(1);
    const totalPages = ref(1);
    const searchTerm = ref("");
    const selectedOption = ref(""); // State for select option, "" means "All"
    const selectedOptionTwo = ref(""); // State for select option, "" means "All"
    const selectedOptionThree = ref(""); // State for select option, "" means "All"
    const isLoading = ref(false);
    const isOnboardedChecked = ref(false); // Boolean value for checkbox
    const onboarding_status = ref("False"); // String value to send as query param

    const paginatedData = computed(() => {
      if (!Array.isArray(data.value)) return [];
      const start = (page.value - 1) * props.pageSize;
      return data.value.slice(start, start + props.pageSize);
    });
    const fetchData = () => {
      isLoading.value = true;

      let request;
      const queryParams = {
        ...props?.queryParams,
        // onboarding_status: onboarding_status.value,
        page: page.value,
      };

      // Only append the search term if it has 3 or more characters
      if (searchTerm.value.length >= 3) {
        queryParams.term = searchTerm.value;
      }

      // If a specific option is selected, append query parameters for the select option
      if (selectedOption.value && selectedOption.value !== "All") {
        queryParams.type = selectedOption.value; // Include type only when not "All"
      }
      // If a specific option is selected, append query parameters for the select option
      if (selectedOptionTwo.value && selectedOptionTwo.value !== "All") {
        queryParams.onboarding_status = selectedOptionTwo.value; // Include type only when not "All"
      }
      if (selectedOptionThree.value && selectedOptionThree.value !== "All") {
        queryParams.is_insurance = "True"; // Include type only when not "All"
      }

      const queryString = new URLSearchParams(queryParams).toString();
      request = new Request(`${props.endpoint}?${queryString}`);

      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error fetching data:", err);
        } else {
          console.log("data res", res);
          data.value = res || [];
          totalPages.value = Math.ceil(res.length / props.pageSize);
          console.log("total pages value", totalPages.value);
        }
      });
    };

    const onSearch = () => {
      // Check if the search term has 3 or more characters
      if (searchTerm.value.length >= 3 || searchTerm.value === "") {
        page.value = 1;
        fetchData();
      }
    };

    const onSelectChange = () => {
      page.value = 1;
      fetchData();
    };
    const onSelectChangeTwo = () => {
      page.value = 1;
      fetchData();
    };
    const onSelectChangeThree = () => {
      page.value = 1;
      fetchData();
    };

    const onCheck = () => {
      // Convert boolean checkbox value to string "True"/"False"
      onboarding_status.value = isOnboardedChecked.value ? "True" : "False";
      page.value = 1;
      fetchData();
    };

    const prevPage = () => {
      if (page.value > 1) {
        page.value--;
        fetchData();
      }
    };

    const nextPage = () => {
      if (page.value < totalPages.value) {
        page.value++;
        fetchData();
      }
    };

    watch(searchTerm, () => onSearch());
    watch(selectedOption, () => onSelectChange());
    watch(selectedOptionTwo, () => onSelectChangeTwo());
    onMounted(() => fetchData());

    // Controls whether the action button is shown
    const showTable = ref(true); // Controls table visibility

    const performAction = (careId) => {
      // Perform your desired action here
      // For example, hide the table and show something else
      showTable.value = false;
     const careData =  data.value.find((da) => {
        return da.id == careId;
      });
      console.log("care data", careData);
      careDetails.value = careData;
    };

    const careDetails = ref({});
    return {
      data,
      page,
      totalPages,
      searchTerm,
      selectedOption,
      isLoading,
      paginatedData,
      fetchData,
      onSearch,
      prevPage,
      nextPage,
      isOnboardedChecked,
      onboarding_status,
      onCheck,
      onSelectChange,
      onSelectChangeTwo,
      selectedOptionTwo,
      selectedOptionThree,
      onSelectChangeThree,
      careDetails,
      showTable,
      performAction,
    };
  },

};
</script>

<style scoped>
.table-container {
  padding: 20px;
}

.filter-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.select-container {
  margin-bottom: 20px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 100%;
}

.select-container label {
  white-space: nowrap;
}

.data-table {
  width: 100%;
  table-layout: fixed; /* Ensure equal column width distribution */
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 8px;
  /* border: 1px solid #ddd; */
}
.table-container {
  padding: 20px;
  width: 100%;
}

.data-table {
  width: 100%;
  border-collapse: collapse; /* Ensure borders are collapsed */
  table-layout: auto; /* Ensure equal column width distribution */
}

.data-table th,
.data-table td {
  padding: 10px 15px; /* Add consistent padding */
  text-align: left; /* Align text to the left */
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.input-text {
  width: 150px;
  outline: none;
  transition: 0.2s;
  height: 44px;
  background-color: transparent;
  padding: 16px 10px 16px 10px;
  border-radius: 12px;
  box-sizing: border-box;
  border: 1px solid #979797;
}
.input-text::placeholder {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.filter-div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.next-button {
  width: 100px;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
}
.next-button:disabled {
  background-color: var(--state-grey);
  cursor: not-allowed;
  opacity: 0.2;
}
.prev-button {
  width: 100px;
  background-color: var(--s-green-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
}
.prev-button:disabled {
  background-color: var(--state-grey);
  cursor: not-allowed;
  opacity: 0.2;
}
span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-container {
  width: 100%;
  max-width: 250px;
  height: 60px;

  position: relative;
}

.select-container select {
  width: 150px;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: white;
  color: #333;
  cursor: pointer;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */
}

.select-container::after {
  content: ""; /* Custom arrow */
  font-size: 12px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #333;
}

.select-container select:focus {
  outline: none;
  border-color: #1a73e8; /* Focus border color */
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.select-container select option {
  padding: 10px;
  background-color: white;
  color: #333;
}
.all-filters-container {
  display: flex;
  justify-content: space-between;
}
.table-container {
  width: 100%;
}
.select-filter {
  display: flex;
  align-items: center;
  gap: 20px;
}
.base-button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 10px;
  font-size: 10px;

  border: 2px solid transparent;
  border-radius: 25px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, opacity 0.3s;
}

.base-button {
  background-color: var(--s-green-normal);
  color: var(--state-white);
}
.caregiver-details-first-div {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
</style>
