<template>
  <DashboardLayout>
    <div class="overall-container">
      <div class="the-container">
        <div v-if="!isLargeScreen" @click="redirectToHome" class="back-class">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 7H3.83L9.42 1.41L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z"
              fill="black"
            />
          </svg>
          Home
        </div>
        <div class="dashboard-main-container">
          <div class="first-dashboard-container">
            <header>
              <h1>Jobs</h1>
            </header>
            <div class="tabs">
              <div
                class="single-tab"
                :class="{ active: currentTab === 'active' }"
                @click="setTab('active')"
              >
                <p>Active</p>
              </div>
              <div
                class="single-tab"
                :class="{ active: currentTab === 'archived' }"
                @click="setTab('archived')"
              >
                <p>Archived</p>
              </div>
            </div>
            <div class="jobs-container">
              <section v-if="currentTab === 'active'" class="jobs">
                <div v-if="filteredJobs.length == 0">
                  <div class="no-archived-container">
                    <div class="no-archived">
                      <svg
                        width="125"
                        height="107"
                        viewBox="0 0 125 107"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M84.6783 4.47897C84.6783 4.47897 67.5568 26.1989 40.1618 13.2436C12.7668 0.288249 -14.6278 35.3442 8.96193 56.6832C32.5516 78.0222 9.46734 89.7478 25.4935 102.826C43.7082 117.69 59.5663 87.5485 74.0246 94.4075C105.935 109.545 129.575 103.553 124.248 86.0243C114.898 55.2554 102.941 53.2537 112.073 37.2495C121.205 21.2453 101.051 -12.0315 84.6783 4.47897Z"
                          fill="#FCE0E5"
                        />
                        <path
                          d="M94.2 25.5789H90.2895C85.9875 25.5789 82.5 22.0915 82.5 17.7895C82.5 13.4663 79.029 10 74.7 10H51.3C46.971 10 43.5 13.4663 43.5 17.7895C43.5 22.0915 40.0125 25.5789 35.7105 25.5789H31.8C27.471 25.5789 24 29.0453 24 33.3684V76.2105C24 80.5337 27.471 84 31.8 84H94.2C98.529 84 102 80.5337 102 76.2105V33.3684C102 29.0453 98.529 25.5789 94.2 25.5789ZM51.3 21.6842C51.3 19.5332 53.0437 17.7895 55.1947 17.7895H70.8053C72.9563 17.7895 74.7 19.5332 74.7 21.6842C74.7 23.8352 72.9563 25.5789 70.8053 25.5789H55.1947C53.0437 25.5789 51.3 23.8352 51.3 21.6842ZM94.2 72.3158C94.2 74.4668 92.4563 76.2105 90.3053 76.2105H35.6947C33.5437 76.2105 31.8 74.4668 31.8 72.3158C31.8 70.1648 33.5437 68.4211 35.6947 68.4211H90.3053C92.4563 68.4211 94.2 70.1648 94.2 72.3158ZM94.2 46.7368C94.2 52.2597 89.7229 56.7368 84.2 56.7368H41.8C36.2771 56.7368 31.8 52.2597 31.8 46.7368V39.2184C31.8 35.9876 34.4191 33.3684 37.65 33.3684H39.6053C41.7563 33.3684 43.5 35.1122 43.5 37.2632C43.5 39.4142 45.249 41.1579 47.4 41.1579C49.551 41.1579 51.3 39.4142 51.3 37.2632C51.3 35.1122 53.0437 33.3684 55.1947 33.3684H70.8053C72.9563 33.3684 74.7 35.1122 74.7 37.2632C74.7 39.4142 76.449 41.1579 78.6 41.1579C80.751 41.1579 82.5 39.4142 82.5 37.2632C82.5 35.1122 84.2437 33.3684 86.3947 33.3684H88.35C91.5809 33.3684 94.2 35.9876 94.2 39.2184V46.7368Z"
                          fill="#17865F"
                        />
                      </svg>
                         <p>You have no active Jobs listed</p>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-for="job in filteredJobs"
                    :key="job.id"
                    class="job"
                    @click="openJobDetails(job)"
                  >
                    <div class="job-header-content">
                      <div class="job-header-content-job-title">
                        <h2>{{ job.title }}</h2>
                        <div class="posted-ago">
                          <p>Posted {{ job.posted }} ago</p>
                        </div>
                      </div>
                      <div>
                        <p>Intermediate - Est. Budget: {{ job.budget }}</p>
                      </div>
                    </div>
                    <div class="job-body-content">
                      <p>{{ job.description }}</p>
                    </div>

                    <!-- <div class="job-tag">
                   < span v-for="tag in job.tags" :key="tag">{{ tag }}</span>
                   </div> -->
                    <!-- <button @click="saveJob(job.id)">Save</button> -->
                  </div>
                </div>
              </section>
              <section v-if="currentTab === 'archived'">
                <div class="no-archived-container">
                  <div class="no-archived">
                    <svg
                      width="125"
                      height="107"
                      viewBox="0 0 125 107"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M84.6783 4.47897C84.6783 4.47897 67.5568 26.1989 40.1618 13.2436C12.7668 0.288249 -14.6278 35.3442 8.96193 56.6832C32.5516 78.0222 9.46734 89.7478 25.4935 102.826C43.7082 117.69 59.5663 87.5485 74.0246 94.4075C105.935 109.545 129.575 103.553 124.248 86.0243C114.898 55.2554 102.941 53.2537 112.073 37.2495C121.205 21.2453 101.051 -12.0315 84.6783 4.47897Z"
                        fill="#FCE0E5"
                      />
                      <path
                        d="M94.2 25.5789H90.2895C85.9875 25.5789 82.5 22.0915 82.5 17.7895C82.5 13.4663 79.029 10 74.7 10H51.3C46.971 10 43.5 13.4663 43.5 17.7895C43.5 22.0915 40.0125 25.5789 35.7105 25.5789H31.8C27.471 25.5789 24 29.0453 24 33.3684V76.2105C24 80.5337 27.471 84 31.8 84H94.2C98.529 84 102 80.5337 102 76.2105V33.3684C102 29.0453 98.529 25.5789 94.2 25.5789ZM51.3 21.6842C51.3 19.5332 53.0437 17.7895 55.1947 17.7895H70.8053C72.9563 17.7895 74.7 19.5332 74.7 21.6842C74.7 23.8352 72.9563 25.5789 70.8053 25.5789H55.1947C53.0437 25.5789 51.3 23.8352 51.3 21.6842ZM94.2 72.3158C94.2 74.4668 92.4563 76.2105 90.3053 76.2105H35.6947C33.5437 76.2105 31.8 74.4668 31.8 72.3158C31.8 70.1648 33.5437 68.4211 35.6947 68.4211H90.3053C92.4563 68.4211 94.2 70.1648 94.2 72.3158ZM94.2 46.7368C94.2 52.2597 89.7229 56.7368 84.2 56.7368H41.8C36.2771 56.7368 31.8 52.2597 31.8 46.7368V39.2184C31.8 35.9876 34.4191 33.3684 37.65 33.3684H39.6053C41.7563 33.3684 43.5 35.1122 43.5 37.2632C43.5 39.4142 45.249 41.1579 47.4 41.1579C49.551 41.1579 51.3 39.4142 51.3 37.2632C51.3 35.1122 53.0437 33.3684 55.1947 33.3684H70.8053C72.9563 33.3684 74.7 35.1122 74.7 37.2632C74.7 39.4142 76.449 41.1579 78.6 41.1579C80.751 41.1579 82.5 39.4142 82.5 37.2632C82.5 35.1122 84.2437 33.3684 86.3947 33.3684H88.35C91.5809 33.3684 94.2 35.9876 94.2 39.2184V46.7368Z"
                        fill="#17865F"
                      />
                    </svg>
                    <p>You have no archived Jobs listed</p>
                    <!-- <BaseButton
                      label="Search for Jobs"
                      backgroundColor="var(--s-pink-normal)"
                      textColor="var(--state-white)"
                      height="60px"
                      width="243px"
                      @click="handleClick"
                    /> -->
                  </div>
                </div>
              </section>
            </div>
          </div>

          <!-- Job Details Modal -->
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, computed } from "vue";
// import BaseButton from "@/components/BaseButton.vue";
import "@vueform/multiselect/themes/default.css";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
export default {
  name: "MyJobs",
  components: {
    // BaseButton,
    DashboardLayout,
  },
  setup() {
    const router = useRouter();
    // Sample data
    const jobs = ref([
      // {
      //   id: 1,
      //   posted: "5 minutes",
      //   title: "Senior Care Needed",
      //   budget: "₦20,000",
      //   description:
      //     "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
      //   tags: ["Bathing / Dressing", "Special Care"],
      // },
      // {
      //   id: 2,
      //   posted: "5 minutes",
      //   title: "Senior Care Needed",
      //   budget: "₦20,000",
      //   description:
      //     "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
      //   tags: ["Bathing / Dressing", "Special Care"],
      // },
      // {
      //   id: 3,
      //   posted: "5 minutes",
      //   title: "Senior Care Needed",
      //   budget: "₦20,000",
      //   description:
      //     "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
      //   tags: ["Bathing / Dressing", "Special Care"],
      // },
      // {
      //   id: 4,
      //   posted: "5 minutes",
      //   title: "Senior Care Needed",
      //   budget: "₦20,000",
      //   description:
      //     "I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years I need a sit in care giver for my mom of 75 years. I need a sit in care giver for ..",
      //   tags: ["Bathing / Dressing", "Special Care"],
      // },
      // Add more job objects here
    ]);

    const user = ref({
      image: "profileimage.png",
      name: "Jane Adeyemi",
      title: "Professional Caregiver",
      availability: "Mondays - Friday",
      categories: ["Child Care", "Senior Care", "Academic Care", "Home Care"],
      preferences: "Live Out",
    });

    const searchQuery = ref("");
    const currentTab = ref("active");
    const isModalVisible = ref(false);
    const selectedJob = ref({});

    // Computed property to filter jobs based on search query
    const filteredJobs = computed(() => {
      return jobs.value.filter((job) =>
        job.title.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    });

    // Method to set the current tab
    const setTab = (tab) => {
      currentTab.value = tab;
    };

    // Method to save a job (dummy implementation)
    const saveJob = (jobId) => {
      console.log(`Job ${jobId} saved!`);
    };

    // Method to open job details modal
    const openJobDetails = (job) => {
      selectedJob.value = job;
      isModalVisible.value = true;
    };

    // Method to close job details modal
    const closeJobDetails = () => {
      selectedJob.value = null;
    };
    const isLargeScreen = ref(window.innerWidth > 800);

    const handleResize = () => {
      isLargeScreen.value = window.innerWidth > 800;
      console.log("inner", window.innerWidth);
    };
    // end main modal
    const redirectToHome = () => {
      router.push("/caregiver");
    };
    return {
      jobs,
      user,
      searchQuery,
      currentTab,
      filteredJobs,
      setTab,
      saveJob,
      openJobDetails,
      closeJobDetails,
      selectedJob,
      isModalVisible,
      redirectToHome,
      isLargeScreen,
      handleResize,
    };
  },
};
</script>

<style scoped>
.overall-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.the-container {
  width: 1184px;
  display: flex;
  box-sizing: border-box;
  /* padding-left: 128px; */
}
.login-container {
  max-width: 400px;
  margin: auto;
}

h2 {
  margin-bottom: 20px;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.5px;
  text-align: center;
}

.login-button {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}

.login-button:hover {
  background-color: var(--s-pink-normal-hover);
}

.forgot-password {
  display: block;
  margin-bottom: 34px;
  color: var(--s-green-normal);
  text-decoration: none;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password:hover {
  color: var(--s-green-normal-hover);
}

.google-login,
.apple-login {
  width: 100%;
  padding: 10px 67px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 10px;
  font-size: var(--font-body-large-regular-size);
  font-weight: var(--font-weight-regular);
  line-height: 34px;
  font-family: var(--font-family);
  white-space: nowrap;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.google-login img,
.apple-login img {
  width: 40.67px;
  height: 40.67px;
}

/* .google-login:hover,
.apple-login:hover {
  background-color: #f5f5f5;
} */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
}
.separator:not(:empty)::before {
  margin-right: 8px;
}
.separator:not(:empty)::after {
  margin-left: 8px;
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-form {
  padding-bottom: 200px;
}

.dashboard-main-container {
  width: 899px;
  display: flex;
  gap: 24px;
  height: 100%;
  margin-top: 33px;

  box-sizing: border-box;
}

.first-dashboard-container {
  width: 100%;
  box-sizing: border-box;
}
.second-dashboard-container {
  width: 100%;
  width: 372px;
  box-sizing: border-box;
  padding-top: 76px;
}
h1 {
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
  margin-top: 0px;
  margin-bottom: 24px;

  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.search-wrapper input {
  border: 1px solid #979797 !important;
  width: 575px;
  height: 64px;
  padding: 10px 10px 10px 70px;
  gap: 16px;
  border-radius: 12px;
  border: 1px;
  box-sizing: border-box;
  background-color: transparent;
}
.search-wrapper input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: #979797;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.search-icon {
  position: absolute;
  left: 20px;
}

.active {
  border-bottom: 3px solid var(--s-pink-normal);
}
.active p {
  color: var(--s-pink-normal) !important;
  font-weight: var(--font-weight-bold) !important;
}
.single-tab {
  padding: 0px 20.5px 4px 20.5px;
  cursor: pointer;
}
.tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 42px;
}
.tabs p {
  color: #0a3828;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  font-weight: var(--font-weight-regular);
}
.jobs-container {
  padding-top: 32px;
  width: 100%;
}
.job {
  padding: 34px 16px 34px 16px;
  border-bottom: 0.5px solid black;
  cursor: pointer;
}
.job-header-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  margin-bottom: 10px;
}
.job-header-content p {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
}
.job-header-content-job-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.job-header-content-job-title h2 {
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
}
.job-body-content p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: justified;
}
.job-body-content {
  margin-bottom: 10px;
}
.job-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.job-tag span {
  padding: 10px;
  gap: 8px;
  border-radius: 12px;
  background-color: #ececec;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.second-dashboard-container-main {
  display: flex;
  gap: 18px;
  flex-direction: column;
}
.second-dashboard-container-profile-div {
  background-color: #e8f3ef80;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px 16px;
}
.second-dashboard-container-profile-div img {
  width: 71px;
  height: 71px;
  border-radius: 50%;
}
.second-dashboard-container-profile-settings {
  background-color: #e8f3ef80;
}
.second-dashboard-container-profile-div-user {
  display: flex;
  flex-direction: column;
}
.second-dashboard-container-profile-div-user h3 {
  margin: 0px;
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.second-dashboard-container-profile-div-user p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.second-dashboard-container-profile-settings {
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.second-dashboard-container-profile-settings h4 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  margin: 0px;
}
.availability-header,
.categories-header,
.job-preferences-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.availability,
.categories,
.preferences p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
}
.availability,
.categories,
.preferences {
  padding: 10px 0px 10px 0px;
}

/* start availability */
.availability-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.days-container,
.times-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.day-button,
.time-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.day-button.selected,
.time-button.selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal);
}

.specific-time-container {
  margin-bottom: 20px;
  margin-top: 29px;
}

.time-picker {
  display: flex;
  align-items: center;
  gap: 29px;
  margin-top: 10px;
  position: relative;
}

.time-picker select {
  padding: 16px 52px 16px 10px;

  border-radius: 12px;
  border: 1px solid #979797;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z" fill="black"/></svg>')
    no-repeat right 26px center;
  background-size: 10px 6px;
}

.time-picker select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer */
}

.next-button {
  background-color: #ff6978;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

.days-container button {
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.specific-time-container label {
  text-align: left;
}

.add-times {
  color: #0063f7;
  text-decoration: none;
  text-align: left;
}
span {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}
.bottom-text {
  margin-top: 29px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}
/* end availability */
/* start .categories */
input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.selected-options {
  margin-bottom: 16px;
  margin-top: 16px;
}
.selected-option {
  display: inline-block;
  background-color: var(--s-pink-light-hover);
  border-radius: 12px;
  padding: 10px;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;

  color: #000000;
  border: 1px solid var(--s-green-normal-active);
}
.focus {
  border: 1px solid var(--s-green-normal);
}

.dropdown {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
}
.dropdown label {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  margin-left: 8px;
  color: #000000;
}

.option-container {
  width: 50%;
  box-sizing: border-box;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.care-button .category {
  margin-top: 10px;
}

.care-button {
  padding: 16px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  margin-top: 20px;
  flex-direction: column;
  box-sizing: border-box;
}
.care-button-first {
  display: flex;
  justify-content: space-between;
}
.care-button {
  max-height: 210px;
}
input[type="checkbox"] {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #979797;
  background-color: transparent;
  display: grid;
  place-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

input[type="checkbox"]::before {
  content: "";
  width: 10px;
  height: 10px;
  transform: scale(0);
  transition: transform 0.3s;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}
/* end categories */
.custom-multiselect {
  position: relative;
  width: 100%;
}
.multiselect-option.is-selected {
  background: var(--s-green-normal-active);
  color: #000;
}
.custom-multiselect .multiselect {
  z-index: 11;
  width: 100%;
  padding: 10px;
  height: 60px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}

.custom-multiselect .multiselect__content {
  background-color: transparent; /* White background for options */
}
.multiselect {
  background: transparent;
}
.custom-multiselect-selected .multiselect {
  border: 1px solid var(--s-green-normal-active); /* Change to desired color when a value is selected */
}
.no-archived-container {
  display: flex;
  justify-content: center;
  padding-top: 49px;
}
.no-archived {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 575px;
  background-color: #fff;
  padding-top: 59px;
  padding-bottom: 59px;
  flex-direction: column;
}
.no-archived svg {
  margin-bottom: 10px;
}
.no-archived p {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: center;
}

@media (max-width: 800px) {
  .the-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
  }
  .job-header-content-job-title p {
    font-size: 8px;
  }
  .job-header-content-job-title h2 {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    letter-spacing: 0.5px;
    text-align: left;
  }
  .posted-ago p {
    text-align: right !important;
  }
  .job-header-content p {
    text-align: left;
  }
  h1 {
    margin-bottom: 5px;
  }
  .tabs {
    margin-top: 10px;
  }
  .jobs-container {
    padding-top: 15px;
  }
  .no-archived {
    width: 300px;
  }
  .no-archived-container {
    padding-top: 20px;
  }
  .job {
    padding: 34px 0px 34px 0px;
  }
  .back-class {
    display: flex;
    align-items: center;
    gap: 16px;

    width: 100%;

    margin-top: 20px;
  }
  .dashboard-main-container {
    width: 100%;
    max-width: 320px;
  }
  .no-archived p {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 20.4px;
    letter-spacing: 0.5px;
    text-align: center;
  }
}
</style>

