<template>
  <div class="main-container">
    <h2>Complete your Bio</h2>

    <div class="complete-bio-container">
      <p>Qualifications</p>
      <form @submit.prevent="submitForm">
        <div class="complete-bio-section">
          <p class="header-text">Education</p>
          <div class="complete-bio-section-div">
            <CustomInput
              label="Institution"
              v-model="institution"
              placeholder="University / High school"
              type="text"
              focusColor="#17865F"
              defaultColor="#979797"
            />
          </div>
          <div class="complete-bio-section-div">
            <SelectDropdown
              label="Qualification"
              id="qualification"
              :options="qualificationOptions"
              width="400px"
              @update:selectedOption="handleQualificationSelection"
            />
            <div v-if="selectedQualification" class="file-div">
              <svg
                width="11"
                height="20"
                viewBox="0 0 11 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5 4V14.5C9.5 16.71 7.71 18.5 5.5 18.5C3.29 18.5 1.5 16.71 1.5 14.5V4C1.5 2.62 2.62 1.5 4 1.5C5.38 1.5 6.5 2.62 6.5 4V13.5C6.5 14.05 6.05 14.5 5.5 14.5C4.95 14.5 4.5 14.05 4.5 13.5V4H3V13.5C3 14.88 4.12 16 5.5 16C6.88 16 8 14.88 8 13.5V4C8 1.79 6.21 0 4 0C1.79 0 0 1.79 0 4V14.5C0 17.54 2.46 20 5.5 20C8.54 20 11 17.54 11 14.5V4H9.5Z"
                  fill="black"
                />
              </svg>
              <input type="file" id="degree_file" @change="handleFileUpload" />
              <label for="degree_file" class="degree-file">
                <!-- {{ fileName || `Upload a copy of your ${selectedQualification} certificate` }} -->
                {{ fileName || `Upload a copy of your certificate` }}
              </label>
            </div>
          </div>
        </div>
        <div class="complete-bio-section">
          <p class="header-text-language">Preference</p>
          <div class="complete-bio-section-div">
            <SelectDropdown
              selectedText="Choose option"
              label="Preference"
              id="profession"
              :options="professionOptions"
              width="400px"
              height="60px"
              @update:selectedOption="handleProfessionSelection"
            />
          </div>
        </div>
        <div class="complete-bio-section">
          <p class="header-text-language">Language</p>
          <div :class="{ selectedoptions: selectedOptions.length }">
            <span
              v-for="option in selectedOptions"
              :key="option.id"
              class="selected-option"
            >
              {{ option.name }}
            </span>
          </div>
          <div
            v-if="selectedOptions.length && hideLanguageDropdown == false"
            @click="toggleLanguages"
            :class="{ addlanguage: selectedOptions.length }"
          >
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_274_4864)">
                <path
                  d="M9.99935 2.1665C5.39935 2.1665 1.66602 5.89984 1.66602 10.4998C1.66602 15.0998 5.39935 18.8332 9.99935 18.8332C14.5993 18.8332 18.3327 15.0998 18.3327 10.4998C18.3327 5.89984 14.5993 2.1665 9.99935 2.1665ZM14.166 11.3332H10.8327V14.6665H9.16602V11.3332H5.83268V9.6665H9.16602V6.33317H10.8327V9.6665H14.166V11.3332Z"
                  fill="#0063F7"
                />
              </g>
              <defs>
                <clipPath id="clip0_274_4864">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <p>Add Language</p>
          </div>
          <div
            v-if="hideLanguageDropdown"
            class="care-button"
            :class="{ focus: categories.showDropdown }"
          >
            <div class="care-button-first" @click="toggleDropdown">
              {{ categories.name }}
              <span v-if="categories.showDropdown">
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.68141e-08 6.50016L1.41 7.91016L6 3.33016L10.59 7.91016L12 6.50016L6 0.500156L1.68141e-08 6.50016Z"
                    fill="black"
                  />
                </svg>
              </span>
              <span v-else>
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 1.91L10.59 0.5L6 5.08L1.41 0.5L-6.16331e-08 1.91L6 7.91L12 1.91Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
            <div v-if="categories.showDropdown" class="dropdown">
              <div
                v-for="option in categories.options"
                :key="option.id"
                class="option-container"
              >
                <input
                  type="checkbox"
                  :id="option.id"
                  :value="option.id"
                  v-model="categories.selectedOptions"
                  @change="updateSelectedOptions"
                />
                <label :for="option.id">{{ option.name }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="button-div">
          <BaseButton
            label="Next"
            backgroundColor="var(--s-pink-normal)"
            textColor="var(--state-white)"
            height="60px"
            width="100%"
            :disabled="!isFormValid"
            disabledBackgroundColor="#ECECEC"
            disabledColor="#ECECEC"
            disabledTextColor="#979797"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, computed, inject, onMounted } from "vue";
import SelectDropdown from "@/components/SelectDropdown.vue";
import CustomInput from "@/components/CustomInput.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useRouter } from "vue-router";
import Request from "@/utils/apiclient.js";

export default {
  name: "BuildProfileStepFour",
  components: {
    SelectDropdown,
    CustomInput,
    BaseButton,
  },
  setup() {
    const categories = ref({
      name: "Select Language",
      showDropdown: false,
      options: [],
      selectedOptions: [],
    });

    const fetchLanguages = () => {
      const request = new Request("/language/");
      request.get((res, err) => {
        if (err) {
          console.error("Error:", err);
        } else {
          categories.value.options = res.results.map((lang) => ({
            id: lang.id,
            name: lang.name,
          }));
        }
      });
    };

    const router = useRouter();
    const formData = inject("formData");
    const updateFormData = inject("updateFormData");

    const localData = ref({
      ...formData.value.stepSevenData,
    });
    const institution = ref("");
    const qualification = ref("");
    const profession = ref("");
    const language = ref([]);
    const file = ref(null);
    const fileName = ref("");
    const selectedOptions = ref([]);
    const hideLanguageDropdown = ref(true);

    const toggleLanguages = () => {
      hideLanguageDropdown.value = !hideLanguageDropdown.value;
    };

    const professionOptions = ref([
      {
        value: "Live In",
        text: "Live In",
      },
      {
        value: "Live Out",
        text: "Live Out",
      },
      {
        value: "Both",
        text: "Both",
      },
    ]);

    const qualificationOptions = ref([
      {
        value: "PostGraduate degree",
        text: "PostGraduate degree",
      },
      {
        value: "Graduate degree",
        text: "Graduate degree",
      },
      {
        value: "Diploma degree",
        text: "Diploma degree",
      },
      {
        value: "High School degree",
        text: "High School degree",
      },
    ]);

    const selectedProfession = ref("");
    const selectedQualification = ref("");

    const handleProfessionSelection = (value) => {
      selectedProfession.value = value;
    };

    const handleQualificationSelection = (value) => {
      selectedQualification.value = value;
    };

    const toggleDropdown = () => {
      categories.value.showDropdown = !categories.value.showDropdown;
      if (!categories.value.showDropdown && selectedOptions.value.length) {
        hideLanguageDropdown.value = false;
      }
    };

    const updateSelectedOptions = () => {
      selectedOptions.value = categories.value.selectedOptions.map((id) => {
        return categories.value.options.find((option) => option.id === id);
      });
    };

    const handleFileUpload = (event) => {
      const uploadedFile = event.target.files[0];
      file.value = uploadedFile;
      fileName.value = uploadedFile ? uploadedFile.name : "";
    };

    const isFormValid = computed(() => {
      return (
        institution.value &&
        selectedQualification.value &&
        selectedProfession.value &&
        file.value &&
        selectedOptions.value.length > 0
      );
    });

    const selectedLanguageIds = computed(() => {
      return selectedOptions.value.map((option) => option.id);
    });

    const submitForm = () => {
      localData.value.institution = institution.value;
      localData.value.qualification = selectedQualification.value;
      localData.value.work_preference = selectedProfession.value;
      localData.value.language = selectedLanguageIds.value;
      localData.value.certificate = file.value;
      console.log("lang", selectedLanguageIds.value);
      updateFormData("stepSevenData", localData.value);

      router.push("/caregiver-onboarding/complete-verification/step-1");
    };

    onMounted(() => {
      fetchLanguages();
    });

    return {
      qualification,
      profession,
      language,
      handleFileUpload,
      submitForm,
      toggleDropdown,
      categories,
      updateSelectedOptions,
      selectedOptions,
      professionOptions,
      handleProfessionSelection,
      selectedProfession,
      qualificationOptions,
      selectedQualification,
      handleQualificationSelection,
      institution,
      toggleLanguages,
      hideLanguageDropdown,
      isFormValid,
      fileName,
      selectedLanguageIds,
    };
  },
};
</script>

<style scoped>
/* Add your styles here */
.complete-bio-container {
  display: flex;
  width: 400px;
  flex-direction: column;
}
.main-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
h2 {
    margin-top: 30px;
   
  
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
}
p {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.complete-bio-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.complete-bio-section-div {
  display: flex;
  flex-direction: column;
}
.file-div {
  display: flex;
  margin-top: 8px;
  flex-direction: row;
}
.file-div input {
  display: none;
}

.care-button .category {
  margin-top: 10px;
}

.care-button {
  padding: 15px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  flex-direction: column;
  box-sizing: border-box;
}
.care-button-first {
  display: flex;
  justify-content: space-between;
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.care-button {
  max-height: 145px;
}
.option-container {
  width: 28%;
  box-sizing: border-box;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}
.dropdown {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  justify-content: space-between;
}
.dropdown label {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  margin-left: 8px;
  color: #000000;
}
.selected-option {
  display: inline-block;
  background-color: var(--s-pink-light-hover);
  border-radius: 12px;
  padding: 10px;
  margin-right: 8px;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  color: #000000;
  border: 1px solid var(--s-green-normal-active);
}
.focus {
  border: 1px solid var(--s-green-normal);
}
.selectedoptions {
  margin-bottom: 16px;
  /* margin-top: 16px; */
}
.degree-file {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #0063f7;
  margin-left: 8px;
}
.header-text {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.header-text-language {
  margin-top: 24px;
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.addlanguage {
  display: flex;
  gap: 10px;
  align-items: center;
}
.addlanguage p {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
  cursor: pointer;
}
.button-div {
  width: 400px;
}


@media screen and (max-width: 800px) {
  .care-card-container {
    width: 350px;
  }
  .selects-div {
    width: 350px;
    flex-direction: column;
  }

  .complete-bio-container :deep(.custom-select-container) {
    width: 350px !important;
  }
  .complete-bio-container :deep(.custom-select-trigger) {
    width: 350px !important;
  }
  .work-preference {
    justify-content: center;
  }

  .button-div {
    width: 350px;
    display: flex;
    justify-content: center;
  }
  .days-container-main {
    max-width: 350px;
  }
  .complete-bio-container {
    width: 350px;
  }
  h2 {
    margin-top: 20px;
   
  
font-size: 20px;
font-weight: 700;
line-height: 31.2px;
letter-spacing: 0.5px;
text-align: left;
width: 350px;
margin-bottom: 16px;


  }
  .main-container{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
}
</style>
