<template>
  <div>
    <!-- Table -->
      <LoadingSpinner :isLoading="isLoading" />
    <div v-if="notCareView" class="table-wrapper">
      <table class="data-table">
        <thead>
          <tr>
            <th>S/N</th>
            <!-- <th>Group Name</th> -->
            <th>Caregiver</th>
            <th>Careseeker</th>
            <!-- <th>Last Message</th> -->
            <th>Timestamp</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(conversation, index) in paginatedData"
            :key="conversation.groupName"
          >
            <td>{{ index + 1 + (page - 1) * pageSize }}</td>
            <!-- <td>{{ conversation.groupName }}</td> -->
            <td>{{ conversation.caregiverUsername }}</td>
            <td>{{ conversation.careseekerUsername }}</td>
            <!-- <td>{{ conversation.lastMessage }}</td> -->
            <td>{{ new Date(conversation.timestamp).toLocaleString() }}</td>
            <td>
              <div>
                <button
                  @click="viewMessages(conversation.theGroupName)"
                  class="base-button"
                >
                  View Details
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Job Details Modal -->
    <GenericModal
      :isVisible="isMainModalVisible"
      :title="modalTitle"
      @close="hideModal"
      @confirm="handleConfirm"
    >
         <MainMessageItem
                v-for="(message, index) in mainMessages"
                :key="index"
                :name="message.sender.full_name"
                :date="message.timestamp"
                :time="new Date(message.timestamp).toLocaleTimeString()"
                :message="message.message"
                :avatarSrc="message.sender.profile_picture"
                :initials="
                  message.sender.full_name.split(' ')[0].charAt(0) +
                  message.sender.full_name.split(' ').slice(-1)[0].charAt(0)
                "
                :reply="message.reply"
                :senderType="message.sender.type"
              />
     
     
    </GenericModal>
    <!-- Pagination -->
    <div v-if="notCareView" class="pagination">
      <button @click="prevPage" class="prev-button" :disabled="page === 1">
        Previous
      </button>
      <span class="page">Page {{ page }}</span>
      <button
        @click="nextPage"
        class="next-button"
        :disabled="page === totalPages"
      >
        Next
      </button>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import GenericModal from "@/components/GenericModal.vue";
import MainMessageItem from "@/components/MainMessageItem.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
export default {
  name: "GenericTable",
  props: {
    data: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  components: {
    GenericModal,
    MainMessageItem,
    LoadingSpinner
  },
  setup(props, { emit }) {
       const isLoading = ref(false);
    const mainMessages = ref([]);
    let socket;
    const universalGroupName = ref("");
    const isMainModalVisible = ref(false);

    const modalTitle = ref("");
    const page = ref(1);
    const totalPages = computed(() => {
      return Math.ceil(props.data.length / props.pageSize);
    });

    const paginatedData = computed(() => {
      if (!Array.isArray(props.data)) return [];
      const start = (page.value - 1) * props.pageSize;
      return props.data.slice(start, start + props.pageSize);
    });

    const prevPage = () => {
      if (page.value > 1) {
        page.value--;
      }
    };

    const nextPage = () => {
      if (page.value < totalPages.value) {
        page.value++;
      }
    };
    const userToken = localStorage.getItem("_user");
    const viewMessages = (groupname) => {
      mainMessages.value = []
      isLoading.value = true;
      universalGroupName.value = groupname;
      console.log("grp", groupname);
      modalTitle.value = "View Chat";
      isMainModalVisible.value = true;
      connectWebSocket(groupname);
    };
    // web socket config
    const connectWebSocket = (groupname) => {
      // Close existing WebSocket if any
      if (socket) {
        console.log(
          "Closing existing WebSocket connection before opening a new one."
        );
        socket.removeEventListener("open", handleWebSocketOpen);
        socket.removeEventListener("message", handleWebSocketMessage);
        socket.removeEventListener("error", handleWebSocketError);
        socket.removeEventListener("close", handleWebSocketClose);
        socket.close();
      }

      const socketUrl = `wss://api-dev.succour.care/ws/chat/${groupname}/?token=${userToken}`;
      console.log("Attempting WebSocket connection to:", socketUrl);
      socket = new WebSocket(socketUrl);

      // Add event listeners
      socket.addEventListener("open", handleWebSocketOpen);
      socket.addEventListener("message", handleWebSocketMessage);
      socket.addEventListener("error", handleWebSocketError);
      socket.addEventListener("close", handleWebSocketClose);
    };
    const handleWebSocketOpen = () => {
      console.log("WebSocket connection opened.");
      try {
        // Validate selectedMessageRoom and group_name
        if (!universalGroupName.value) {
          throw new Error("Selected message room or group_name is undefined.");
        }

        // Check if the socket is open
        if (socket.readyState === WebSocket.OPEN) {
          const message = JSON.stringify({
            type: "join",
            room: universalGroupName.value,
          });
          console.log("mess", message);
          socket.send(message);
        } else {
          throw new Error(
            `WebSocket is not open: readyState=${socket.readyState}`
          );
        }
      } catch (error) {
        console.error("Error in handleWebSocketOpen:", error);

        // Optionally notify the user about the error
        // alert("Failed to join the chat room. Please try again.");
      }
    };
    // WebSocket Error Handler
    const handleWebSocketError = (e) => {
      console.error("WebSocket error occurred:", e);
      // Optionally notify the user
      // alert("WebSocket connection error. Please try again later.");
    };
    const handleWebSocketMessage = (e) => {
      try {
        const msg = JSON.parse(e.data);
        console.log("Received message:", msg);

        if (universalGroupName.value) {
          // Create a unique key for the message
          const msgKey = `${msg.timestamp}_${msg.sender.full_name}_${msg.message}`;

          // Check if the message already exists in mainMessages
          const messageExists = mainMessages.value.some((message) => {
            const existingMsgKey = `${message.timestamp}_${message.sender.full_name}_${message.message}`;
            return existingMsgKey === msgKey;
          });

          if (!messageExists) {
            mainMessages.value.push(msg);
            isLoading.value = false
            console.log("main messages", mainMessages);
          } else {
                  isLoading.value = false
            console.log("Duplicate message detected, ignoring.");
          }
        } else {
                isLoading.value = false
          console.log("Received message but room does not match:", msg.room);
        }
      } catch (error) {
              isLoading.value = false
        console.error("Error parsing message:", error);
      }
    };
    // WebSocket Close Handler
    const handleWebSocketClose = (e) => {
      console.log("WebSocket connection closed.", e);
      if (!e.wasClean) {
        console.error("WebSocket closed unexpectedly:", e);
        // Optionally attempt to reconnect after a delay
        setTimeout(() => {
          connectWebSocket();
        }, 5000); // Reconnect after 5 seconds
      }
    };
    // websocket config end
    const hideModal = () => {
      isMainModalVisible.value = false;
    };
    const actionDropdown = ref(null);

    const toggleDelistDropdown = (index) => {
      actionDropdown.value = actionDropdown.value === index ? null : index;
    };

    const delistCaregiver = (caregiver) => {
      emit("delist", caregiver);
      actionDropdown.value = null; // Close the dropdown after action
    };

    const viewCare = (care) => {
      emit("view-care", care);
    };

    const notCareView = ref(true);
    const handleConfirm = () => {
      hideModal();
    };
    return {
      page,
      totalPages,
      paginatedData,
      prevPage,
      nextPage,
      actionDropdown,
      toggleDelistDropdown,
      delistCaregiver,
      viewCare,
      notCareView,
      viewMessages,

      isMainModalVisible,
      modalTitle,
      hideModal,
      handleConfirm,
      mainMessages,
      isLoading
    };
  },
};
</script>
<style scoped>
table {
  width: 100%;
}
.table-wrapper {
  width: 100%;
}
.table-container {
  padding: 20px;
}

.filter-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.select-container {
  margin-bottom: 20px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 100%;
}

.select-container label {
  white-space: nowrap;
}

.data-table {
  width: 100%;
  table-layout: fixed; /* Ensure equal column width distribution */
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 8px;
  /* border: 1px solid #ddd; */
}
.table-container {
  padding: 20px;
  width: 100%;
}

.data-table {
  width: 100%;
  border-collapse: collapse; /* Ensure borders are collapsed */
  /* table-layout: auto; */
  table-layout: fixed;
}

.data-table th,
.data-table td {
  padding: 10px 15px; /* Add consistent padding */
  text-align: left; /* Align text to the left */
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.input-text {
  width: 150px;
  outline: none;
  transition: 0.2s;
  height: 44px;
  background-color: transparent;
  padding: 16px 10px 16px 10px;
  border-radius: 12px;
  box-sizing: border-box;
  border: 1px solid #979797;
}
.input-text::placeholder {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.filter-div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.next-button {
  width: 100px;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
}
.next-button:disabled {
  background-color: var(--state-grey);
  cursor: not-allowed;
  opacity: 0.2;
}
.base-button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 10px;
  font-size: 10px;

  border: 2px solid transparent;
  border-radius: 25px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, opacity 0.3s;
}

.base-button {
  background-color: var(--s-green-normal);
  color: var(--state-white);
}
.prev-button {
  width: 100px;
  background-color: var(--s-green-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
}
.prev-button:disabled {
  background-color: var(--state-grey);
  cursor: not-allowed;
  opacity: 0.2;
}
.page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-container {
  width: 100%;
  max-width: 250px;
  height: 60px;

  position: relative;
}

.select-container select {
  width: 150px;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: white;
  color: #333;
  cursor: pointer;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */
}

.select-container::after {
  content: ""; /* Custom arrow */
  font-size: 12px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #333;
}

.select-container select:focus {
  outline: none;
  border-color: #1a73e8; /* Focus border color */
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.select-container select option {
  padding: 10px;
  background-color: white;
  color: #333;
}
.all-filters-container {
  display: flex;
  justify-content: space-between;
}
.table-container {
  width: 100%;
}
.select-filter {
  display: flex;
  align-items: center;
  gap: 20px;
}
.the-td {
  position: relative;
}
.icon-dots {
  display: flex;
  padding-left: 12px;

  cursor: pointer;
}
.dropdown-menu {
  position: absolute;
  right: 60px; /* Align to the right edge of the icon-dots container */
  top: -30px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #0000001a;
  padding: 10px;
  transform: translateY(100%); /* Position below the icon-dots */
  z-index: 10;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  text-align: left;
}

.dropdown-menu .icon {
  margin-right: 8px;
}
</style>


