<template>
  <div class="login-layout-container">
    <ToastManagerVue ref="toastManager" />
    <div class="main">
      <header>
        <nav class="navbar">
          <img
            @click="redirectToHome"
            class="main-logo"
            src="@/assets/full_logo.svg"
            alt="Logo"
          />
          <div class="links">
            <!-- <router-link class="normal-links" to="/onboarding"
              >Apply To Care Jobs</router-link
            > -->
            <router-link class="normal-links" to="/login">Log In</router-link>
            <!-- <router-link class="join-now" to="/onboarding"
              >Get Care</router-link
            > -->
          </div>
        </nav>
      </header>
      <div>
        <div v-if="!showRegScreen" class="onboarding-container">
          <div style="display: flex; justify-content: center">
            <p class="onboarding-header-text">
              Let’s get started. Choose an option
            </p>
          </div>
          <div class="care-card">
            <div class="care-card-single">
              <div>
                <img :src="caregiverIcon" alt="Caregiver Icon" />
              </div>
              <div>
                <p class="care-card-single-header-text">I need a care giver</p>
                <p class="care-card-single-other-text">
                  Start your search for a care in your area
                </p>
              </div>
              <div><button @click="routeToCareseeker">Find care</button></div>
            </div>
            <div class="care-card-single">
              <div>
                <img :src="jobIcon" alt="Job Icon" />
              </div>
              <div>
                <p class="care-card-single-header-text">I want a care job</p>
                <p class="care-card-single-other-text">
                  Create a profile and search for jobs
                </p>
              </div>
              <div><button @click="routeToCaregiver">Find job</button></div>
            </div>
          </div>
        </div>
        <div v-if="showRegScreen" class="form-container">
          <h1 style="text-align: center;margin-top:20px">Create an account</h1>
           <h1 style="text-align: center;margin-top:20px; font-size: 20px" v-if="isCSInsurance">Please enter your own details, Your staff details would be entered on the next page</h1>
          <form @submit.prevent="handleSubmit">
            <div
              v-for="(field, index) in fields"
              :key="index"
              class="form-group"
              :class="{ 'has-value': field.value }"
            >
              <label :for="field.id" :class="{ active: field.value }">{{
                field.label
              }}</label>
              <div class="input-container">
                <input
                  :id="field.id"
                  :type="
                    field.id === 'password' || field.id === 'confirmPassword'
                      ? showPassword
                        ? 'text'
                        : 'password'
                      : field.type
                  "
                  :placeholder="field.placeholder"
                  v-model="field.value"
                  @input="updateFieldValue(field)"
                  :style="{ fontFamily: 'var(--font-family)' }"
                />
                <span
                  v-if="
                    field.id === 'password' || field.id === 'confirmPassword'
                  "
                  class="icon"
                  @click="togglePasswordVisibility"
                >
                  <svg
                    v-if="showPassword"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1344_748)">
                      <path
                        d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                        fill="#979797"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1344_748">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <svg
                    v-else
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1344_748)">
                      <path
                        d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                        fill="#979797"
                      />
                      <path d="M2 2L22 22" stroke="#979797" stroke-width="2" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1344_748">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
              <small v-if="field.subText">{{ field.subText }}</small>
              <small
                v-if="field.id === 'confirmPassword' && passwordsDoNotMatch"
                class="error"
              >
                Passwords do not match
              </small>
              <small
                v-if="field.id === 'date_of_birth' && !isValidDate(field.value)"
                class="error"
              >
                You must be at least 18 years old
              </small>
              <small
                v-if="field.id === 'phone' && !isValidPhoneNumber(field.value)"
                class="error"
              >
                Phone number must be at most 11 digits
              </small>
              <small
                v-if="field.id === 'email' && !isValidEmail(field.value)"
                class="error"
              >
                Email must be a valid email address
              </small>
            </div>
            <div class="form-group has-value">
              <label>Gender</label>
              <div class="gender-group">
                <button
                  type="button"
                  @click="setGender('M')"
                  :class="{ selected: selectedGender === 'M' }"
                >
                  Male
                </button>
                <button
                  type="button"
                  @click="setGender('F')"
                  :class="{ selected: selectedGender === 'F' }"
                >
                  Female
                </button>
              </div>
            </div>
            <p class="terms">
              By clicking “Join now” you agree to our
              <a href="#">terms of use</a> and
              <a href="#">privacy policy</a>
            </p>

            <BaseButton
              :label="isSubmitting ? 'Creating Account...' : 'Join Now'"
              :disabled="isSubmitting || isFormInvalid"
              backgroundColor="var(--s-pink-normal)"
              textColor="var(--state-white)"
              disabledBackgroundColor="#ECECEC"
              disabledColor="#ECECEC"
              disabledTextColor="#979797"
              height="60px"
              width="100%"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import Request from "@/utils/apiclient.js";
import { ref, computed, onMounted } from "vue";
import caregiverIcon from "@/assets/caregiver_icon.svg";
import jobIcon from "@/assets/job_icon.svg";
import BaseButton from "@/components/BaseButton.vue";
import ToastManagerVue from "../../components/ToastAlert/ToastManager.vue";
import { formatErrorMessages } from "@/utils/errorHandler";

export default {
  name: "OnboardingPage",
  components: {
    BaseButton,
    ToastManagerVue,
  },

  setup() {
    const isCSInsurance = sessionStorage.getItem("isCSInsurance");
    localStorage.clear();
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };

    const router = useRouter();
    const showRegScreen = ref(false);
    const caretype = ref("");
    const isSubmitting = ref(false);

    const routeToCaregiver = () => {
      showRegScreen.value = true;
      caretype.value = "Care giver";
    };
    const routeToCareseeker = () => {
      showRegScreen.value = true;
      caretype.value = "Care seeker";
    };

    const redirectToHome = () => {
      router.push("/");
    };

    const fields = ref([
      {
        id: "first_name",
        label: "First Name",
        type: "text",
        placeholder: "First name",
        value: "",
        subText: "",
      },
      {
        id: "last_name",
        label: "Last Name",
        type: "text",
        placeholder: "Last name",
        value: "",
        subText: "Families only see the first Initial e.g Charles Okocha is represented as Charles O.",
      },
      {
        id: "email",
        label: "Email Address",
        type: "email",
        placeholder: "yourmail@yahoo.com",
        value: "",
        subText: "",
      },
      {
        id: "phone",
        label: "Phone Number",
        type: "text",
        placeholder: "Phone Number",
        value: "",
        subText: "",
      },
      {
        id: "password",
        label: "Password",
        type: "password",
        placeholder: "Password",
        value: "",
        subText: "At least 8 characters",
      },
      {
        id: "confirmPassword",
        label: "Confirm Password",
        type: "password",
        placeholder: "Confirm password",
        value: "",
        subText: "",
      },
      {
        id: "date_of_birth",
        label: "Date of birth",
        type: "date",
        placeholder: "Enter date of birth",
        value: "",
        subText: "",
      },
    ]);

    const selectedGender = ref("");
    const showPassword = ref(false);

    const passwordsDoNotMatch = computed(() => {
      const passwordField = fields.value.find(
        (field) => field.id === "password"
      ).value;
      const confirmPasswordField = fields.value.find(
        (field) => field.id === "confirmPassword"
      ).value;
      return passwordField !== confirmPasswordField;
    });

    const isValidPhoneNumber = (number) => {
      return /^\d{1,11}$/.test(number);
    };

    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const isFormInvalid = computed(() => {
      return (
        fields.value.some(
          (field) =>
            !field.value ||
            (field.id === "password" && field.value.length < 8) ||
            (field.id === "phone" && !isValidPhoneNumber(field.value)) ||
            (field.id === "email" && !isValidEmail(field.value))
        ) ||
        !selectedGender.value ||
        passwordsDoNotMatch.value ||
        !isValidDate(
          fields.value.find((field) => field.id === "date_of_birth").value
        )
      );
    });

    // const updateFieldValue = (field) => {
    //   field.value = field.value.trim();
    // };
    const updateFieldValue = (field) => {
      if (field.id === "phone") {
        // Remove all spaces from the phone number
        field.value = field.value.replace(/\s+/g, "").trim();
      } else {
        field.value = field.value.trim();
      }
    };

    const setGender = (gender) => {
      selectedGender.value = gender;
    };

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    const isValidDate = (date) => {
      const today = new Date();
      const birthDate = new Date(date);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age >= 18;
    };

    const handleSubmit = () => {
      if (isSubmitting.value) return;
      isSubmitting.value = true;

      console.log("caretype", caretype.value);
      console.log("fields", fields.value);
      console.log("selected gender", selectedGender.value);

      const formData = {};

      fields.value.forEach((field) => {
        if (field.id !== "confirmPassword") {
          formData[field.id] = field.value;
        }
      });
      const email = fields.value.find((field) => field.id === "email").value;

      formData.type = caretype.value;

    

      if (isCSInsurance && isCSInsurance === "true") {
       
        formData.is_insurance = true;
      }else{
        formData.is_insurance = false;
      }

      //formData.type = "Admin";
      formData.gender = selectedGender.value;
      console.log("formData", formData);
      const request = new Request("/user/");
      const rawData = JSON.stringify(formData);
      console.log("raw data", rawData);
      request.post(rawData, (data, error) => {
        isSubmitting.value = false;
        if (error) {
          console.error("data error", error);
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("Success:", data);
          showToast(
            `Please verify your account through your email ${email}`,
            "success"
          );
          sessionStorage.removeItem("isCSInsurance")
          localStorage.clear()
          setTimeout(() => {
            router.push("/login");
          }, 3000);
        }
      });
    };
    onMounted(()=>{
      if (isCSInsurance && isCSInsurance == "true"){
        showRegScreen.value = true
        caretype.value = "Care seeker"
      }
    })
    return {
      redirectToHome,
      routeToCaregiver,
      routeToCareseeker,
      showRegScreen,
      caretype,
      fields,
      selectedGender,
      showPassword,
      isFormInvalid,
      passwordsDoNotMatch,
      setGender,
      togglePasswordVisibility,
      handleSubmit,
      updateFieldValue,
      isValidDate,
      isValidPhoneNumber,
      isValidEmail,
      toastManager,
      caregiverIcon,
      jobIcon,
      isSubmitting,
      isCSInsurance
    };
  },
};
</script>

<style scoped>
.links {
  display: flex;
  gap: 30px;
}

.links router-link:hover {
  background-color: #007bff;
  color: #fff;
}

.links router-link-active {
  font-weight: bold;
}
.onboarding-container {
  padding-top: 56px;
}
.care-card {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.care-card-single {
  padding: 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);
  width: 360px;
}
.login-layout-container {
  background-color: #fffbfc;
  min-height: 100vh;
}
.main {
  background-color: #fff8f9;
  min-height: 100vh;
  width: 100%;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.onboarding-header-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 60px;
}
.care-card-single button {
  background-color: var(--s-pink-normal);
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 40px;
  border: none;
  outline: none;
  color: white;
  margin-top: 10px;
  height: 48px;
  width: 100%;
  cursor: pointer;
}
.care-card-single button:hover {
  background-color: var(--s-pink-normal-hover);
}
.care-card-single div:first-child {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.care-card-single p {
  text-align: center;
}
.care-card-single-header-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #0a3828;
}
.care-card-single-other-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  color: #0a3828;
}

.login-container {
  max-width: 400px;
  margin: auto;
}

h2 {
  margin-bottom: 20px;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.5px;
  text-align: center;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-group input {
  width: 100%;
  padding: 16px 10px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent;
  box-sizing: border-box;
}
.input-group input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #979797;
}

.login-button {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}

.login-button:hover {
  background-color: var(--s-pink-normal-hover);
}

.forgot-password {
  display: block;
  margin-bottom: 34px;
  color: var(--s-green-normal);
  text-decoration: none;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password:hover {
  color: var(--s-green-normal-hover);
}

/* .google-login:hover,
.apple-login:hover {
  background-color: #f5f5f5;
} */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
}
.separator:not(:empty)::before {
  margin-right: 8px;
}
.separator:not(:empty)::after {
  margin-left: 8px;
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-form {
  padding-bottom: 200px;
}

.form-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.notification {
  background-color: #e6f7e6;
  color: #2c662d;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 40px;
}

h1 {
  color: #0a3828;

  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #000000;
  margin-bottom: 5px;
  transition: color 0.3s;
}

.form-group input {
  background-color: transparent;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  transition: border-color 0.3s;
  padding: 16px 24px 16px 24px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
}
.form-group input::placeholder {
  color: #979797;
}
.normal-links {
  color: var(--s-green-normal-active);
  text-decoration: none;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}
.form-group.has-value label.active,
.form-group.has-value input:focus {
  color: var(--s-green-normal);
}

.form-group.has-value input {
  border-color: var(--s-green-normal);
}

.form-group small {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: #979797;

  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-container {
  position: relative;
}

.input-container .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.gender-group {
  display: flex;
  gap: 10px;
}

.gender-group button {
  flex: 1;

  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  height: 48px;
}

.gender-group button.selected {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}

.terms {
  color: #000000;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;

  text-align: left;
  margin-bottom: 20px;
}

.terms a {
  color: #0063f7;
  text-decoration: none;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #17865f;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.error {
  color: red;
  font-size: 12px;
}
@media screen and (max-width: 800px) {
  .links {
    align-items: center;
    gap: 5px !important;
  }
  .links a {
    text-decoration: none;
    color: var(--s-green-dark);
    font-size: 16px;
  }
  .navbar {
    justify-content: space-between;
    padding: 32px;
  }
  .care-card {
    flex-direction: column;
    align-items: center;
  }
  .care-card-single {
    width: 313px;
  }
  .care-card-single-other-text {
    font-size: 12px;
  }
  .onboarding-header-text {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: 0.5px;
    text-align: center;
    max-width: 170px;
    max-width: 179px;
    margin-bottom: 16px;
  }
  .onboarding-container {
    padding-top: 32px;
  }
}
</style>
