
<template>
  <div class="availability-container">
    <ToastManagerVue ref="toastManager" />
    <h1>Which days are you available for work?</h1>
    <div style="days-container-main">
      <div class="days-container">
        <button
          v-for="day in days"
          :key="day.short"
          :class="['day-button', { selected: selectedDays.includes(day.full) }]"
          @click="toggleDay(day.full)"
        >
          {{ day.short }}
        </button>
      </div>
      <!-- <div v-if="showSpecificTime" class="specific-time-container">
        <label>Pick a specific Time</label>
        <div class="time-picker">
          <select v-model="startTime">
            <option v-for="time in timeOptions" :key="time" :value="time">
              {{ time }}
            </option>
          </select>
          <span>To</span>
          <select v-model="endTime">
            <option v-for="time in timeOptions" :key="time" :value="time">
              {{ time }}
            </option>
          </select>
        </div>
      </div>
      <a class="add-times" href="#" @click.prevent="toggleSpecificTime"
        >Add specific times</a
      >
      <p class="bottom-text">
        Don't worry, you can always adjust these times later
      </p> -->
      <div class="button-div">
        <BaseButton
          label="Next"
          :disabled="!canProceed"
          backgroundColor="var(--s-pink-normal)"
          textColor="var(--state-white)"
          disabledBackgroundColor="#ECECEC"
          disabledColor="#ECECEC"
          disabledTextColor="#979797"
          height="60px"
          width="100%"
          @click="handleNext"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { ref, computed, inject } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import { useRouter } from "vue-router";
import { formatErrorMessages } from "@/utils/errorHandler";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import Request from "@/utils/apiclient.js";

export default {
  name: "BuildProfileStepTwo",
  components: {
    BaseButton,
    ToastManagerVue,
  },
  setup() {
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };

    const formData = inject("formData");
    const updateFormData = inject("updateFormData");

    const localData = ref({
      ...formData.value.stepFiveData,
    });

    const days = [
      { short: "Sun", full: "Sunday" },
      { short: "Mon", full: "Monday" },
      { short: "Tue", full: "Tuesday" },
      { short: "Wed", full: "Wednesday" },
      { short: "Thu", full: "Thursday" },
      { short: "Fri", full: "Friday" },
      { short: "Sat", full: "Saturday" },
    ];

    const router = useRouter();
    const selectedDays = ref([]);

    const toggleDay = (day) => {
      if (selectedDays.value.includes(day)) {
        selectedDays.value = selectedDays.value.filter((d) => d !== day);
      } else {
        selectedDays.value.push(day);
      }
      localData.value.days = selectedDays.value;
    };

    const canProceed = computed(() => {
      return selectedDays.value.length > 0;
    });

    const handleNext = () => {
      updateFormData("stepFiveData", localData.value);
      console.log("formdata here", formData);
      console.log("Selected Days:", selectedDays.value);

      const payload = {
        days: selectedDays.value,
      };
      const rawData = JSON.stringify(payload);
      console.log("raw data", rawData);
      const request = new Request("/availability/");
      request.post(rawData, (data, error) => {
        if (error) {
          console.log("data error", error);
          if (error.status === "failure") {
            return showToast(error.message, "error");
          }
          const errorMessages = formatErrorMessages(error);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("Success:", data);
          // showToast("Availability Update Successful", "success");
          setTimeout(() => {
            router.push("/caregiver-onboarding/build-profile/step-3");
          }, 2000);
        }
      });
    };

    return {
      days,
      selectedDays,
      toggleDay,
      canProceed,
      handleNext,
      toastManager,
    };
  },
};
</script>


<style scoped>
/* Include the CSS adjustments mentioned above */
.availability-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.days-container,
.times-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.day-button,
.time-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.day-button.selected,
.time-button.selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal);
}

.specific-time-container {
  margin-bottom: 20px;
  margin-top: 29px;
}

.time-picker {
  display: flex;
  align-items: center;
  gap: 29px;
  margin-top: 10px;
  position: relative;
}

.time-picker select {
  padding: 16px 52px 16px 10px;

  border-radius: 12px;
  border: 1px solid #979797;
  background-color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  background: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1.70492L10.59 0.294922L6 4.87492L1.41 0.294921L-6.16331e-08 1.70492L6 7.70492L12 1.70492Z" fill="black"/></svg>')
    no-repeat right 26px center;
  background-size: 10px 6px;
}

.time-picker select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer */
}

.next-button {
  background-color: #ff6978;
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

.days-container button {
  padding: 10px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.specific-time-container label {
  text-align: left;
}

.add-times {
  color: #0063f7;
  text-decoration: none;
  text-align: left;
}
span {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}
.bottom-text {
  margin-top: 29px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}
.days-container-main {
  max-width: 400px;
}
@media screen and (max-width: 800px) {
  .care-card-container {
    width: 300px;
  }
  .selects-div {
    width: 300px;
    flex-direction: column;
  }

  .selects-div :deep(.custom-select-container) {
    width: 300px !important;
  }
  .selects-div :deep(.custom-select-trigger) {
    width: 300px !important;
  }
  .work-preference {
    justify-content: center;
  }

  .button-div {
    width: 300px;
    display: flex;
    justify-content: center;
  }
  .days-container-main {
    max-width: 300px;
  }
  .button-div{
    width: 100%
  }
  h1{
  
font-size: 20px;
font-weight: 700;
line-height: 31.2px;
letter-spacing: 0.5px;
text-align: left;

}
}
</style>
