<template>
  <DashboardLayout>
        <ToastManagerVue ref="toastManager" />
    <LoadingSpinner :isLoading="isLoading" />
    <div class="overall-container">
      <div class="main-container">
        <div class="content">
          <div class="jobs-div">
            <h2>Staff</h2>
          </div>
          <div class="content-main">
            <div class="tabs">
              <div
                class="single-tab"
                :class="{ active: currentTab === 'Current' }"
                @click="setTab('Current')"
              >
                <p>Staff List</p>
              </div>
            </div>
            <div class="staff-container">
              <div
                v-for="(staff, index) in careSeekerStaff"
                :key="index"
                class="individual-staff"
              >
                <h4>Staff {{ index + 1 }}</h4>
                <div>First Name: {{ staff.first_name }}</div>
                <div>Last Name: {{ staff.last_name }}</div>
                <div>Phone: {{ staff.phone }}</div>
                <div>Salary: N{{ staff.salary }}</div>

                <h4>Virtual Bank Details:</h4>
                <p>Bank: {{ staff.bank_account?.vba_bank }}</p>
                <p>Name: {{ staff.bank_account?.vba_name }}</p>
                <p>Dedicated account number: {{ staff.bank_account?.vba_number }}</p>
              </div>
            </div>
            <div style="display: flex; justify-content: center">
              <BaseButton
                label="Add New Staff"
                backgroundColor="var(--s-pink-normal)"
                textColor="var(--state-white)"
                width="50%"
                @click="handleAddStaff"
              />
            </div>

            <!-- Check if no jobs for the current tab -->
            <div
              v-if="careSeekerStaff.length == 0"
              class="no-archived-container"
            >
              <div class="no-archived">
                <svg
                  width="125"
                  height="107"
                  viewBox="0 0 125 107"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M84.6783 4.47897C84.6783 4.47897 67.5568 26.1989 40.1618 13.2436C12.7668 0.288249 -14.6278 35.3442 8.96193 56.6832C32.5516 78.0222 9.46734 89.7478 25.4935 102.826C43.7082 117.69 59.5663 87.5485 74.0246 94.4075C105.935 109.545 129.575 103.553 124.248 86.0243C114.898 55.2554 102.941 53.2537 112.073 37.2495C121.205 21.2453 101.051 -12.0315 84.6783 4.47897Z"
                    fill="#FCE0E5"
                  />
                  <path
                    d="M94.2 25.5789H90.2895C85.9875 25.5789 82.5 22.0915 82.5 17.7895C82.5 13.4663 79.029 10 74.7 10H51.3C46.971 10 43.5 13.4663 43.5 17.7895C43.5 22.0915 40.0125 25.5789 35.7105 25.5789H31.8C27.471 25.5789 24 29.0453 24 33.3684V76.2105C24 80.5337 27.471 84 31.8 84H94.2C98.529 84 102 80.5337 102 76.2105V33.3684C102 29.0453 98.529 25.5789 94.2 25.5789ZM51.3 21.6842C51.3 19.5332 53.0437 17.7895 55.1947 17.7895H70.8053C72.9563 17.7895 74.7 19.5332 74.7 21.6842C74.7 23.8352 72.9563 25.5789 70.8053 25.5789H55.1947C53.0437 25.5789 51.3 23.8352 51.3 21.6842ZM94.2 72.3158C94.2 74.4668 92.4563 76.2105 90.3053 76.2105H35.6947C33.5437 76.2105 31.8 74.4668 31.8 72.3158C31.8 70.1648 33.5437 68.4211 35.6947 68.4211H90.3053C92.4563 68.4211 94.2 70.1648 94.2 72.3158ZM94.2 46.7368C94.2 52.2597 89.7229 56.7368 84.2 56.7368H41.8C36.2771 56.7368 31.8 52.2597 31.8 46.7368V39.2184C31.8 35.9876 34.4191 33.3684 37.65 33.3684H39.6053C41.7563 33.3684 43.5 35.1122 43.5 37.2632C43.5 39.4142 45.249 41.1579 47.4 41.1579C49.551 41.1579 51.3 39.4142 51.3 37.2632C51.3 35.1122 53.0437 33.3684 55.1947 33.3684H70.8053C72.9563 33.3684 74.7 35.1122 74.7 37.2632C74.7 39.4142 76.449 41.1579 78.6 41.1579C80.751 41.1579 82.5 39.4142 82.5 37.2632C82.5 35.1122 84.2437 33.3684 86.3947 33.3684H88.35C91.5809 33.3684 94.2 35.9876 94.2 39.2184V46.7368Z"
                    fill="#17865F"
                  />
                </svg>
                <p>You have no staff listed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GenericModal
      :isVisible="isMainModalVisible"
      title="Add New Staff"
      @close="hideModal"
      @confirm="handleConfirm"
    >
      <div class="availability-container">
        <div style="max-width: 400px">
          <form @submit.prevent="handleSubmit">
            <!-- Name Input -->
            <div class="form-group has-value">
              <label for="name">First Name</label>
              <div class="input-container">
                <input
                  id="name"
                  type="text"
                  placeholder="Enter first name"
                  v-model="formData.first_name"
                  @input="updateFieldValue('first_name')"
                />
              </div>
            </div>
            <div class="form-group has-value">
              <label for="name"> Last Name</label>
              <div class="input-container">
                <input
                  id="name"
                  type="text"
                  placeholder="Enter Last name"
                  v-model="formData.last_name"
                  @input="updateFieldValue('last_name')"
                />
              </div>
            </div>
            <div class="form-group has-value">
              <label for="name"> Email</label>
              <div class="input-container">
                <input
                  id="name"
                  type="email"
                  placeholder="Enter email"
                  v-model="formData.email"
                  @input="updateFieldValue('email')"
                />
              </div>
            </div>
            <!-- Staff Type Buttons -->
            <div class="form-group has-value">
              <label>Staff Type</label>
              <div class="staff-type-group">
                <button
                  type="button"
                  @click="setStaffType('Housekeeper')"
                  :class="{
                    selectedStaff: formData.staffType === 'Housekeeper',
                  }"
                >
                  Housekeeper
                </button>
                <button
                  type="button"
                  @click="setStaffType('Nanny')"
                  :class="{ selectedStaff: formData.staffType === 'Nanny' }"
                >
                  Nanny
                </button>
                <button
                  type="button"
                  @click="setStaffType('Care giver')"
                  :class="{
                    selectedStaff: formData.staffType === 'Care giver',
                  }"
                >
                  Caregiver
                </button>
              </div>
            </div>

            <!-- Phone Number Input -->
            <div class="form-group has-value">
              <label for="phone">Phone Number</label>
              <div class="input-container">
                <input
                  id="phone"
                  type="text"
                  maxlength="11"
                  placeholder="Enter phone number"
                  v-model="formData.phone"
                  @input="updateFieldValue('phone')"
                />
              </div>
            </div>

            <!-- BVN Input -->
            <div class="form-group has-value">
              <label for="bvn">BVN</label>
              <div class="input-container">
                <input
                  id="bvn"
                  type="text"
                   maxlength="11"
                  placeholder="Enter BVN"
                  v-model="formData.bvn"
                  @input="updateFieldValue('bvn')"
                />
              </div>
            </div>

            <!-- Bank Account Number Input -->
            <div class="form-group has-value">
              <label for="bank_account">Regular Bank Account Number</label>
              <div class="input-container">
                <input
                  id="bank_account"
                  type="text"
                  placeholder="Enter bank account number"
                  v-model="formData.bankAccount"
                  @input="updateFieldValue('bankAccount')"
                />
              </div>
            </div>
            <label for="bank_account">Select Bank</label>
            <div
              style="margin-bottom: 30px; margin-top: 10px"
              :class="['custom-multiselect']"
            >
              <Multiselect
                id="Bank"
                label="text"
                v-model="selectedBank"
                :options="banksOptions"
                track-by="text"
                :searchable="true"
                placeholder="Banks"
                class="custom-multiselect"
              >
                <template #caret>
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                      fill="#979797"
                    />
                  </svg> </template
              ></Multiselect>
            </div>
            <!-- Bank Account Number Input -->
            <div class="form-group has-value">
              <label for="bank_account">Caregiver Salary</label>
              <div class="input-container two-rows">
                <input
                  id="salary"
                  type="text"
                  placeholder="Enter salary"
                  v-model="formData.salary"
                  @input="updateFieldValue('salary')"
                />
                <span>5% is added as our fee</span>
              </div>
            </div>

            <!-- Gender Buttons -->
            <div class="form-group has-value">
              <label>Gender</label>
              <div class="gender-group">
                <button
                  type="button"
                  @click="setGender('M')"
                  :class="{ selected: formData.gender === 'M' }"
                >
                  Male
                </button>
                <button
                  type="button"
                  @click="setGender('F')"
                  :class="{ selected: formData.gender === 'F' }"
                >
                  Female
                </button>
              </div>
            </div>

            <!-- Submit Button -->
            <BaseButton
              :label="isSubmitting ? 'Updating...' : 'Update'"
              :disabled="isSubmitting || isFormInvalid"
              backgroundColor="var(--s-pink-normal)"
              textColor="var(--state-white)"
              disabledBackgroundColor="#ECECEC"
              disabledColor="#ECECEC"
              disabledTextColor="#979797"
              height="60px"
              width="100%"
              @click="handleCSHIDashboard"
            />
          </form>
        </div>
      </div>
    </GenericModal>
  </DashboardLayout>
</template>



<script>
import { ref, onMounted, computed } from "vue";
import GenericModal from "@/components/GenericModal.vue";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
import Request from "@/utils/apiclient.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import BaseButton from "@/components/BaseButton.vue";
import { formatErrorMessages } from "@/utils/errorHandler";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import Multiselect from "@vueform/multiselect";
export default {
  name: "CareSeekerStaff",
  components: { DashboardLayout, LoadingSpinner, BaseButton, GenericModal, Multiselect, ToastManagerVue },
  setup() {
    const isMainModalVisible = ref(false);
    const isLoading = ref(true);
    const currentTab = ref("Current");
    const careSeekerStaff = ref([]);
    const handleAddStaff = () => {
      console.log("staff added");
      showModal();
    };
    const setTab = (tab) => {
      currentTab.value = tab;
    };

    // Fetch hired jobs
    const fetchStaff = () => {
      const request = new Request("/staff/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("staff here", res);
          careSeekerStaff.value = [...res.results];
        }
      });
    };

    const showModal = () => {
      isMainModalVisible.value = true;
    };

    const hideModal = () => {
      isMainModalVisible.value = false;
    };
    onMounted(() => {
      fetchStaff();
    });
    const handleConfirm = () => {
      hideModal();
    };

    // add staff data here

    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };

    const isSubmitting = ref(false);
    const formData = ref({
      first_name: "",
      last_name: "",
      staffType: "",
      phone: "",
      bvn: "",
      bankAccount: "",
      gender: "",
      salary: "",
      email: "",
    });

    const updateFieldValue = (field) => {
    //   formData.value[field] = formData.value[field].trim();
          if (['phone', 'bvn', 'salary'].includes(field)) {
    formData.value[field] = formData.value[field].replace(/\D/g, ''); // This replaces any non-digit character with an empty string
  } else {
    formData.value[field] = formData.value[field].trim();
  }
    };

    const setGender = (gender) => {
      formData.value.gender = gender;
    };

    const setStaffType = (type) => {
      formData.value.staffType = type;
    };

    const handleSubmit = () => {
      if (isSubmitting.value) return;
      isSubmitting.value = true;

      console.log("Form Data", formData.value);
      // Post form data to the backend (implement actual logic)
    };
    const banksOptions = ref([]);
    const selectedBank = ref(null);
    const fetchBanks = () => {
      const request = new Request(`/banks/`);
      // const request = new Request('/user/')
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here is banks data", res);
          banksOptions.value = res.map((bank) => ({
            text: bank.name, // Customize according to your API response structure
            value: bank.id, // Customize according to your API response structure
            // id: bank.id
          }));
          console.log("banksOptions", banksOptions.value);
        }
      });
    };
    const isFormInvalid = computed(() => {
      return (
        !formData.value.first_name ||
        !formData.value.last_name ||
        !formData.value.staffType ||
        !formData.value.phone ||
        !formData.value.bvn ||
        !formData.value.bankAccount ||
        !selectedBank.value ||
        !formData.value.gender ||
        !formData.value.salary ||
        !formData.value.email
      );
    });
    const handleCSHIDashboard = () => {
      if (isSubmitting.value) return;
      isSubmitting.value = true;
isLoading.value = true;
      const payload = {
        bvn: parseInt(formData.value.bvn),
        bank_account: {
          account_number: formData.value.bankAccount,
          business_name: "",
          bank: selectedBank.value,
        },
        first_name: formData.value.first_name,
        last_name: formData.value.last_name,
        email: formData.value.email,
        staff_type: formData.value.staffType,
        gender: formData.value.gender,
        phone: formData.value.phone,
        salary: formData.value.salary,
      };

      console.log("full form data 7", payload);
      const rawData = JSON.stringify(payload);
      const request = new Request("/staff/");
      request.post(rawData, (data, error) => {
         isLoading.value = false;
        isSubmitting.value = false;
        if (error) {
          console.log("data error", error);
          if (error.status == "failure") {
            return showToast(error.message, "error");
          }
          if (error.detail) {
            return showToast("An error occurred", "error");
          }
          const errorMessages = formatErrorMessages(error);
          console.log("error", errorMessages);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
           
          console.log("data success here", data);
          showToast("Staff added Successfully", "success");
          hideModal();
            fetchStaff();
        }
      });
    };

    onMounted(() => {
      fetchBanks();
    });

    // add staff end
    return {
      setTab,
      currentTab,
      careSeekerStaff,
      isLoading,
      handleAddStaff,
      showModal,
      hideModal,
      isMainModalVisible,
      handleConfirm,
      isFormInvalid,
      formData,
      isSubmitting,
      setGender,
      setStaffType,
      updateFieldValue,
      handleSubmit,

      handleCSHIDashboard,
      toastManager,
      fetchBanks,

      selectedBank,
      banksOptions,
    };
  },
};
</script>

<style scoped>
.overall-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.staff-container {
  padding: 30px;
}
.individual-staff {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid var(--s-green-light-active);
}
.main-container {
  display: flex;
  padding: 32px 0px 500px 28px;
}
.content {
  width: 967px;
}
.jobs-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 42px;
}
.tabs p {
  color: #0a3828;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  font-weight: var(--font-weight-regular);
}
.single-tab {
  padding: 0px 20.5px 4px 20.5px;
  cursor: pointer;
}
.active {
  border-bottom: 3px solid var(--s-pink-normal);
}
.active p {
  color: var(--s-pink-normal) !important;
  font-weight: var(--font-weight-bold) !important;
}
.jobs-list {
  padding-top: 16px;
}
.jobs-list-item {
  margin-bottom: 16px;
  border: 1px solid var(--s-green-light-active);
  border-radius: 12px;
  padding: 20px 16px;
}
.jobs-list-item-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.jobs-list-item-first-div div:first-child {
  display: flex;
  flex-direction: column;
}
h4 {
  margin: 0px 0px 4px 0px;
}
.view-edit {
  color: var(--s-green-normal-active);

  text-decoration: none;
}
.main-text {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: justified;
}
.sub-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
h2 {
  margin: 0px;

  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
  letter-spacing: 0.5px;
  text-align: center;
  display: flex;
  align-items: center;
}
.no-archived-container {
  display: flex;
  justify-content: center;
  padding-top: 49px;
}
.no-archived {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 575px;
  background-color: #fff;
  padding-top: 59px;
  padding-bottom: 59px;
  flex-direction: column;
}
.no-archived svg {
  margin-bottom: 10px;
}
.no-archived p {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: center;
}
span {
  margin-top: 5px;
  font-size: 14px;
}
.create-account-small {
  display: none;
}
.onboarding-container {
  padding-top: 56px;
}
.care-card {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.care-card-single {
  padding: 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);

  width: 360px;
}
.login-layout-container {
  background-color: #fffbfc;
  min-height: 100vh;
}
.main {
  background-color: #fff8f9;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 200px;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.onboarding-header-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 60px;
}
.stepper {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}
.progress-bar {
  width: 100%;
  background-color: #979797;
  height: 5px;
}
.progress {
  background-color: #17865f;
  height: 5px;
  transition: width 0.3s;
}
.create-account {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.create-account p {
  color: #116547;
}
.create-account p:first-child {
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-right: 8px;
}
.links {
  display: flex;
  gap: 30px;
}

.links router-link:hover {
  background-color: #007bff;
  color: #fff;
}

.links router-link-active {
  font-weight: bold;
}
.onboarding-container {
  padding-top: 56px;
}
.care-card {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.care-card-single {
  padding: 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);
  width: 360px;
}
.login-layout-container {
  background-color: #fffbfc;
  min-height: 100vh;
}
.main {
  background-color: #fff8f9;
  min-height: 100vh;
  width: 100%;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.onboarding-header-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 60px;
}
.care-card-single button {
  background-color: var(--s-pink-normal);
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 40px;
  border: none;
  outline: none;
  color: white;
  margin-top: 10px;
  height: 48px;
  width: 100%;
  cursor: pointer;
}
.care-card-single button:hover {
  background-color: var(--s-pink-normal-hover);
}
.care-card-single div:first-child {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.care-card-single p {
  text-align: center;
}
.care-card-single-header-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #0a3828;
}
.care-card-single-other-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  color: #0a3828;
}

.login-container {
  max-width: 400px;
  margin: auto;
}

h2 {
  margin-bottom: 20px;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.5px;
  text-align: center;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-group input {
  width: 100%;
  padding: 16px 10px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent;
  box-sizing: border-box;
}
.input-group input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #979797;
}

.login-button {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}

.login-button:hover {
  background-color: var(--s-pink-normal-hover);
}

.forgot-password {
  display: block;
  margin-bottom: 34px;
  color: var(--s-green-normal);
  text-decoration: none;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password:hover {
  color: var(--s-green-normal-hover);
}

/* .google-login:hover,
.apple-login:hover {
  background-color: #f5f5f5;
} */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
}
.separator:not(:empty)::before {
  margin-right: 8px;
}
.separator:not(:empty)::after {
  margin-left: 8px;
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-form {
  padding-bottom: 200px;
}

.form-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.notification {
  background-color: #e6f7e6;
  color: #2c662d;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 40px;
}

h1 {
  color: #0a3828;

  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #000000;
  margin-bottom: 5px;
  transition: color 0.3s;
}

.form-group input {
  background-color: transparent;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  transition: border-color 0.3s;
  padding: 16px 24px 16px 24px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
}
.form-group input::placeholder {
  color: #979797;
}
.normal-links {
  color: var(--s-green-normal-active);
  text-decoration: none;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}
.form-group.has-value label.active,
.form-group.has-value input:focus {
  color: var(--s-green-normal);
}

.form-group.has-value input {
  border-color: var(--s-green-normal);
}

.form-group small {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: #979797;

  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-container {
  position: relative;
}

.input-container .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.two-rows {
  display: flex;
  flex-direction: column;
}
.gender-group {
  display: flex;
  gap: 10px;
}

.gender-group button {
  flex: 1;

  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  height: 48px;
}

.gender-group button.selected {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
.staff-type-group {
  display: flex;
  gap: 10px;
}
.staff-type-group button {
  flex: 1;

  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  height: 48px;
}
.staff-type-group button.selectedStaff {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}

.terms {
  color: #000000;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;

  text-align: left;
  margin-bottom: 20px;
}

.terms a {
  color: #0063f7;
  text-decoration: none;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #17865f;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.error {
  color: red;
  font-size: 12px;
}
.custom-multiselect {
  position: relative;
  width: 100%;
}
.multiselect-option.is-selected {
  background: var(--s-green-normal-active);
  color: #000;
}
.custom-multiselect .multiselect {
  z-index: 11;
  width: 100%;
  padding: 10px;
  height: 60px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}

.custom-multiselect .multiselect__content {
  background-color: transparent; /* White background for options */
}
.multiselect {
  background: transparent;
}
.custom-multiselect-selected .multiselect {
  border: 1px solid var(--s-green-normal-active); /* Change to desired color when a value is selected */
}
@media screen and (max-width: 800px) {
  .links {
    align-items: center;
    gap: 5px !important;
  }
  .links a {
    text-decoration: none;
    color: var(--s-green-dark);
    font-size: 16px;
  }
  .navbar {
    justify-content: space-between;
    padding: 32px;
  }
  .care-card {
    flex-direction: column;
    align-items: center;
  }
  .care-card-single {
    width: 313px;
  }
  .care-card-single-other-text {
    font-size: 12px;
  }
  .onboarding-header-text {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: 0.5px;
    text-align: center;
    max-width: 170px;
    max-width: 179px;
    margin-bottom: 16px;
  }
  .onboarding-container {
    padding-top: 32px;
  }
  .content {
  width: 350.%;
}
}
@media screen and (max-width: 800px) {
  .create-account {
    display: none;
  }
  .navbar {
    padding: 20px;
    justify-content: space-between;
  }
  /* .main-logo{
    width: 100px;
    height: 70px
  } */
  .main-logo {
    width: 140px;
    height: 50px;
    cursor: pointer;
  }
  .create-account-small {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-top: 40px;
  }
  .create-account-small p {
    color: #979797;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 27.2px;
    letter-spacing: 0.5px;
    text-align: left;
  }
}
</style>
