export const isAuthenticated = () => {
    
    return !!localStorage.getItem('_user');
  };
  export const isRefreshToken = () => {
   
    return !!localStorage.getItem('_userRefresh');
  };
  export const getUserRole = () => {
    // Retrieve user profile from local storage
    const userProfile = localStorage.getItem('_userProfile');
    
    // Check if userProfile exists
    if (userProfile) {
      try {
        // Parse the JSON string into an object
        const parsedProfile = JSON.parse(userProfile);
        
        // Log the parsed profile to the console for debugging
        console.log("Parsed user profile:", parsedProfile);
        
        // Return the role type
        return parsedProfile.type;
      } catch (e) {
        console.error("Error parsing user profile:", e);
        return null;
      }
    } else {
      // Handle the case where userProfile is not found in local storage
      console.log("No user profile found in local storage.");
      return null; // or return a default role or handle it as needed
    }
  };
  