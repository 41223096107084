<template>
  <LoginLayout>
    <!-- Add your login form here -->
    <div class="login-form">
      <ToastManagerVue ref="toastManager" />
      <!-- Your login form content -->

      <div class="login-container">
        <h2>Login</h2>
        <form @submit.prevent="handleLogin">
          <div class="input-group">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              v-model="email"
              placeholder="Enter Email address"
              required
            />
          </div>
          <div class="input-group">
            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              v-model="password"
              placeholder="Enter Password"
              required
            />
          </div>
          <button type="submit" class="login-button" :disabled="isLoggingIn">
            {{ isLoggingIn ? "Logging in..." : "Login" }}
          </button>
          <router-link to="/forgot-password" class="forgot-password"
            >Forgot Password?</router-link
          >
          <!-- <router-link to="/forgot-password" class="forgot-password"
            >Forgot Password?</router-link
          >
          <div class="separator">or</div>
          <button type="button" class="google-login">
            <img src="@/assets/google_logo.svg" alt="Google Logo" /> Continue
            with Google
          </button>
          <button type="button" class="apple-login">
            <img src="@/assets/apple_logo.svg" alt="Apple Logo" /> Continue with
            Apple
          </button> -->
        </form>
      </div>
    </div>
  </LoginLayout>
</template>

<script>
import { useRouter } from "vue-router";
import LoginLayout from "@/layouts/LoginLayout.vue";
import { ref } from "vue";
import Request from "@/utils/apiclient.js";
import { formatErrorMessages } from "@/utils/errorHandler";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";

export default {
  name: "LoginPage",
  components: {
    LoginLayout,
    ToastManagerVue,
  },

  setup() {
      sessionStorage.clear();
    localStorage.clear();
    const router = useRouter();
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const email = ref("");
    const password = ref("");
    const isLoggingIn = ref(false);

    const handleLogin = async () => {
      if (isLoggingIn.value) return;
      isLoggingIn.value = true;

      // Handle login logic here
      const formData = {
        email: email.value,
        password: password.value,
      };
      const request = new Request("/auth/login/");
      const rawData = JSON.stringify(formData);
      console.log("raw data", rawData);
      request.post(rawData, (data, error) => {
        isLoggingIn.value = false;
        if (error) {
          console.log("data error", error);
          if (error.status == "failure") {
            return showToast(error.message, "error");
          }
          if (error.detail) {
            return showToast("Incorrect Email or Password", "error");
          }
          const errorMessages = formatErrorMessages(error);
          console.log("error", errorMessages);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("Success data here:", data);

          localStorage.setItem("_user", data.data.tokens.access);
          localStorage.setItem("_userRefresh", data.data.tokens.refresh);
          localStorage.setItem("_userProfile", JSON.stringify(data.data.user));
          //  showToast(data.message, "success");
          showToast("Login Successful", "success");
          // router.push("/login");
          setTimeout(() => {
            if (data.data.user.type == "Care seeker") {
              //  router.push("/careseeker/home");

              const { user } = data.data;
              const { has_onboarded, care_seeker, is_insurance } = user;
              if (is_insurance) {
                if (care_seeker?.staffs.length > 0) {
                  return router.push("/careseeker");
                } else {
                    return router.push("/careseeker/onboardingcshi");
                }
              }

              if (has_onboarded) {
                return router.push("/careseeker");
              }

              if (!has_onboarded) {
                if (!care_seeker || Object.keys(care_seeker).length === 0) {
                  return router.push("/careseeker-onboarding");
                }

                const onboardingRoutes = {
                  Onboarding: "careseeker-onboarding/job-post/step-1",
                };

                const route = onboardingRoutes[care_seeker.onboarding_status];
                if (route) {
                  return router.push(route);
                }
              }
            } else if (data.data.user.type == "Care giver") {
              const { user } = data.data;
              const { has_onboarded, care_giver } = user;

              if (has_onboarded) {
                return router.push("/caregiver");
              }

              if (!has_onboarded) {
                if (!care_giver || Object.keys(care_giver).length === 0) {
                  return router.push("/caregiver-onboarding");
                }

                const onboardingRoutes = {
                  Onboarding: "/caregiver-onboarding/build-profile/step-2",
                  Availability: "/caregiver-onboarding/build-profile/step-3",
                  Profile: "/caregiver-onboarding/complete-verification/step-2",
                  Verification:
                    "/caregiver-onboarding/complete-verification/step-2",
                };

                const route = onboardingRoutes[care_giver.onboarding_status];
                if (route) {
                  return router.push(route);
                }
              }
            } else if (data.data.user.type == "Admin") {
              router.push("/admin");
            }
          }, 100);
        }
      });
    };

    return {
      email,
      password,
      handleLogin,
      toastManager,
      isLoggingIn,
    };
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: auto;
 
}

h2 {
  margin-bottom: 20px;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.5px;
  text-align: center;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-group input {
  width: 100%;
  padding: 16px 10px;
  border: 1px solid #979797;
  border-radius: 12px;
  height: 60px;
  background-color: transparent;
  box-sizing: border-box;
}
.input-group input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #979797;
}

.login-button {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  height: 60px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}

.login-button:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-button:disabled {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
  cursor: not-allowed;
  opacity: 0.6;
}

.forgot-password {
  display: block;
  margin-top: 10px;
  margin-bottom: 34px;
  color: var(--s-green-normal);
  text-decoration: none;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password:hover {
  color: var(--s-green-normal-hover);
}

.google-login,
.apple-login {
  width: 100%;
  padding: 10px 67px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  gap: 10px;
  font-size: var(--font-body-large-regular-size);
  font-weight: var(--font-weight-regular);
  line-height: 34px;
  font-family: var(--font-family);
  white-space: nowrap;
  letter-spacing: 0.5px;
  text-align: left;
  background-color: transparent;
}

.google-login img,
.apple-login img {
  width: 40.67px;
  height: 40.67px;
}

/* .google-login:hover,
.apple-login:hover {
  background-color: #f5f5f5;
} */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
}
.separator:not(:empty)::before {
  margin-right: 8px;
}
.separator:not(:empty)::after {
  margin-left: 8px;
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-form {
  padding-bottom: 200px;
}
</style>
