<template>
  <div :class="['message-item', senderTypeClass]">
    <div class="message-content">
      <div class="message-header">
        <Avatar :initials="initials" />
        <!-- Avatar :src="avatarSrc" :initials="initials" /> -->
        <span class="reply-name">{{ name }}</span>
        <span class="reply-date">{{ time }}</span>
      </div>
      <div class="message-body">
        <p>{{ message }}</p>
      </div>

      <div v-if="reply" class="message-body-reply">
        <div class="reply">
          <div class="reply-content">
            <div class="reply-content-avatar-date">
              <Avatar  :initials="reply.initials" width="28px" height="28px" fontSize="10px" />
              <span class="reply-name">{{ reply.name }}</span>
              <span class="reply-date">{{ reply.date }}</span>
            </div>
            <p>{{ reply.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/AvatarComponent.vue";

export default {
  name: "MessageItem",
  components: {
    Avatar,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    avatarSrc: {
      type: String,
      default: "",
    },
    initials: {
      type: String,
      default: "AV",
    },
    reply: {
      type: Object,
      default: null,
    },
    senderType: {
      type: String,
      required: true,
    },
  },
  computed: {
    senderTypeClass() {
      return this.senderType === 'Care seeker' ? 'align-right' : 'align-left';
    },
  },
};
</script>

<style scoped>
.message-item {
  display: flex;
  margin-bottom: 10px;
  box-sizing: border-box;
  gap: 8px;
}

.message-content {
  border-radius: 5px;
  box-sizing: border-box;
 
}

.message-header {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  gap: 8px;
  margin-bottom: 14px;
}

.name {
  font-weight: bold;
}

.date {
  color: #888;
  font-size: 12px;
}

.message-body {
  color: #555;
  margin-bottom: 24px;
}

.reply {
  display: flex;
  margin-top: 10px;
}

.reply-content {
  margin-left: 10px;
  padding: 5px;
  border-radius: 5px;
}

.message-body-reply {
  display: flex;
  margin-top: 22px;
  margin-bottom: 22px;
}

.reply-content-avatar-date {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  margin-bottom: 14px;
}

.reply-content {
  display: flex;
  flex-direction: column;
}

.reply-name {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}

.reply-date {
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}

/* Alignment for care seeker messages */
.align-left {
  justify-content: flex-start;
  width: 100%;
  display: flex;
}

/* Alignment for caregiver messages */
.align-right {
  justify-content: flex-end;
    width: 100%;
    display: flex;
}
</style>
