<template>
  <div class="caregiver-container">
    <LoadingSpinner :isLoading="isLoading" />
    <div style="margin-bottom: 32px">
      <h1>
        What type of caregiver
        <!-- <span>{{ careImage ?? "" }}</span>  -->
        are you interested in?
      </h1>
    </div>
    <div class="selects-div">
      <!-- <img src="@/assets/academic care.png" alt="Senior Care" /> -->
      <img
        v-if="careImage == 'Tutor'"
        src="@/assets/academic care.png"
        alt="Tutor"
      />
      <img
        v-else-if="careImage == 'Elderly care'"
        src="@/assets/senior care.png"
        alt="Elderly care'"
      />
      <img
        v-else-if="careImage == 'Child care'"
        src="@/assets/child care.png"
        alt="Child Care"
      />
      <img
        v-else-if="careImage == 'Housekeeper'"
        src="@/assets/home care.png"
        alt="Housekeeper"
      />
      <img v-else src="@/assets/academic care.png" alt="Tutor" />
      <!-- <img
        :src="require(`@/assets/${formData.stepOneData.type_of_care || careImage}.png`)"
        alt="Senior Care"
      /> -->
    </div>
    <div class="work-preference">
      <p>Work preference.</p>
    </div>
    <div
      v-if="formData.stepFourData.type_of_care === 'Tutor'"
      class="care-card-container"
    >
      <div
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Live In' }"
        @click="setWork('Live In')"
      >
        <p>Live In</p>
      </div>
      <div
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Live Out' }"
        @click="setWork('Live Out')"
      >
        <p>Live Out</p>
      </div>
      <div
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Both' }"
        @click="setWork('Both')"
      >
        <p>Both</p>
      </div>
    </div>
    <div v-else class="care-card-container">
      <div
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Live In' }"
        @click="setWork('Live In')"
      >
        <p>Live In</p>
      </div>
      <div
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Live Out' }"
        @click="setWork('Live Out')"
      >
        <p>Live Out</p>
      </div>
         <div
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Both' }"
        @click="setWork('Both')"
      >
        <p>Both</p>
      </div>
      <!-- <div
        v-if="formData.stepFourData.type_of_care === 'Child care'"
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Either' }"
        @click="setWork('Either')"
      >
        <p>Either</p>
      </div>
      <div
        v-else
        class="care-card"
        :class="{ 'care-card-selected': selectedWork === 'Either' }"
        @click="setWork('Either')"
      >
        <p>I am not sure yet</p>
      </div> -->
    </div>

    <div>
      <BaseButton
        label="Next"
        :disabled="disabled"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="400px"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import { ref, inject, watch, onMounted } from "vue";
import Request from "@/utils/apiclient.js";
import BaseButton from "@/components/BaseButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { useRouter } from "vue-router";
export default {
  name: "CareSeekerCreateJobStepOne",
  components: {
    BaseButton,
    LoadingSpinner,
  },

  setup() {
    const isLoading = ref(true);
    const userProfile = JSON.parse(localStorage.getItem("_userProfile"));
    const formData = inject("onboardingCareSeekerformData");
    const updateFormData = inject("onboardingUpdateCareSeekerformData");
    const router = useRouter();
    const localData = ref({
      ...formData.value.stepFourData,
    });

    const careImage = ref("");
    const selectedWork = ref("");
    const disabled = ref(true);

    const setWork = (work) => {
      selectedWork.value = work;
      localData.value.preference = work;
    };

    const handleClick = () => {
      updateFormData("stepFourData", localData.value);
      console.log("formdata here", formData);
      router.push("/careseeker-onboarding/job-post/step-2");
    };

    watch(
      selectedWork,
      (newSelectedWork) => {
        disabled.value = !newSelectedWork;
      },
      { immediate: true }
    );

    const fetchCareseeker = () => {
      const request = new Request(
        `/profile/${userProfile.id}/`
      );
      request.get((res, err) => {
        if (err) {
          console.error("Error for reallll:", err);
        } else {
          console.log("here is profile data", res);
          const categoryRequest = new Request("/category/");
          categoryRequest.get((response, err) => {
            isLoading.value = false;
            if (err) {
              console.error("Error:", err);
            } else {
              console.log("Data:", response);
              console.log("res ooooo", res)
              // Map the API data to add the icon field from the static data
              // const iconMap = {
              //   "Senior care": "senior care.png",
              //   "Academic care": "academic care.png",
              //   "Child care": "child care.png",
              //   "Home care": "house care.png",
              // };

              let care = response.results.find((item) => item.id === res.care_seeker.category.id);
              console.log("caree image1", care);
              careImage.value = care.name;
              localData.value.type_of_care = care.name;
              localData.value.type_of_care_id = care.id;
              console.log("caree imag2", careImage.value);
              // return {
              //   id: item.id,
              //   label: item.name,
              //   icon: iconMap[item.name], // fallback icon if not found
              // };
            }
          });
        }
      });
    };
    onMounted(() => {
      fetchCareseeker();

    });
    return {
      selectedWork,

      handleClick,
      disabled,
      setWork,
      careImage,
      formData,
      isLoading,
    };
  },
};
</script>

<style scoped>
h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  margin: 0px;
  text-align: center;
}

.text {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: center;
}

.caregiver-container {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.care-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  gap: 16px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card-selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal-active);
}

.care-card-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
  margin-bottom: 24px;
  cursor: pointer;
}

.care-card p {
  color: #0d4a34;
}

.selects-div {
  display: flex;
  gap: 16px;
  width: 400px;
}
.work-preference p {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: var(--s-green-darker);
}
.work-preference {
  display: flex;
  justify-content: flex-start;
  width: 400px;
  margin-top: 24px;
}
</style>
