<template>
  <div class="caregiver-container">
    <LoadingSpinner :isLoading="isLoading" />
    <div>
      <h1>Do you offer any other care services?</h1>
    </div>
    <div class="care-card-container">
      <div
        v-for="service in services"
        :key="service.id"
        class="care-card"
        :class="{
          'care-card-selected': localData.type_of_care.includes(service.id),
        }"
        @click="toggleService(service.id)"
      >
        <div>
          <img :src="require(`@/assets/${service.icon}`)" />
          <p>{{ service.label }}</p>
        </div>
        <input
          type="radio"
          :checked="localData.type_of_care.includes(service.id)"
        />
      </div>
    </div>
    <div class="button-div">
      <BaseButton
        label="Next"
        :disabled="disabled"
        backgroundColor="var(--s-pink-normal)"
        textColor="var(--state-white)"
        disabledBackgroundColor="#ECECEC"
        disabledColor="#ECECEC"
        disabledTextColor="#979797"
        height="60px"
        width="100%"
        @click="handleClick"
      />
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";
import { ref, inject, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import Request from "@/utils/apiclient.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "CareGiverStepFour",
  components: {
    BaseButton,
    LoadingSpinner,
  },
  setup() {
    const isLoading = ref(true);
    const formData = inject("formData");
    const updateFormData = inject("updateFormData");

    const localData = ref({
      ...formData.value.stepThreeData,
    });

    const services = ref([]);

    const fetchCareCategory = () => {
      const request = new Request("/category/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("Data:", res);
          const iconMap = {
            'Elderly care': 'senior_care.svg',
            'Tutor': 'academic_care.svg',
            'Child care': 'child_care.svg',
            'Housekeeper': 'house_care.svg',
          };

          services.value = res.results.map(item => {
            return {
              id: item.id,
              label: item.name,
              icon: iconMap[item.name], 
            };
          });

          // Automatically select the service that matches the formData.stepOneData.type_of_care
          const matchedService = services.value.find(
            service => service.label === formData.value.stepOneData.type_of_care
          );
          if (matchedService) {
             localData.value.type_of_care.push(matchedService.id);
          }
        }
      });
    };

    const router = useRouter();
    const disabled = ref(false);
    const toggleService = (serviceId) => {
  // Ensure only one service is selected
  localData.value.type_of_care = [serviceId];
  disabled.value = localData.value.type_of_care.length === 0;
};


    const handleClick = () => {
      console.log("BaseButton clicked");
      updateFormData("stepThreeData", localData.value);
      router.push("/caregiver-onboarding/build-profile/step-1");
    };

    watch(
      localData,
      (newValue) => {
        updateFormData("stepThreeData", newValue);
      },
      { deep: true }
    );

    onMounted(() => {
      fetchCareCategory();
    });

    return {
      services,
      localData,
      disabled,
      handleClick,
      toggleService,
      isLoading
    };
  },
};
</script>


<style scoped>
h1 {
  color: #0a3828;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  margin: 0px;
  text-align: center;
}

.caregiver-container {
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.care-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 24px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
  transition: background-color 0.3s, border-color 0.3s;
  cursor: pointer;
}

.care-card-selected {
  background-color: var(--s-pink-light-hover);
  border-color: var(--s-green-normal-active);
}

.care-card-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 42px;
  margin-bottom: 24px;
}

.care-card p {
  color: #0d4a34;
}

.care-card div:first-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.care-card input[type="checkbox"] {
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #979797;
  background-color: transparent;
  display: grid;
  place-content: center;
  transition: background-color 0.3s, border-color 0.3s;
}

.care-card input[type="checkbox"]::before {
  content: "";
  width: 12px;
  height: 12px;
  transform: scale(0);
  transition: transform 0.3s;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  background-color: var(--s-green-normal-active);
}

.care-card input[type="checkbox"]:checked {
  border-color: var(--s-green-normal-active);
}

.care-card input[type="checkbox"]:checked::before {
  transform: scale(1);
}
.button-div{
  width: 400px;
}
@media screen and (max-width: 800px) {
 
.care-card-container{
  width: 350px;
}
.selects-div{
  width: 350px;
  flex-direction: column;

}

.selects-div :deep(.custom-select-container){
  width: 350px !important;
}
.selects-div :deep(.custom-select-trigger){
  width: 350px !important;
}
.work-preference{
  justify-content: center;
}

.button-div{
  width: 350px;
  display: flex;
  justify-content: center;
}
h1{
  
font-size: 20px;
font-weight: 700;
line-height: 31.2px;
letter-spacing: 0.5px;
text-align: left;
width:350px
}
}
</style>
