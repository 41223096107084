<template>
  <div class="login-layout-container">
    <div class="main">
      <div class="navbar">
        <img
          @click="redirectToHome"
          class="main-logo"
          src="@/assets/full_logo.svg"
          alt="Logo"
        />
        <div class="create-account">
          <p>Onboard your staff</p>
          <p>Step 1 of 1</p>
        </div>
      </div>
      <div class="progress-bar">
        <div class="progress" style="width: 100%"></div>
      </div>
      <div class="login-layout-container">
        <ToastManagerVue ref="toastManager" />
        <div class="main">
          <div>
            <div class="form-container">
              <h1
                style="
                  text-align: center;
                  margin-top: 30px;
                  margin-bottom: 30px;
                "
              >
                Update your staff information
              </h1>
              <form @submit.prevent="handleSubmit">
                <!-- Name Input -->
                <div class="form-group has-value">
                  <label for="name">First Name</label>
                  <div class="input-container">
                    <input
                      id="name"
                      type="text"
                      placeholder="Enter first name"
                      v-model="formData.first_name"
                      @input="updateFieldValue('first_name')"
                    />
                  </div>
                </div>
                <div class="form-group has-value">
                  <label for="name"> Last Name</label>
                  <div class="input-container">
                    <input
                      id="name"
                      type="text"
                      placeholder="Enter Last name"
                      v-model="formData.last_name"
                      @input="updateFieldValue('last_name')"
                    />
                  </div>
                </div>
                <div class="form-group has-value">
                  <label for="name"> Email</label>
                  <div class="input-container">
                    <input
                      id="name"
                      type="email"
                      placeholder="Enter email"
                      v-model="formData.email"
                      @input="updateFieldValue('email')"
                    />
                  </div>
                </div>
                <!-- Staff Type Buttons -->
                <div class="form-group has-value">
                  <label>Staff Type</label>
                  <div class="staff-type-group">
                    <button
                      type="button"
                      @click="setStaffType('Housekeeper')"
                      :class="{
                        selectedStaff: formData.staffType === 'Housekeeper',
                      }"
                    >
                      Housekeeper
                    </button>
                    <button
                      type="button"
                      @click="setStaffType('Nanny')"
                      :class="{ selectedStaff: formData.staffType === 'Nanny' }"
                    >
                      Nanny
                    </button>
                    <button
                      type="button"
                      @click="setStaffType('Care giver')"
                      :class="{
                        selectedStaff: formData.staffType === 'Care giver',
                      }"
                    >
                      Caregiver
                    </button>
                  </div>
                </div>

                <!-- Phone Number Input -->
                <div class="form-group has-value">
                  <label for="phone">Phone Number</label>
                  <div class="input-container">
                    <input
                      id="phone"
                      type="text"
                      maxlength="11"
                      placeholder="Enter phone number"
                      v-model="formData.phone"
                      @input="updateFieldValue('phone')"
                    />
                  </div>
                </div>

                <!-- BVN Input -->
                <div class="form-group has-value">
                  <label for="bvn">BVN</label>
                  <div class="input-container">
                    <input
                      id="bvn"
                      type="text"
                        maxlength="11"
                      placeholder="Enter BVN"
                      v-model="formData.bvn"
                      @input="updateFieldValue('bvn')"
                    />
                  </div>
                </div>

                <!-- Bank Account Number Input -->
                <div class="form-group has-value">
                  <label for="bank_account">Regular Bank Account Number</label>
                  <div class="input-container">
                    <input
                      id="bank_account"
                      type="text"
                      placeholder="Enter bank account number"
                      v-model="formData.bankAccount"
                      @input="updateFieldValue('bankAccount')"
                    />
                  </div>
                </div>
                 <label for="bank_account">Select Bank</label>
                <div
                  style="margin-bottom: 30px; margin-top: 10px"
                  :class="['custom-multiselect']"
                >
                  <Multiselect
                    id="Bank"
                    label="text"
                    v-model="selectedBank"
                    :options="banksOptions"
                    track-by="text"
                    :searchable="true"
                    placeholder="Banks"
                    class="custom-multiselect"
                  >
                    <template #caret>
                      <svg
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 1.70688L10.59 0.296875L6 4.87687L1.41 0.296875L0 1.70688L6 7.70688L12 1.70688Z"
                          fill="#979797"
                        />
                      </svg> </template
                  ></Multiselect>
                </div>
                <!-- Bank Account Number Input -->
                <div class="form-group has-value">
                  <label for="bank_account">Caregiver Salary</label>
                  <div class="input-container two-rows">
                    <input
                      id="salary"
                      type="text"
                      placeholder="Enter salary"
                      v-model="formData.salary"
                      @input="updateFieldValue('salary')"
                    />
                    <span>5% is added as our fee</span>
                  </div>
                </div>

                <!-- Gender Buttons -->
                <div class="form-group has-value">
                  <label>Gender</label>
                  <div class="gender-group">
                    <button
                      type="button"
                      @click="setGender('M')"
                      :class="{ selected: formData.gender === 'M' }"
                    >
                      Male
                    </button>
                    <button
                      type="button"
                      @click="setGender('F')"
                      :class="{ selected: formData.gender === 'F' }"
                    >
                      Female
                    </button>
                  </div>
                </div>

                <!-- Submit Button -->
                <BaseButton
                  :label="isSubmitting ? 'Updating...' : 'Update'"
                  :disabled="isSubmitting || isFormInvalid"
                  backgroundColor="var(--s-pink-normal)"
                  textColor="var(--state-white)"
                  disabledBackgroundColor="#ECECEC"
                  disabledColor="#ECECEC"
                  disabledTextColor="#979797"
                  height="60px"
                  width="100%"
                  @click="handleCSHIDashboard"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="create-account-small">
        <p>Onboard your staff</p>
        <p>Step 1 of 1</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import BaseButton from "@/components/BaseButton.vue";
import ToastManagerVue from "@/components/ToastAlert/ToastManager.vue";
import Request from "@/utils/apiclient.js";
import { formatErrorMessages } from "@/utils/errorHandler";
import Multiselect from "@vueform/multiselect";
export default {
  name: "OnboardingCSHI",
  components: {
    ToastManagerVue,
    BaseButton,
    Multiselect
  },
  setup() {
    const isLoading = ref(true);
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const router = useRouter();
    const isSubmitting = ref(false);
    const formData = ref({
      first_name: "",
      last_name: "",
      staffType: "",
      phone: "",
      bvn: "",
      bankAccount: "",
      gender: "",
      salary: "",
      email: "",
    });

    const updateFieldValue = (field) => {
    //   formData.value[field] = formData.value[field].trim();

        if (['phone', 'bvn', 'salary'].includes(field)) {
    formData.value[field] = formData.value[field].replace(/\D/g, ''); // This replaces any non-digit character with an empty string
  } else {
    formData.value[field] = formData.value[field].trim();
  }
    };

    const setGender = (gender) => {
      formData.value.gender = gender;
    };

    const setStaffType = (type) => {
      formData.value.staffType = type;
    };

    const handleSubmit = () => {
      if (isSubmitting.value) return;
      isSubmitting.value = true;

      console.log("Form Data", formData.value);
      // Post form data to the backend (implement actual logic)
    };
    const banksOptions = ref([]);
    const selectedBank = ref(null);
    const fetchBanks = () => {
      const request = new Request(`/banks/`);
      // const request = new Request('/user/')
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here is banks data", res);
          banksOptions.value = res.map((bank) => ({
            text: bank.name, // Customize according to your API response structure
            value: bank.id, // Customize according to your API response structure
            // id: bank.id
          }));
          console.log("banksOptions", banksOptions.value);
        }
      });
    };
    const isFormInvalid = computed(() => {
      return (
        !formData.value.first_name ||
        !formData.value.last_name ||
        !formData.value.staffType ||
        !formData.value.phone ||
        !formData.value.bvn ||
        !formData.value.bankAccount ||
           !selectedBank.value ||
        !formData.value.gender ||
        !formData.value.salary ||
        !formData.value.email
      );
    });
    const handleCSHIDashboard = () => {
      if (isSubmitting.value) return;
      isSubmitting.value = true;

      const payload = {
        bvn: parseInt(formData.value.bvn),
        bank_account:{
            account_number: formData.value.bankAccount,
            business_name: "",
            bank: selectedBank.value
        },
        first_name: formData.value.first_name,
        last_name: formData.value.last_name,
        email: formData.value.email,
        staff_type: formData.value.staffType,
        gender: formData.value.gender,
        phone: formData.value.phone,
        salary: formData.value.salary,
      };

      console.log("full form data 7", payload);
      const rawData = JSON.stringify(payload);
      const request = new Request("/staff/");
      request.post(rawData, (data, error) => {
        isSubmitting.value = false;
        if (error) {
          console.log("data error", error);
          if (error.status == "failure") {
            return showToast(error.message, "error");
          }
          if (error.detail) {
            return showToast("An error occurred", "error");
          }
          const errorMessages = formatErrorMessages(error);
          console.log("error", errorMessages);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          console.log("data success here", data);
          showToast("Staff added Successfully", "success");
          router.push("/careseeker");
        }
      });
    };

    onMounted(() => {
      fetchBanks();
    });

    return {
      formData,
      isSubmitting,
      setGender,
      setStaffType,
      updateFieldValue,
      handleSubmit,
      isFormInvalid,
      handleCSHIDashboard,
      toastManager,
      fetchBanks,
      isLoading,
      selectedBank,
      banksOptions
    };
  },
};
</script>

<style scoped>
span {
  margin-top: 5px;
  font-size: 14px;
}
.create-account-small {
  display: none;
}
.onboarding-container {
  padding-top: 56px;
}
.care-card {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.care-card-single {
  padding: 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);

  width: 360px;
}
.login-layout-container {
  background-color: #fffbfc;
  min-height: 100vh;
}
.main {
  background-color: #fff8f9;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 200px;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.onboarding-header-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 60px;
}
.stepper {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}
.progress-bar {
  width: 100%;
  background-color: #979797;
  height: 5px;
}
.progress {
  background-color: #17865f;
  height: 5px;
  transition: width 0.3s;
}
.create-account {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.create-account p {
  color: #116547;
}
.create-account p:first-child {
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-right: 8px;
}
.links {
  display: flex;
  gap: 30px;
}

.links router-link:hover {
  background-color: #007bff;
  color: #fff;
}

.links router-link-active {
  font-weight: bold;
}
.onboarding-container {
  padding-top: 56px;
}
.care-card {
  display: flex;
  gap: 40px;
  justify-content: center;
}
.care-card-single {
  padding: 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid var(--s-green-normal);
  width: 360px;
}
.login-layout-container {
  background-color: #fffbfc;
  min-height: 100vh;
}
.main {
  background-color: #fff8f9;
  min-height: 100vh;
  width: 100%;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.onboarding-header-text {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: center;
  margin-bottom: 60px;
}
.care-card-single button {
  background-color: var(--s-pink-normal);
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 40px;
  border: none;
  outline: none;
  color: white;
  margin-top: 10px;
  height: 48px;
  width: 100%;
  cursor: pointer;
}
.care-card-single button:hover {
  background-color: var(--s-pink-normal-hover);
}
.care-card-single div:first-child {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.care-card-single p {
  text-align: center;
}
.care-card-single-header-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #0a3828;
}
.care-card-single-other-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  color: #0a3828;
}

.login-container {
  max-width: 400px;
  margin: auto;
}

h2 {
  margin-bottom: 20px;
  margin-top: 32px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29.05px;
  letter-spacing: 0.5px;
  text-align: center;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #979797;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-group input {
  width: 100%;
  padding: 16px 10px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent;
  box-sizing: border-box;
}
.input-group input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #979797;
}

.login-button {
  width: 100%;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
}

.login-button:hover {
  background-color: var(--s-pink-normal-hover);
}

.forgot-password {
  display: block;
  margin-bottom: 34px;
  color: var(--s-green-normal);
  text-decoration: none;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: center;
}

.forgot-password:hover {
  color: var(--s-green-normal-hover);
}

/* .google-login:hover,
.apple-login:hover {
  background-color: #f5f5f5;
} */
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;

  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 16px;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #979797;
}
.separator:not(:empty)::before {
  margin-right: 8px;
}
.separator:not(:empty)::after {
  margin-left: 8px;
}
.join-now:hover {
  background-color: var(--s-pink-normal-hover);
}
.login-form {
  padding-bottom: 200px;
}

.form-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.notification {
  background-color: #e6f7e6;
  color: #2c662d;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 40px;
}

h1 {
  color: #0a3828;

  text-align: left;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #000000;
  margin-bottom: 5px;
  transition: color 0.3s;
}

.form-group input {
  background-color: transparent;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  transition: border-color 0.3s;
  padding: 16px 24px 16px 24px;
  border-radius: 12px;
  border: 1px solid #979797;
  box-sizing: border-box;
}
.form-group input::placeholder {
  color: #979797;
}
.normal-links {
  color: var(--s-green-normal-active);
  text-decoration: none;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}
.form-group.has-value label.active,
.form-group.has-value input:focus {
  color: var(--s-green-normal);
}

.form-group.has-value input {
  border-color: var(--s-green-normal);
}

.form-group small {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: #979797;

  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.input-container {
  position: relative;
}

.input-container .icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.two-rows {
  display: flex;
  flex-direction: column;
}
.gender-group {
  display: flex;
  gap: 10px;
}

.gender-group button {
  flex: 1;

  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  height: 48px;
}

.gender-group button.selected {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}
.staff-type-group {
  display: flex;
  gap: 10px;
}
.staff-type-group button {
  flex: 1;

  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  padding: 10px 24px 10px 24px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid #979797;
  height: 48px;
}
.staff-type-group button.selectedStaff {
  background-color: var(--s-pink-light-hover);
  color: var(--s-green-dark);
  border-color: var(--s-green-normal);
}

.terms {
  color: #000000;
  text-align: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;

  text-align: left;
  margin-bottom: 20px;
}

.terms a {
  color: #0063f7;
  text-decoration: none;

  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #17865f;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:disabled {
  background-color: #ececec;
  color: #979797;
  cursor: not-allowed;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.error {
  color: red;
  font-size: 12px;
}
.custom-multiselect {
  position: relative;
  width: 100%;
}
.multiselect-option.is-selected {
  background: var(--s-green-normal-active);
  color: #000;
}
.custom-multiselect .multiselect {
  z-index: 11;
  width: 100%;
  padding: 10px;
  height: 60px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}

.custom-multiselect .multiselect__content {
  background-color: transparent; /* White background for options */
}
.multiselect {
  background: transparent;
}
.custom-multiselect-selected .multiselect {
  border: 1px solid var(--s-green-normal-active); /* Change to desired color when a value is selected */
}
@media screen and (max-width: 800px) {
  .links {
    align-items: center;
    gap: 5px !important;
  }
  .links a {
    text-decoration: none;
    color: var(--s-green-dark);
    font-size: 16px;
  }
  .navbar {
    justify-content: space-between;
    padding: 32px;
  }
  .care-card {
    flex-direction: column;
    align-items: center;
  }
  .care-card-single {
    width: 313px;
  }
  .care-card-single-other-text {
    font-size: 12px;
  }
  .onboarding-header-text {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 25.2px;
    letter-spacing: 0.5px;
    text-align: center;
    max-width: 170px;
    max-width: 179px;
    margin-bottom: 16px;
  }
  .onboarding-container {
    padding-top: 32px;
  }
}
@media screen and (max-width: 800px) {
  .create-account {
    display: none;
  }
  .navbar {
    padding: 20px;
    justify-content: space-between;
  }
  /* .main-logo{
    width: 100px;
    height: 70px
  } */
  .main-logo {
    width: 140px;
    height: 50px;
    cursor: pointer;
  }
  .create-account-small {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-top: 40px;
  }
  .create-account-small p {
    color: #979797;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 27.2px;
    letter-spacing: 0.5px;
    text-align: left;
  }
}
</style>
